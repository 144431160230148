<template>
  <div class="container-fluid vh-100 usfc-bg">
    <div class="row h-100">
      <div class="col-md-4 col-sm-12 bg-white">
        <ResetForm />
      </div>
      <div class="d-none d-md-flex col-8 illustration justify-content-center">
        <img src="illustration2.svg" style="width: 60%" />
      </div>
    </div>
  </div>
</template>

<script>
import ResetForm from "./forms/ResetPass.vue";

/* eslint-disable */

export default {
  name: "ResetPage",
  props: {},
  components: {
    ResetForm,
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
html {
  zoom: 1;
}
.usfc-bg {
  /* background: linear-gradient(237.99deg, #2dc9ff 0%, #0f80c1 76.43%); */
  background: #8ccbff;
}

.illustration {
  align-items: center;
  flex-wrap: wrap;
}

.vh-100 {
  height: 100vh;
}
h3 {
  margin: 40px 0 0;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
a {
  color: #42b983;
}
</style>
