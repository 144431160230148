<template>
    <Transition name="fade" mode="out-in">
        <div v-if="this.$parent.loadForm == 'forgot'" class="form mx-sm-4">
            <form>
                <div class="text-start button-back" style="margin: 10% 0 50% 0">
                    <div @click="forgotPass('signin')" style="cursor: pointer; text-decoration: none; color: #3699ff; font-weight: bold"><i class="bi bi-arrow-left"></i> Back</div>
                </div>
                <div class="form-group">
                    <h4 style="font-weight: bold">Forgot Password?</h4>
                    <span class="spantexts mb-5 pb-5">Send us your email, and we will immediately give you the reset password link.</span>
                </div>
                <div class="label-float">
                    <input id="email" type="email" placeholder=" " autocomplete="off" />
                    <label>Email</label>
                </div>
                <br />
                <!-- ADD DISABLED SETELAH APPLY API -->'
                <button @click="sendEmail()" id="submitBtn" class="btn btn-primary w-100" style="position: relative; top: -25px">Reset Password</button>
                <span class="spantexts" id="responseMessage" to="/"></span>
            </form>
        </div>
    </Transition>
</template>

<script>
    import { nextTick } from "vue";
    import axios from "axios";
    import $ from "jquery";

    export default {
        name: "ForgotPass",
        props: {
            msg: String,
        },
        methods: {
            async forgotPass(val) {
                event.preventDefault();
                await nextTick();
                this.$parent.forgotPass(val);
            },
            sendEmail() {
                event.preventDefault();
                $("#submitBtn").attr("disabled", true);
                let body = { email: $("#email").val() };
                axios
                    .post(this.$store.state.app.apiBasepath + "/user/forgot-password", body)
                    .then(() => {
                        this.$store.state.app.emailReset = $("#email").val();
                        this.forgotPass("emailreset");
                    })
                    .catch((err) => {
                        console.log(err);
                        $("#responseMessage").html(err.response.data.message);
                    });
            },
        },
    };
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
    .spantexts {
        font-size: 0.9em;
    }

    .text-dangers {
        color: red;
    }

    .form {
        padding: 10%;
        text-align: left;
    }

    .right-text {
        text-align: right;
    }
    h4 {
        margin: 40px 0 0;
    }
    ul {
        list-style-type: none;
        padding: 0;
    }
    li {
        display: inline-block;
        margin: 0 10px;
    }
    a {
        color: #42b983;
    }

    .label-float {
        position: relative;
        padding-top: 13px;
    }

    input {
        width: 100%;
    }

    .label-float input {
        border: 1px solid lightgrey;
        background-color: whitesmoke;
        border-radius: 5px;
        outline: none;
        min-width: 250px;
        padding: 15px 20px;
        font-size: 16px;
        transition: all 0.1s linear;
        -webkit-transition: all 0.1s linear;
        -moz-transition: all 0.1s linear;
        -webkit-appearance: none;
        top: 10px;
    }

    .label-float input:focus {
        border: 2px solid #3951b2;
        background-color: transparent;
    }

    .label-float input::placeholder {
        color: black;
    }

    .label-float label {
        pointer-events: none;
        position: absolute;
        top: calc(50% - 8px);
        left: 15px;
        transition: all 0.1s linear;
        -webkit-transition: all 0.1s linear;
        -moz-transition: all 0.1s linear;
        background-color: whitesmoke;
        padding: 5px;
        box-sizing: border-box;
    }

    /* USE IF LABEL NEEDS TO BE RED */
    /* .label-float input:required:invalid + label{
  color: red;
} */

    .label-float input:required:invalid {
        border: 2px solid red;
    }
    .label-float input:required:invalid + label:before {
        content: "";
    }
    .label-float input:focus + label,
    .label-float input:not(:placeholder-shown) + label {
        font-size: 13px;
        top: 10px;
        color: #3951b2;
        background-color: transparent;
    }
</style>
