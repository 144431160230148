<template>
    <div v-if="!loading" class="col text-start">
        <!-- MANAGER DASH -->
        <div v-if="getAccessLevel == 5" class="row my-2">
            <div class="col px-0 mx-2 card-item client-rates">
                <div class="m-2 pt-2 px-2 row text-white">
                    <span class="px-0" style="font-size: 20px; font-weight: 700">Client Rates</span>
                </div>
                <div class="m-2 px-2 row text-white" style="width: 80%">
                    <span class="px-0">Get details information about your client's shipping rate</span>
                </div>

                <div class="m-2 px-2 row text-white" style="width: 80%; position: relative; top: 15px">
                    <button @click="goTo('/rates/client')" class="px-3 btn btn-details text-start d-flex" style="line-height: 30px">See Details <img src="/icons/all/twotone/arrow-left-1.svg" style="transform: rotate(180deg) scale(0.4); height: 30px" /></button>
                </div>
            </div>
            <div class="col px-0 mx-2 card-item public-rates">
                <div class="m-2 pt-2 px-2 row text-white">
                    <span class="px-0" style="font-size: 20px; font-weight: 700">Public Rates</span>
                </div>
                <div class="m-2 px-2 row text-white" style="width: 80%">
                    <span class="px-0">Get details information about your public shipping rate</span>
                </div>

                <div class="m-2 px-2 row text-white" style="width: 80%; position: relative; top: 15px">
                    <button @click="goTo('/rates/public')" class="px-3 btn btn-details text-start d-flex" style="line-height: 30px">See Details <img src="/icons/all/twotone/arrow-left-1.svg" style="transform: rotate(180deg) scale(0.4); height: 30px" /></button>
                </div>
            </div>
            <div class="col px-0 mx-2 card-item approval-rates">
                <div class="m-2 pt-2 px-2 row text-white">
                    <span class="px-0" style="font-size: 20px; font-weight: 700">Request Change Approval</span>
                </div>
                <div class="m-2 px-2 row text-white" style="width: 80%">
                    <span class="px-0">Update your sales request change rate by approving or rejecting their request </span>
                </div>

                <div class="m-2 px-2 row text-white" style="width: 80%; position: relative; top: 15px">
                    <button @click="goTo('/rates/request')" class="px-3 btn btn-details text-start d-flex" style="line-height: 30px">Check Request Change <img src="/icons/all/twotone/arrow-left-1.svg" style="transform: rotate(180deg) scale(0.4); height: 30px" /></button>
                </div>
            </div>
        </div>
        <!-- ADMIN DASH -->
        <div v-if="getAccessLevel == 4" class="row my-2">
            <div class="col px-0 mx-2 card-item client-rates">
                <div class="m-2 pt-2 px-2 row text-white">
                    <span class="px-0" style="font-size: 20px; font-weight: 700">Client Rates</span>
                </div>
                <div class="m-2 px-2 row text-white" style="width: 80%">
                    <span class="px-0">Get details information about your client's shipping rate</span>
                </div>

                <div class="m-2 px-2 row text-white" style="width: 80%; position: relative; top: 15px">
                    <button @click="goTo('/rates/client')" class="px-3 btn btn-details text-start d-flex" style="line-height: 30px">See Details <img src="/icons/all/twotone/arrow-left-1.svg" style="transform: rotate(180deg) scale(0.4); height: 30px" /></button>
                </div>
            </div>
            <div class="col px-0 mx-2 card-item public-rates">
                <div class="m-2 pt-2 px-2 row text-white">
                    <span class="px-0" style="font-size: 20px; font-weight: 700">Public Rates</span>
                </div>
                <div class="m-2 px-2 row text-white" style="width: 80%">
                    <span class="px-0">Get details information about your public shipping rate</span>
                </div>

                <div class="m-2 px-2 row text-white" style="width: 80%; position: relative; top: 15px">
                    <button @click="goTo('/rates/public')" class="px-3 btn btn-details text-start d-flex" style="line-height: 30px">See Details <img src="/icons/all/twotone/arrow-left-1.svg" style="transform: rotate(180deg) scale(0.4); height: 30px" /></button>
                </div>
            </div>

            <div class="col px-0 mx-2 card-item approval-rates">
                <div class="m-2 pt-2 px-2 row text-white">
                    <span class="px-0" style="font-size: 20px; font-weight: 700">Request Booking Approval</span>
                </div>
                <div class="m-2 px-2 row text-white" style="width: 80%">
                    <span class="px-0">Update your client booking request by approving or rejecting their request </span>
                </div>

                <div class="m-2 px-2 row text-white" style="width: 80%; position: relative; top: 15px">
                    <button @click="goTo('/bookings')" class="px-3 btn btn-details text-start d-flex" style="line-height: 30px">Check Booking <img src="/icons/all/twotone/arrow-left-1.svg" style="transform: rotate(180deg) scale(0.4); height: 30px" /></button>
                </div>
            </div>
        </div>
        <!-- SALES DASH -->
        <div v-if="getAccessLevel == 3" class="row my-2">
            <div class="col px-0 mx-2 card-item client-rates">
                <div class="m-2 pt-2 px-2 row text-white">
                    <span class="px-0" style="font-size: 20px; font-weight: 700">Client Rates</span>
                </div>
                <div class="m-2 px-2 row text-white" style="width: 80%">
                    <span class="px-0">Get details information about your client's shipping rate</span>
                </div>

                <div class="m-2 px-2 row text-white" style="width: 80%; position: relative; top: 15px">
                    <button @click="goTo('/rates/client')" class="px-3 btn btn-details text-start d-flex" style="line-height: 30px">See Details <img src="/icons/all/twotone/arrow-left-1.svg" style="transform: rotate(180deg) scale(0.4); height: 30px" /></button>
                </div>
            </div>
            <div class="col px-0 mx-2 card-item public-rates">
                <div class="m-2 pt-2 px-2 row text-white">
                    <span class="px-0" style="font-size: 20px; font-weight: 700">Public Rates</span>
                </div>
                <div class="m-2 px-2 row text-white" style="width: 80%">
                    <span class="px-0">Get details information about public shipping rate</span>
                </div>

                <div class="m-2 px-2 row text-white" style="width: 80%; position: relative; top: 15px">
                    <button @click="goTo('/rates/public')" class="px-3 btn btn-details text-start d-flex" style="line-height: 30px">See Details <img src="/icons/all/twotone/arrow-left-1.svg" style="transform: rotate(180deg) scale(0.4); height: 30px" /></button>
                </div>
            </div>

            <div class="col px-0 mx-2 card-item approval-rates">
                <div class="m-2 pt-2 px-2 row text-white">
                    <span class="px-0" style="font-size: 20px; font-weight: 700">Request Change Update</span>
                </div>
                <div class="m-2 px-2 row text-white" style="width: 80%">
                    <span class="px-0">Get an update information about your shipping rate and public rate </span>
                </div>

                <div class="m-2 px-2 row text-white" style="width: 80%; position: relative; top: 15px">
                    <button @click="goTo('/rates/request')" class="px-3 btn btn-details text-start d-flex" style="line-height: 30px">Check Request Change Status <img src="/icons/all/twotone/arrow-left-1.svg" style="transform: rotate(180deg) scale(0.4); height: 30px" /></button>
                </div>
            </div>
        </div>
        <!-- CLIENT DASH -->
        <div v-if="getAccessLevel == 2" class="row my-2">
            <div class="col px-0 mx-2 card-item client-booking">
                <div class="m-2 pt-2 px-2 row text-white">
                    <span class="px-0" style="font-size: 20px; font-weight: 700">Shipment Booking</span>
                </div>
                <div class="m-2 px-2 row text-white" style="width: 80%">
                    <span class="px-0" style="min-height: 42px">Let’s find the best shipping method and start shipping everywhere</span>
                </div>

                <div class="m-2 px-2 row text-white" style="width: 80%; position: relative; top: 15px">
                    <button @click="goTo('/bookings')" class="px-3 btn btn-details text-start d-flex" style="line-height: 30px">Book Now <img src="/icons/all/twotone/arrow-left-1.svg" style="transform: rotate(180deg) scale(0.4); height: 30px" /></button>
                </div>
            </div>
            <div class="col px-0 mx-2 card-item check-rates">
                <div class="m-2 pt-2 px-2 row text-white">
                    <span class="px-0" style="font-size: 20px; font-weight: 700">Check Rates</span>
                </div>
                <div class="m-2 px-2 row text-white" style="width: 80%">
                    <span class="px-0" style="min-height: 42px">Get details information about your shipping rate and public rate</span>
                </div>

                <div class="m-2 px-2 row text-white" style="width: 80%; position: relative; top: 15px">
                    <button @click="goTo('/check-rates')" class="px-3 btn btn-details text-start d-flex" style="line-height: 30px">Check Rates <img src="/icons/all/twotone/arrow-left-1.svg" style="transform: rotate(180deg) scale(0.4); height: 30px" /></button>
                </div>
            </div>
        </div>

        <div class="row">
            <div class="col m-2">
                <div class="row">
                    <span style="font-size: 22px; font-weight: 600">Latest Updates </span>
                </div>
                <div class="row my-2">
                    <div v-for="(cards, index) in update_cards" :key="cards.id" @click="swtichTab(cards.title)" class="col-1 dash-tab-item p-2 mx-2" :class="active_tab == update_cards[index].title ? 'active' : ''">{{ update_cards[index]?.title }}</div>
                </div>
                <div class="row updates-table" ref="receiptsWrapper" v-if="active_tab == 'Active Receipts'">
                    <div class="col">
                        <div @click="goTo('/receipts/details/' + item.id)" v-for="item in getActiveReceipts" :key="item.id" class="row my-3 update-cards">
                            <div class="col">
                                <div class="pt-3 px-2 row">
                                    <div class="col">
                                        <span style="float: left; font-weight: bold; font-size: 16px">{{ item.booking_receipt?.booking_number }}</span>
                                        <span class="update-status" :class="`receipt__${item.delivery_status}`" style="float: right"></span>
                                    </div>
                                </div>
                                <div class="pt-3 px-2 row">
                                    <div class="col">
                                        <span style="float: left; font-size: 14px; font-weight: bold">Destination</span>
                                    </div>
                                </div>
                                <div class="px-2 row">
                                    <div class="col">
                                        <span style="float: left; font-size: 14px">{{ `${item.booking_receipt?.recipient_company.street}, ${item.booking_receipt?.recipient_company.city}, ${item.booking_receipt?.recipient_company.province}` }}</span>
                                    </div>
                                </div>
                                <div class="pt-3 px-2 row">
                                    <div class="col">
                                        <div style="font-size: 12px; opacity: 0.8">Client</div>
                                    </div>
                                </div>
                                <div class="px-2 row">
                                    <div class="col">
                                        <span style="float: left; font-size: 14px">{{ item.booking_receipt?.recipient_company.company }}</span>
                                    </div>
                                </div>
                                <div class="mx-1 my-3 py-2 px-2 row update-location process">
                                    <div class="col">
                                        <div v-if="item.booking_receipt?.client_rate.shipping_type.is_real_time" style="float: left" class="pulsating-circle mx-2"></div>

                                        <span style="float: left; font-weight: bold; font-size: ">See Current Location</span>
                                        <span style="float: right"><img src="/icons/all/twotone/arrow-left-1.svg" style="transform: rotate(180deg) scale(1.4); height: 12px" /></span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row updates-table" ref="rateWrapper" v-if="active_tab == 'Change Rate Status'">
                    <div class="col">
                        <div @click="goTo('/rates/request/' + getRequest[index - 1].id)" v-for="index in limit" :key="index" class="row my-3 update-cards">
                            <div class="col">
                                <div class="pt-3 px-2 row">
                                    <div class="col">
                                        <span style="float: left; font-weight: bold; font-size: 16px">{{ getRequest[index - 1]?.request_number }}</span>
                                        <span v-if="getRequest[index - 1]?.status == 2" class="update-status process" style="float: right">Approved</span>
                                        <span v-if="getRequest[index - 1]?.status == 3" class="update-status approval" style="float: right">Pending For Approval</span>
                                        <span v-if="getRequest[index - 1]?.status == 1" class="update-status rejected" style="float: right">Rejected</span>
                                    </div>
                                </div>
                                <div class="pt-3 px-2 row">
                                    <div class="col">
                                        <span style="float: left; font-size: 14px; font-weight: bold">Request Change Rate</span>
                                    </div>
                                </div>
                                <div class="px-2 row">
                                    <div class="col">
                                        <span style="float: left; font-size: 14px"
                                            >{{ getRequest[index - 1]?.origin.city }} <span><img class="mx-3" src="/icons/all/twotone/arrow-right-99.svg" style="transform: scale(1); height: 14px; position: relative; bottom: 2px" /></span> {{ getRequest[index - 1]?.destination.city }}</span
                                        >
                                    </div>
                                </div>
                                <div class="pt-3 px-2 row">
                                    <div class="col">
                                        <div style="font-size: 12px; opacity: 0.8">Requested By</div>
                                    </div>
                                    <div class="col">
                                        <div style="font-size: 12px; opacity: 0.8">Client</div>
                                    </div>
                                </div>
                                <div class="px-2 mb-3 row">
                                    <div class="col">
                                        <span style="float: left; font-size: 14px">{{ getRequest[index - 1]?.sales_full_name }}</span>
                                    </div>
                                    <div class="col">
                                        <span style="float: left; font-size: 14px">{{ getRequest[index - 1]?.user.company }} </span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="row text-center my-5">
                            <span class="load-more" v-if="limit != maxLimit" @click="loadMore()">Load More</span>
                            <span v-else>Bottom of Page</span>
                        </div>
                    </div>
                </div>
                <div class="row updates-table" ref="bookingWrapper" v-if="active_tab == 'Booking Status'">
                    <div class="col">
                        <div v-if="dashItems.bookings.pending && dashItems.bookings.pending.length > 0">
                            <div v-for="item in getPendingBookings" :key="item.id" class="row my-3 update-cards" @click="goTo(`/bookings/details/${item.id}`)">
                                <div class="col">
                                    <div class="pt-3 px-2 row">
                                        <div class="col">
                                            <span style="float: left; font-weight: bold; font-size: 16px"> {{ item.booking_number }} </span>
                                            <span class="update-status pending" style="float: right">Pending for Approval</span>
                                        </div>
                                    </div>
                                    <div class="pt-3 px-2 row">
                                        <div class="col">
                                            <span style="float: left; font-size: 14px; font-weight: bold">Shipment Booking</span>
                                        </div>
                                    </div>
                                    <div class="px-2 row">
                                        <div class="col">
                                            <span :title="`${item.pickup_address}`" style="float: left; font-size: 14px"
                                                >{{ t(item.pickup_address) }} <span><img class="mx-3" src="/icons/all/twotone/arrow-right-99.svg" style="transform: scale(1); height: 14px; position: relative; bottom: 2px" /></span> {{ item.recipient_company.city }},
                                                {{ item.recipient_company.province }}</span
                                            >
                                        </div>
                                    </div>
                                    <div class="pt-3 px-2 row">
                                        <div class="col">
                                            <div style="font-size: 12px; opacity: 0.8">Client</div>
                                        </div>
                                    </div>
                                    <div class="px-2 mb-3 row">
                                        <div class="col">
                                            <span style="float: left; font-size: 14px">{{ item.recipient_company.company }}</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div v-if="dashItems.bookings.complete && dashItems.bookings.complete.length > 0">
                            <div v-for="item in getCompleteBookings" :key="item.id" class="row my-3 update-cards" @click="goTo(`/bookings/details/${item.id}`)">
                                <div class="col">
                                    <div class="pt-3 px-2 row">
                                        <div class="col">
                                            <span style="float: left; font-weight: bold; font-size: 16px"> {{ item.booking_number }} </span>
                                            <span class="update-status" :class="[item.booking_status === 1 ? 'rejected' : 'approved']" style="float: right">{{ item.booking_status === 1 ? "Booking Rejected" : "Booking Approved" }}</span>
                                        </div>
                                    </div>
                                    <div class="pt-3 px-2 row">
                                        <div class="col">
                                            <span style="float: left; font-size: 14px; font-weight: bold">Shipment Booking</span>
                                        </div>
                                    </div>
                                    <div class="px-2 row">
                                        <div class="col">
                                            <span :title="`${item.pickup_address}`" style="float: left; font-size: 14px"
                                                >{{ t(item.pickup_address) }} <span><img class="mx-3" src="/icons/all/twotone/arrow-right-99.svg" style="transform: scale(1); height: 14px; position: relative; bottom: 2px" /></span> {{ item.recipient_company.city }},
                                                {{ item.recipient_company.province }}</span
                                            >
                                        </div>
                                    </div>
                                    <div class="pt-3 px-2 row">
                                        <div class="col">
                                            <div style="font-size: 12px; opacity: 0.8">Client</div>
                                        </div>
                                    </div>
                                    <div class="px-2 mb-3 row">
                                        <div class="col">
                                            <span style="float: left; font-size: 14px">{{ item.recipient_company.company }}</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import $ from "jquery";
    import axios from "axios";
    import truncateMiddle from "truncate-middle";

    /* eslint-disable */

    export default {
        name: "DashboardComponent",
        props: {},
        components: {},
        data() {
            return {
                loading: true,
                active_tab: "Change Rate Status",
                update_cards: [],
                loadForm: "signin",
                menu: "Manage",
                submenu: "Users",
                userAction: false,
                limit: 3,
                displayCount: 5,
                dashItems: { bookings: {}, rate: {}, receipts: {} },
            };
        },
        computed: {
            getActiveReceipts() {
                return this.dashItems.receipts.slice(0, this.displayCount);
            },
            getCompleteBookings() {
                return this.dashItems.bookings.complete.slice(0, this.displayCount);
            },
            getPendingBookings() {
                return this.dashItems.bookings.pending;
            },
            maxLimit() {
                return this.$store.state.app.managerForm.allOrigin.length;
            },
            getLoadForm() {
                return this.loadForm;
            },
            currentMenu() {
                return this.menu;
            },
            currentSubmenu() {
                return this.submenu;
            },
            isUserAction() {
                return this.userAction;
            },
            getCurrentRouteName() {
                return this.$route.name;
            },
            getAccessLevel() {
                return this.$store.state.userData.access_level;
            },
            getRequest() {
                return this.$store.state.app.managerForm.allOrigin;
            },
        },
        methods: {
            t(text) {
                return truncateMiddle(text, 25, 0, "...");
            },
            async swtichTab(to) {
                this.active_tab = to;
                this.displayCount = 5;
                let path;
                if (this.getAccessLevel >= 4) path = "all";
                else if (this.getAccessLevel === 3) path = "by-sales";
                else if (this.getAccessLevel === 2) path = "by-client";

                switch (to) {
                    case "Booking Status":
                        (async () => {
                            let token = localStorage.getItem("token");
                            let pending = await axios.get(this.$store.state.app.apiBasepath + `/booking-receipt/${path}?filter=pending`, { headers: { Authorization: `Bearer ${JSON.parse(token)}` } }).then((res) => {
                                return res.data.rows;
                            });
                            let complete = await axios.get(this.$store.state.app.apiBasepath + `/booking-receipt/${path}?filter=complete`, { headers: { Authorization: `Bearer ${JSON.parse(token)}` } }).then((res) => {
                                return res.data.rows;
                            });
                            pending = this.descDateSort(pending);
                            complete = this.descDateSort(complete);

                            this.dashItems.bookings = { pending: pending, complete: complete };
                        })();
                        break;

                    case "Change Rate Status":
                        (async () => {
                            let token = localStorage.getItem("token");
                            const data = await axios.get(this.$store.state.app.apiBasepath + `/client-rate-request/${path}`, { headers: { Authorization: `Bearer ${JSON.parse(token)}` } }).then((res) => {
                                return res.data.rows;
                            });
                            if (data) {
                                console.log(this.descDateSort(data));
                            }
                        })();
                        break;

                    case "Active Receipts":
                        (async () => {
                            let token = localStorage.getItem("token");
                            const data = await axios.get(this.$store.state.app.apiBasepath + `/receipt/${path}?filter=active`, { headers: { Authorization: `Bearer ${JSON.parse(token)}` } }).then((res) => {
                                return res.data.rows;
                            });

                            console.log(data, "receipts");
                            if (data) {
                                this.dashItems.receipts = this.descDateSort(data);
                            }
                        })();
                        break;

                    default:
                        break;
                }

                setTimeout(() => {
                    const bookingWrapper = this.$refs.bookingWrapper;

                    if (bookingWrapper) {
                        // infinite scroll handler for each update tabs
                        bookingWrapper.addEventListener("scroll", () => {
                            const { scrollTop, scrollHeight, clientHeight } = bookingWrapper;
                            if (scrollTop + clientHeight >= scrollHeight - 1) {
                                this.displayCount += 5;
                            }
                        });
                    }

                    const receiptsWrapper = this.$refs.receiptsWrapper;

                    if (receiptsWrapper) {
                        // infinite scroll handler for each update tabs
                        receiptsWrapper.addEventListener("scroll", () => {
                            const { scrollTop, scrollHeight, clientHeight } = receiptsWrapper;
                            if (scrollTop + clientHeight >= scrollHeight - 1) {
                                this.displayCount += 5;
                            }
                        });
                    }

                    const rateWrapper = this.$refs.rateWrapper;

                    if (rateWrapper) {
                        // infinite scroll handler for each update tabs
                        rateWrapper.addEventListener("scroll", () => {
                            const { scrollTop, scrollHeight, clientHeight } = rateWrapper;
                            if (scrollTop + clientHeight >= scrollHeight - 1) {
                                this.displayCount += 5;
                            }
                        });
                    }
                }, 100);
            },
            loadMore() {
                if (this.limit < this.maxLimit - 3) {
                    this.limit += 3;
                } else {
                    this.limit = this.limit + (this.maxLimit - this.limit);
                }
            },
            goTo(path) {
                this.$router.push(path);
            },
            setTabs(level) {
                if (level == 5 || level == 3) {
                    this.update_cards = [
                        {
                            title: "Active Receipts",
                            endpoint: "",
                        },
                        {
                            title: "Change Rate Status",
                            endpoint: "",
                        },
                    ];
                } else if (level == 4 || level == 2) {
                    this.update_cards = [
                        {
                            title: "Active Receipts",
                            endpoint: "",
                        },
                        {
                            title: "Booking Status",
                            endpoint: "",
                        },
                    ];
                }
            },
            forgotPass(val) {
                this.loadForm = "wait";
                setTimeout(() => {
                    this.loadForm = val;
                }, 250);
            },
            getMenu(val) {
                this.menu = val;
            },
            getSub(val) {
                this.submenu = val;
            },
            toggleUserAction() {
                this.userAction = !this.userAction;
            },
            setFocusUser(ele) {
                $(ele.target).focus();
                // console.log(ele.target);
            },
            keepLogin(getToken) {
                axios
                    .post(this.$store.state.app.apiBasepath + "/user/keep-login", {}, { headers: { Authorization: `Bearer ${JSON.parse(getToken)}` } })
                    .then((response) => {
                        localStorage.setItem("token", JSON.stringify(response.data.token));
                        //
                        let userData = response.data.keepLogin;
                        this.$store.state.userData = userData;

                        this.$store.state.userData.access_level = userData.access_level;
                        this.setTabs(userData.access_level);

                        this.swtichTab(this.update_cards[0].title);

                        this.getRequestUpdates();
                    })
                    .catch(() => {
                        this.$router.push("/signin");
                    });
            },
            requestSort() {
                function compare(a, b) {
                    if (a.status < b.status) return 1;
                    if (a.status > b.status) return -1;
                }

                this.$store.state.app.managerForm.allOrigin.sort(compare);
            },
            getRequestUpdates() {
                let token = JSON.parse(localStorage.getItem("token"));
                this.$store.state.app.managerForm.noResult = false;
                this.$store.state.app.managerForm.formLoading = true;
                let path = "";
                if (this.$store.state.userData.access_level == 5) {
                    path = "all";
                } else {
                    path = "by-sales";
                }

                axios
                    .get(this.$store.state.app.apiBasepath + "/client-rate-request/" + path, { headers: { Authorization: `Bearer ${token}` } })
                    .then((res) => {
                        // ;
                        if (res.data.count > 0) {
                            this.$store.state.app.managerForm.noResult = false;
                            this.$store.state.app.managerForm.formLoading = false;
                        } else {
                            this.$store.state.app.managerForm.noResult = true;
                            this.$store.state.app.managerForm.formLoading = false;
                        }
                        this.$store.state.app.managerForm.allOrigin = res.data.rows;
                        this.requestSort();
                    })
                    .catch((err) => {
                        // console.log(err);
                    });
            },

            descDateSort(array) {
                return array.sort((a, b) => new Date(b.updatedAt) - new Date(a.updatedAt)).slice(0, 10);
            },

            async getDashdata() {
                let token = localStorage.getItem("token");
                const data = await axios.get(this.$store.state.app.apiBasepath + "/booking-receipt/all?", { headers: { Authorization: `Bearer ${JSON.parse(token)}` } }).then((res) => {
                    return res.data.rows;
                });
                if (data) {
                    console.log(this.descDateSort(data));
                }
            },
        },
        mounted() {
            let token = localStorage.getItem("token");

            this.loading = true;
            // let token = localStorage.getItem("token");

            if (token == null || undefined) {
                this.$router.push("/signin");
            } else {
                this.keepLogin(token);
                setTimeout(() => {
                    this.loading = false;
                }, 100);
            }
        },
    };
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
    .updates-table {
        max-height: 75vh;
        overflow: auto;
        padding: 0 15px;
    }

    .updates-table::-webkit-scrollbar-track {
        /* -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3); */
        border-radius: 0px;
        background-color: transparent;
    }

    .updates-table::-webkit-scrollbar {
        width: 0;
        background-color: transparent;
    }

    .updates-table::-webkit-scrollbar-thumb {
        border-radius: 0;
        /* -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3); */
        background-color: transparent;
    }

    .load-more {
        cursor: pointer;
    }

    .load-more:hover {
        color: #01a4e9;
    }
    .pulsating-circle {
        position: relative;
        transform: translateX(-50%) translateY(-50%);
        top: 50%;
        width: 8px;
        height: 8px;
    }
    .pulsating-circle:before {
        content: "";
        position: relative;
        display: block;
        width: 300%;
        height: 300%;
        box-sizing: border-box;
        margin-left: -100%;
        margin-top: -100%;
        border-radius: 50%;
        background-color: #01a4e9;
        -webkit-animation: pulse-ring 1.25s cubic-bezier(0.215, 0.61, 0.355, 1) infinite;
        animation: pulse-ring 1.25s cubic-bezier(0.215, 0.61, 0.355, 1) infinite;
    }
    .pulsating-circle:after {
        content: "";
        position: absolute;
        left: 0;
        top: 0;
        display: block;
        width: 100%;
        height: 100%;
        background-color: white;
        border-radius: 15px;
        box-shadow: 0 0 8px rgba(0, 0, 0, 0.3);
        -webkit-animation: pulse-dot 1.25s cubic-bezier(0.455, 0.03, 0.515, 0.955) -0.4s infinite;
        animation: pulse-dot 1.25s cubic-bezier(0.455, 0.03, 0.515, 0.955) -0.4s infinite;
    }

    @-webkit-keyframes pulse-ring {
        0% {
            transform: scale(0.33);
        }
        80%,
        100% {
            opacity: 0;
        }
    }

    @keyframes pulse-ring {
        0% {
            transform: scale(0.33);
        }
        80%,
        100% {
            opacity: 0;
        }
    }
    @-webkit-keyframes pulse-dot {
        0% {
            transform: scale(0.8);
        }
        50% {
            transform: scale(1);
        }
        100% {
            transform: scale(0.8);
        }
    }
    @keyframes pulse-dot {
        0% {
            transform: scale(0.8);
        }
        50% {
            transform: scale(1);
        }
        100% {
            transform: scale(0.8);
        }
    }

    .update-status {
        color: black;
        font-size: 12px;
        width: 150px;
        text-align: center;
        padding: 3px;
        font-weight: bold;
        line-height: 16px;
        border-radius: 20px;
        opacity: 0.75;

        &.receipt__1 {
            background: rgba(0, 0, 0, 0.09);

            &::before {
                content: "Awaiting Pickup";
            }
        }

        &.receipt__2 {
            background: #c5def9;
            color: #083e76;

            &::before {
                content: "On Process";
            }
        }

        &.receipt__3 {
            background: #bcf2bb;
            color: #107e0d;

            &::before {
                content: "Delivered";
            }
        }

        &.receipt__4 {
            background: #bcf2bb;
            color: #107e0d;

            &::before {
                content: "Delivered";
            }
        }

        &.receipt__5 {
            background: rgba(255, 171, 171, 0.7);
            color: #971919;
            &::before {
                content: "Cancelled";
            }
        }

        &.pending {
            opacity: 0.75;
            background-color: #ffd3ab;
            border-radius: 20px;
            color: #c46b1b;
        }

        &.rejected {
            opacity: 0.75;
            background-color: rgba(255, 171, 171, 0.7);
            border-radius: 20px;
            color: #971919;
        }

        &.approved {
            opacity: 0.75;
            background: #bcf2bb;
            border-radius: 20px;
            color: #107e0d;
        }
    }

    .pickup-address {
        max-width: 200px;
        white-space: break-spaces;
    }

    .update-location {
        line-height: 18px;
        font-size: 12px;
        font-weight: bold;
        transition: all ease 0.3s;
    }

    .update-location:hover {
        background-color: rgba(0, 0, 0, 0.35);
    }

    .process {
        background-color: rgba(0, 0, 0, 0.15);

        filter: invert(45%) sepia(69%) saturate(2325%) hue-rotate(196deg) brightness(108%) contrast(101%);
        border-radius: 20px;
    }

    .update-cards {
        cursor: pointer;
        background-color: white;
        height: auto;
        border-radius: 6px;
        /* border: solid 1px rgba(0, 0, 0, 0.1); */
        /* box-shadow: 0px 4px 24px 5px rgba(0, 0, 0, 0.05); */
        transition: all ease 0.3s;
    }

    .update-cards:hover {
        transform: scale(1.009);
    }
    .dash-tab-item {
        cursor: pointer;
        min-width: 125px;
        width: auto;
        text-align: center;
        font-size: 12;
        font-weight: 900px;
        opacity: 0.6;
        border-radius: 6px;
    }

    .dash-tab-item.active {
        background-color: rgba(0, 0, 0, 0.15);
        opacity: 1;
        filter: invert(45%) sepia(69%) saturate(2325%) hue-rotate(196deg) brightness(108%) contrast(101%);
    }

    .dash-tab-item:hover {
        background-color: rgba(0, 0, 0, 0.15);
        opacity: 1;
        filter: invert(45%) sepia(69%) saturate(2325%) hue-rotate(196deg) brightness(108%) contrast(101%);
    }

    .card-item .btn-details {
        background-color: white;
        width: auto;
    }

    .btn-details:hover {
        color: black;
        background-color: #eeeeee;
    }

    .usfc-bg {
        background-color: #fbfbfb;
    }

    .card-item {
        background-color: #3699ff;
        height: 175px;
        border-radius: 6px;
        box-shadow: 0px 4px 24px 5px rgba(0, 0, 0, 0.05);
        transition: all ease 0.3s;
    }

    .card-item.client-rates {
        background: url("@/assets/client_rates.svg");
        background-color: #3699ff;
        background-repeat: no-repeat;
        background-position: right;
    }

    .client-rates:hover {
        background-position-x: 110%;
    }

    .card-item.client-booking {
        background: url("@/assets/booking.svg");
        background-color: #3699ff;
        background-repeat: no-repeat;
        background-position: right;
    }

    .client-booking:hover {
        background-position-x: 110%;
    }

    .card-item.check-rates {
        background: url("@/assets/check_rate.svg");
        background-color: #3699ff;
        background-repeat: no-repeat;
        background-position: right;
    }

    .check-rates:hover {
        background-position-x: 110%;
    }
    .card-item.public-rates {
        background: url("@/assets/public_rates.svg");
        background-color: #3699ff;
        background-repeat: no-repeat;
        background-position: right;
    }
    .public-rates:hover {
        background-position-x: 110%;
    }
    .card-item.approval-rates {
        background: url("@/assets/approval_rates.svg");
        background-color: #3699ff;
        background-repeat: no-repeat;
        background-position: right;
    }
    .approval-rates:hover {
        background-position-x: 110%;
    }
    .blue-filter {
        filter: invert(45%) sepia(69%) saturate(2325%) hue-rotate(196deg) brightness(108%) contrast(101%);
    }
    .profile-card {
        background-color: white;
        width: 7%;
        height: 100%;
        border-radius: 20px;
        cursor: pointer;
    }

    /* .profile-card:hover {
      border: solid 1px rgba(54, 153, 255, 0.25);
    } */

    .prevent-select {
        -webkit-user-select: none; /* Safari */
        -ms-user-select: none; /* IE 10 and IE 11 */
        user-select: none; /* Standard syntax */
    }

    .parent_arrow {
        transform: rotate(180deg);
    }

    .user-button {
        position: absolute;
        right: 3vw;
        top: 15vh;
        width: 12vw;
        background-color: white;
        box-shadow: 5px 5px 10px rgba(0, 0, 0, 0.05);
        text-align: left;
        border-radius: 6px;
    }

    ul.user-button li {
        display: block;
        margin: 2% 0;
        padding: 5%;
        font-size: 2vh;
        font-weight: 400;
        cursor: pointer;
        line-height: 2em;
    }

    /* li:hover {
      opacity: 1;
      background: rgba(54, 153, 255, 0.1);
      border-radius: 6px;
      filter: invert(42%) sepia(100%) saturate(546%) hue-rotate(178deg) brightness(107%) contrast(101%);
    } */

    .sign-out-button {
        filter: invert(51%) sepia(27%) saturate(6806%) hue-rotate(329deg) brightness(111%) contrast(104%);
    }

    .sign-out-button:hover {
        filter: invert(51%) sepia(27%) saturate(6806%) hue-rotate(329deg) brightness(111%) contrast(104%);
    }

    .top-panel {
        height: 10vh;
    }

    .frame-content {
        min-height: 80vh;
    }
    .illustration {
        align-items: center;
        flex-wrap: wrap;
    }

    .vh-100 {
        height: 100vh;
    }
    h3 {
        margin: 40px 0 0;
    }
    ul {
        list-style-type: none;
        padding: 0;
    }
    li {
        display: inline-block;
        margin: 0 10px;
    }
    a {
        color: #42b983;
    }
</style>
