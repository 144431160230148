<template>
    <div class="swiper-component-container" :class="show ? 'show' : 'hide'">
        <div class="swiper-overlay"></div>

        <div @click="emitClose()" class="close">Close<img class="close" src="/icons/all/outline/add.svg" /></div>
        <div>
            <swiper-container class="viewer" navigation="true" pagination="true" zoom="true">
                <swiper-slide v-for="(image, index) in images" :key="index" class="slide">
                    <div class="image"><img :src="image.src" onerror="this.src='/placeholders/unavailable-image.jpg' " /></div>
                    <div class="label">{{ image.label }}</div>
                </swiper-slide>
            </swiper-container>
        </div>
    </div>
</template>
<script>
    export default {
        props: {
            show: {
                type: Boolean,
                default: false,
            },
            images: {
                type: Array,
                default: () => {
                    return [
                        {
                            src: "/placeholders/unavailable-image.jpg",
                            label: "Content Unavailabe",
                        },
                    ];
                },
            },
        },
        methods: {
            handleImageError() {
                return "/placeholders/unavailable-image.jpg";
            },
            emitClose() {
                this.$emit("onClose");
            },
        },
    };
</script>
<style lang="scss" scoped>
    .close {
        margin: 20px;
        color: white;
        position: absolute;
        right: 0;
        top: 0;
        cursor: pointer;
        z-index: 155;

        &:hover {
            filter: drop-shadow(0px 0px 5px rgba(255, 255, 255, 1));
        }

        img.close {
            position: relative;
            padding: 0 !important;
            margin: 0;
            filter: invert(100%);
            transform: rotate(45deg);
            min-height: 28px;
        }
    }
    .swiper-overlay {
        background: rgba($color: #000000, $alpha: 0.8);
        position: fixed;
        height: 100%;
        width: 100%;
    }

    .swiper-component-container {
        transition: all 0.3s ease;
        z-index: 150;
        position: fixed;
        top: 0;
        left: 0;

        min-height: 100vh;
        max-height: 100vh;
        min-width: 100vw;
        max-width: 100vw;

        &.show {
            opacity: 1;
        }

        &.hide {
            opacity: 0;
            user-select: none;
            pointer-events: none;
        }

        .slide {
            height: 100vh;
            padding: 22px;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;

            .image {
                img {
                    object-fit: contain;
                    padding: 40px;
                    min-height: 85vh;
                    max-height: 85vh;
                }
            }
        }

        .label {
            color: white;
        }
    }
</style>
