<template>
  <Transition name="fade" mode="out-in">
    <div v-if="this.$parent.loadForm == 'signin'" class="form mx-sm-4">
      <form>
        <div class="text-center">
          <img
            src="macra_logistic_logo.png"
            style="width: 35%; padding-bottom: 10%"
          />
        </div>
        <div class="form-group">
          <h3 style="font-weight: bold">Sign in to your account</h3>
          <span>Enter your details to access your account</span>
        </div>
        <div class="label-float">
          <input id="email" type="email" placeholder=" " autocomplete="off" />
          <label>Email</label>
        </div>
        <br />
        <div class="label-float" style="top: -20px">
          <input id="pwd" type="password" placeholder=" " autocomplete="off" />
          <label>Password</label>
          <span
            style="float: right; position: relative; top: -40px; left: -10px"
            @click="togglePass()"
            toggle="#password-field"
            class="bi bi-eye-slash-fill toggle-password"
          ></span>
        </div>
        <div class="text-end pb-1" style="position: relative; top: -15px">
          <div
            @click="forgotPass()"
            style="cursor: pointer; text-decoration: none; color: inherit"
            to="/"
          >
            Forgot Password
          </div>
        </div>
        <!-- ADD DISABLED SETELAH APPLY API -->'
        <button
          @click="login()"
          id="loginBtn"
          class="btn btn-usfc-primary w-100"
          style="position: relative; top: -25px"
        >
          Submit
        </button>
        <div
          id="responseBlock"
          class="spantexts text-danger pb-1"
          style="position: relative; top: -15px"
        >
          <span class="spantexts" id="responseMessage" to="/"></span>
        </div>
      </form>
    </div>
  </Transition>
</template>

<script>
import $ from "jquery";
import axios from "axios";
import { nextTick } from "vue";

export default {
  name: "SigninForm",
  props: {},
  methods: {
    togglePass() {
      $(".toggle-password").toggleClass("bi-eye bi-eye-slash-fill");
      var input = $("#pwd");
      if (input.attr("type") == "password") {
        input.attr("type", "text");
      } else {
        input.attr("type", "password");
      }
    },
    async forgotPass() {
      await nextTick();
      this.$parent.forgotPass("forgot");
    },
    login() {
      event.preventDefault();

      $("#loginBtn").attr("disabled", true);

      let creds = {
        email: $("#email").val(),
        password: $("#pwd").val(),
      };

      axios
        .post(this.$store.state.app.apiBasepath + "/user/login", creds)
        .then((response) => {
          if (response.status == 200) {
            let userData = response.data.userLogin;
            if (userData.access_level == 1) {
              $("#responseBlock").attr("hidden", false);
              $("#responseMessage").html("Access Denied");
              userData = "";
              setTimeout(() => {
                $("#loginBtn").attr("disabled", false);
              }, 1000);
              setTimeout(() => {
                $("#responseBlock").attr("hidden", true);
              }, 5000);
            } else {
              localStorage.setItem(
                "token",
                JSON.stringify(response.data.token)
              );
              setTimeout(() => {
                this.$store.state.app.preloader.on = false;
                location.reload();
              }, 250);
            }
          } else {
            $("#responseBlock").attr("hidden", false);
            $("#responseMessage").html(response.data.message);
            setTimeout(() => {
              $("#loginBtn").attr("disabled", false);
            }, 1000);
            setTimeout(() => {
              $("#responseBlock").attr("hidden", true);
            }, 5000);
          }
        })
        .catch((error) => {
          console.log(error);
          $("#responseBlock").attr("hidden", false);
          $("#responseMessage").html(error.response.data.message);
          setTimeout(() => {
            $("#loginBtn").attr("disabled", false);
          }, 1000);
          setTimeout(() => {
            $("#responseBlock").attr("hidden", true);
          }, 5000);
        });
    },

    keepLogin(getToken) {
      axios
        .post(
          this.$store.state.app.apiBasepath + "/user/keep-login",
          {},
          { headers: { Authorization: `Bearer ${JSON.parse(getToken)}` } }
        )
        .then((response) => {
          localStorage.setItem("token", JSON.stringify(response.data.token));

          let userData = response.data.userLogin;
          this.$store.state.userData = userData;
          this.$router.push("/dashboard");
          setTimeout(() => {
            this.$store.state.app.preloader.on = false;
            location.reload();
          }, 100);
        })
        .catch((error) => {
          this.$store.state.app.preloader.on = false;
          console.log(error);
          $("#responseBlock").attr("hidden", false);
          $("#responseMessage").html("keep-login: " + error.data.message);
          setTimeout(() => {
            $("#loginBtn").attr("disabled", false);
          }, 1000);
          setTimeout(() => {
            $("#responseBlock").attr("hidden", true);
          }, 5000);
        });
    },
  },
  created() {},
  mounted() {
    this.$store.state.app.preloader.on = true;
    setTimeout(() => {
      let token = localStorage.getItem("token");
      if (token != null || undefined) {
        this.keepLogin(token);
      } else {
        this.$store.state.app.preloader.on = false;
      }
    }, 1000);
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.form {
  margin-top: 15vh;
  padding: 10%;
  text-align: left;
}

.right-text {
  text-align: right;
}
h3 {
  margin: 40px 0 0;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
a {
  color: #42b983;
}

/* INPUT FORM LABEL STYLE */
.label-float {
  position: relative;
  padding-top: 13px;
}

input {
  width: 100%;
}

.btn.btn-usfc-primary {
  background-color: #3699ff;
  color: white;
  border-radius: 6px;
  padding: 10px 0px;
}

.btn-usfc-primary:hover {
  background-color: #3699ff;
  color: white;
  box-shadow: 5px 5px 15px 10px rgba(0, 0, 0, 0.05);
}

.label-float input {
  border: 1px solid lightgrey;
  background-color: whitesmoke;
  border-radius: 5px;
  outline: none;
  min-width: 250px;
  padding: 15px 20px;
  font-size: 16px;
  transition: all 0.1s linear;
  -webkit-transition: all 0.1s linear;
  -moz-transition: all 0.1s linear;
  -webkit-appearance: none;
  top: 10px;
}

.label-float input:focus {
  border: 2px solid #3699ff;
  background-color: transparent;
}

.label-float input::placeholder {
  color: black;
}

.label-float label {
  pointer-events: none;
  position: absolute;
  top: calc(50% - 8px);
  left: 15px;
  transition: all 0.1s linear;
  -webkit-transition: all 0.1s linear;
  -moz-transition: all 0.1s linear;
  background-color: whitesmoke;
  padding: 5px;
  box-sizing: border-box;
}

.label-float input:required:invalid + label {
  color: red;
}
.label-float input:focus:required:invalid {
  border: 2px solid red;
}
.label-float input:required:invalid + label:before {
  content: "*";
}
.label-float input:focus + label,
.label-float input:not(:placeholder-shown) + label {
  font-size: 13px;
  top: 10px;
  color: #3951b2;
  background-color: transparent;
}
</style>
