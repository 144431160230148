<template>
    <div
        @click.stop="
            filter.show = false;
            filter.showMonth = false;
        "
        class="container-fluid text-start"
        style="min-height: 80vh">
        <div class="row my-3">
            <div class="col mx-2" style="max-width: fit-content">
                <span>Sort by</span>
                <span @click.stop="filter.show = !filter.show" style="font-weight: 600; cursor: pointer" class="px-3">{{ filter.by }} <img class="parent-arrow" src="/icons/all/twotone/arrow-right-1.svg" style="transform: scale(0.65) rotate(90deg); position: relative; top: -2px" /></span>

                <div class="dropdown-options" :class="filter.show ? 'show' : ''">
                    <div @click="changeFilter('Earnings')" :class="filter.by === 'Earnings' ? 'active' : ''">Earnings</div>
                    <div @click="changeFilter('Clients')" :class="filter.by === 'Clients' ? 'active' : ''">Clients</div>
                    <div @click="changeFilter('SLA')" :class="filter.by === 'SLA' ? 'active' : ''">SLA</div>
                </div>
            </div>
            <div v-if="['Earnings', 'SLA'].includes(filter.by)" class="col">
                <span>Month</span>
                <span @click.stop="filter.showMonth = !filter.showMonth" style="font-weight: 600; cursor: pointer" class="px-3"
                    >{{ months[filter.month - 1] }} <span v-if="filter.month == currentMonth" style="color: #000; opacity: 0.5; font-weight: 200; font-size: 12px">(This Month)</span>
                    <img class="parent-arrow" src="/icons/all/twotone/arrow-right-1.svg" style="transform: scale(0.65) rotate(90deg); position: relative; top: -2px"
                /></span>

                <div class="dropdown-options" :class="filter.showMonth ? 'show' : ''">
                    <div v-for="(month, index) in months" :key="month" @click="changeMonth(index + 1)" :class="index + 1 === filter.month ? 'active' : ''">{{ month }} <span v-if="index + 1 == currentMonth" style="color: #000; opacity: 0.5; font-weight: 200; font-size: 12px">(This Month)</span></div>
                </div>
            </div>
        </div>

        <div v-if="salesData" class="row">
            <div class="top-five">
                <div v-for="(item, i) in topFive" :key="i" class="card">
                    <div class="number">{{ i + 1 }}</div>

                    <div class="photo"><img :src="getImageUrl(item?.photo)" style="object-fit: cover; height: 64px; border-radius: 50%; width: 64px" /></div>
                    <div class="name">{{ item?.full_name }}</div>
                    <div class="data" :class="filter.by === 'SLA' ? 'bubble' : ''">
                        <div v-if="filter.by === 'SLA'" class="Stars" style="--rating: 1"></div>
                        {{ getCurrentData(item, this.filter.by) }}
                    </div>
                </div>
            </div>
        </div>

        <div v-if="salesData" class="rank-list">
            <div v-for="(item, i) in restOfData" :key="i" class="rank">
                <div class="no">{{ 6 + i }}</div>
                <div class="photo"><img :src="getImageUrl(item?.photo)" style="object-fit: cover; height: 42px; border-radius: 50%; width: 42px" /></div>
                <div class="name">{{ item?.full_name }}</div>
                <div class="data" :class="filter.by === 'SLA' ? 'bubble' : ''">
                    <div v-if="filter.by === 'SLA'" class="Stars" style="--rating: 1"></div>
                    {{ getCurrentData(item, this.filter.by) }}
                </div>
            </div>
        </div>
    </div>
</template>
<script>
    import axios from "axios";
    import moment from "moment";
    export default {
        data() {
            return {
                salesData: null,
                filter: {
                    show: false,
                    showMonth: false,
                    by: "Earnings",
                    month: 1,
                },
                months: ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"],
            };
        },
        computed: {
            currentMonth() {
                return moment().format("M");
            },
            topFive() {
                // if (this.salesData) {
                //     if (this.salesData.length < 5) {
                //         const filledArray = [...this.salesData];
                //         for (let i = this.salesData.length; i < 5; i++) {
                //             filledArray.push(null);
                //         }
                //         return filledArray;
                //     }
                //     return this.salesData.slice(0, 5);
                // }

                // return [];

                return this.salesData.slice(0, 5);
            },
            restOfData() {
                return this.salesData ? this.salesData.slice(5) : [];
            },
        },
        methods: {
            getImageUrl(photoUrl) {
                if (!photoUrl || photoUrl == "null") {
                    return "/icons/default.svg";
                }
                return "https://s3-usfc-avatar-image.s3.ap-southeast-1.amazonaws.com/" + photoUrl;
            },
            getCurrentData(item, key) {
                switch (key) {
                    case "Earnings":
                        return "Rp " + item?.total_earnings.toLocaleString();

                    case "Clients":
                        return item?.total_clients + (item?.total_clients > 1 ? " Clients" : " Client");

                    case "SLA":
                        return item?.average_rating;

                    default:
                        break;
                }
            },
            getSalesData() {
                const types = {
                    Earnings: `sales-earnings?month=${this.filter.month}`,
                    Clients: "sales-total-clients",
                    SLA: `sales-ratings?month=${this.filter.month}`,
                };

                let token = JSON.parse(localStorage.getItem("token"));

                axios
                    .get(`${this.$store.state.app.apiBasepath}/report/${types[this.filter.by]}`, {
                        headers: { Authorization: `Bearer ${token}` },
                    })
                    .then((res) => {
                        this.salesData = [];

                        // for (let i = 0; i < 20; i++) {
                        //     this.salesData = this.salesData.concat(res.data.data);
                        // }
                        this.salesData = res.data.data;

                        console.log(this.salesData);
                    })
                    .catch(() => {
                        console.log("500: " + token);
                    });
            },
            changeFilter(filter) {
                this.filter.by = filter;
                this.filter.show = false;
                this.filter.showMonth = false;

                this.getSalesData();
            },
            changeMonth(month) {
                this.filter.month = month;
                this.filter.show = false;
                this.filter.showMonth = false;

                this.getSalesData();
            },
        },
        mounted() {
            this.filter.month = +this.currentMonth;
            this.getSalesData();
        },
    };
</script>
<style lang="scss" scoped>
    .bubble {
        background: #fff;
        color: #000;
        padding: 6px 12px;
        border-radius: 22px;

        box-shadow: 0 0 10px 1px rgba($color: #000000, $alpha: 0.1);
    }
    .Stars {
        --percent: calc(var(--rating) * 100%);

        display: inline-block;
        font-size: var(--star-size);
        font-family: Times; // make sure ★ appears correctly
        line-height: 1;
        transform: translateY(1.5px);

        &::before {
            // content: "★★★★★";
            content: "★";
            letter-spacing: 3px;
            background: linear-gradient(90deg, var(--star-background) var(--percent), var(--star-color) var(--percent));
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
        }
    }

    // Just to make a preview more beautifull

    body {
        background: #eee;
        display: flex;
        justify-content: center;
        align-items: center;
        height: 100vh;
    }

    * {
        position: relative;
        box-sizing: border-box;
    }
    .rank-list {
        margin: 22px 0;
        display: flex;
        flex-direction: column;
        gap: 12px;
        .rank {
            border-radius: 6px;
            background: #fff;
            box-shadow: 0px 4px 24px 5px rgba(0, 0, 0, 0.05);
            display: flex;
            padding: 12px 22px;

            font-weight: 600;
            font-size: 14px;

            gap: 22px;
            justify-content: center;
            align-items: center;

            .no {
                min-width: 32px;
            }
            .name {
                flex-grow: 1;
            }

            .data {
                text-align: end;
            }
        }
    }
    .top-five {
        display: flex;
        gap: 16px;

        .card {
            flex-basis: 0;
            flex-grow: 1;
            border-radius: 6px;
            background: linear-gradient(135deg, #1f8dff 0%, #68b2ff 100%);
            box-shadow: 0px 4px 24px 5px rgba(0, 0, 0, 0.05);

            min-height: 150px;
            transition: all 0.3s ease;

            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: space-evenly;
            padding: 6px;

            color: #fff;
            font-weight: 600px;
            font-size: 14px;

            &:hover {
                background: linear-gradient(135deg, hsl(205, 100%, 50%) 0%, hsl(205, 100%, 70%) 100%);
            }

            .number {
                position: absolute;
                top: 0;
                left: 0;

                font-weight: 600;
                font-size: 22px;
                color: white;

                margin: 5px;
            }
        }
    }
    .dropdown-options {
        opacity: 0;
        pointer-events: none;
        position: absolute;
        transform: translate(60px);
        background: #fff;
        border-radius: 6px;
        width: fit-content;
        box-shadow: 0 0 5px 1px rgba($color: #000000, $alpha: 0.1);
        transition: all 0.3s ease;

        &.show {
            opacity: 1;
            pointer-events: all;
            z-index: 250;
        }

        div {
            padding: 12px 0;
            padding-right: 100px;
            padding-left: 22px;
            background-color: #fff;

            transition: all 0.3s ease;

            &:hover {
                background-color: #fff;
                color: #3699ff;
                cursor: pointer;
            }

            &.active {
                background-color: #fff;
                color: #3699ff;
                font-weight: 600;
                cursor: pointer;
            }
        }
    }
</style>
