export default {
    namespaced: true,
    state: {},
    mutations: {},
    actions: {},
    getters: {
        getUserData: function (state) {
            // Syntax dibawah contoh return sebuah string preprocessed data
            // Jika tidak, bisa langsung return all data dengan payload json
            // return `My name is ${state.userData.fullname}, Call me ${state.userData.nickname}`;
            return state.userData;
        },
    },
};
