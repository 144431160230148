<template>
    <div @click.stop="filter.show = false" class="container-fluid text-start" style="min-height: 80vh">
        <div v-if="salesData" class="row">
            <div class="top-five">
                <div v-for="(item, i) in topFive" :key="i" class="card">
                    <div class="number">{{ i + 1 }}</div>

                    <div class="photo"><img :src="getImageUrl(item?.photo)" style="object-fit: cover; height: 64px; border-radius: 50%; width: 64px" /></div>
                    <div class="name">{{ item?.full_name }}</div>
                    <div class="data bubble">
                        <div v-if="filter.by === 'SLA'" class="Stars" style="--rating: 1"></div>
                        {{ getCurrentData(item, this.filter.by) }}
                    </div>
                </div>
            </div>
        </div>

        <div v-if="salesData" class="rank-list">
            <div v-for="(item, i) in restOfData" :key="i" class="rank">
                <div class="no">{{ 6 + i }}</div>
                <div class="photo"><img :src="getImageUrl(item?.photo)" style="object-fit: cover; height: 42px; border-radius: 50%; width: 42px" /></div>
                <div class="name">{{ item?.full_name }}</div>
                <div class="data bubble">
                    <div v-if="filter.by === 'SLA'" class="Stars" style="--rating: 1"></div>
                    {{ getCurrentData(item, this.filter.by) }}
                </div>
            </div>
        </div>
    </div>
</template>
<script>
    import axios from "axios";
    export default {
        data() {
            return {
                salesData: null,
                filter: {
                    show: false,
                    by: "SLA",
                    month: 1,
                },
            };
        },
        computed: {
            topFive() {
                // if (this.salesData) {
                //     if (this.salesData.length < 5) {
                //         const filledArray = [...this.salesData];
                //         for (let i = this.salesData.length; i < 5; i++) {
                //             filledArray.push(null);
                //         }
                //         return filledArray;
                //     }
                //     return this.salesData.slice(0, 5);
                // }

                // return [];

                return this.salesData.slice(0, 5);
            },
            restOfData() {
                return this.salesData ? this.salesData.slice(5) : [];
            },
        },
        methods: {
            getImageUrl(photoUrl) {
                if (!photoUrl || photoUrl == "null") {
                    return "/icons/default.svg";
                }
                return "https://s3-usfc-avatar-image.s3.ap-southeast-1.amazonaws.com/" + photoUrl;
            },
            getCurrentData(item, key) {
                switch (key) {
                    case "Earnings":
                        return "Rp " + item?.total_earnings.toLocaleString();

                    case "Clients":
                        return item?.total_clients;

                    case "SLA":
                        return item?.average_rating;

                    default:
                        break;
                }
            },
            getTransporterData() {
                const types = {
                    Earnings: "sales-earnings",
                    Clients: "sales-total-clients",
                    SLA: `transporter-ratings`,
                };

                let token = JSON.parse(localStorage.getItem("token"));

                axios
                    .get(`${this.$store.state.app.apiBasepath}/report/${types[this.filter.by]}`, {
                        headers: { Authorization: `Bearer ${token}` },
                    })
                    .then((res) => {
                        this.salesData = [];
                        // for (let i = 0; i < 10; i++) {
                        //     this.salesData = this.salesData.concat(res.data.data);
                        // }
                        this.salesData = res.data.data;
                        console.log(this.salesData);
                    })
                    .catch(() => {
                        console.log("500: " + token);
                    });
            },
            changeFilter(filter) {
                this.filter.by = filter;
                this.filter.show = false;

                this.getTransporterData();
            },
        },
        mounted() {
            this.getTransporterData();
        },
    };
</script>
<style lang="scss" scoped>
    .bubble {
        background: #fff;
        color: #000;
        padding: 6px 12px;
        border-radius: 22px;

        box-shadow: 0 0 10px 1px rgba($color: #000000, $alpha: 0.1);
    }
    .Stars {
        --percent: calc(var(--rating) * 100%);

        display: inline-block;
        font-size: var(--star-size);
        font-family: Times; // make sure ★ appears correctly
        line-height: 1;
        transform: translateY(1.5px);

        &::before {
            content: "★";
            // content: "★★★★★";
            letter-spacing: 3px;
            background: linear-gradient(90deg, var(--star-background) var(--percent), var(--star-color) var(--percent));
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
        }
    }

    // Just to make a preview more beautifull

    body {
        background: #eee;
        display: flex;
        justify-content: center;
        align-items: center;
        height: 100vh;
    }

    * {
        position: relative;
        box-sizing: border-box;
    }
    .rank-list {
        margin: 22px 0;
        display: flex;
        flex-direction: column;
        gap: 12px;
        .rank {
            border-radius: 6px;
            background: #fff;
            box-shadow: 0px 4px 24px 5px rgba(0, 0, 0, 0.05);
            display: flex;
            padding: 12px 22px;

            font-weight: 600;
            font-size: 14px;

            gap: 22px;
            justify-content: center;
            align-items: center;
            .no {
                min-width: 32px;
            }

            .name {
                flex-grow: 1;
            }
            .data {
                text-align: end;
            }
        }
    }
    .top-five {
        display: flex;
        gap: 16px;

        .card {
            flex-basis: 0;
            flex-grow: 1;
            border-radius: 6px;
            background: linear-gradient(135deg, #1f8dff 0%, #68b2ff 100%);
            box-shadow: 0px 4px 24px 5px rgba(0, 0, 0, 0.05);

            min-height: 150px;
            transition: all 0.3s ease;

            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: space-evenly;
            padding: 6px;

            color: #fff;
            font-weight: 600px;
            font-size: 14px;

            &:hover {
                filter: brightness(120%);
            }

            .number {
                position: absolute;
                top: 0;
                left: 0;

                font-weight: 600;
                font-size: 22px;
                color: white;

                margin: 5px;
            }
        }
    }
    .dropdown-options {
        opacity: 0;
        pointer-events: none;
        position: absolute;
        transform: translate(60px);
        background: #fff;
        border-radius: 6px;
        width: fit-content;
        box-shadow: 0 0 5px 1px rgba($color: #000000, $alpha: 0.1);
        transition: all 0.3s ease;

        &.show {
            opacity: 1;
            pointer-events: all;
            z-index: 250;
        }

        div {
            padding: 12px 0;
            padding-right: 100px;
            padding-left: 22px;
            background-color: #fff;

            transition: all 0.3s ease;

            &:hover {
                background-color: #fff;
                color: #3699ff;
                cursor: pointer;
            }

            &.active {
                background-color: #fff;
                color: #3699ff;
                font-weight: 600;
                cursor: pointer;
            }
        }
    }
</style>
