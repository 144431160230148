<template>
    <div class="p-2">
        <div id="modalTitle" class="row">
            <div class="col p-2">
                <h5>
                    <span style="font-weight: 700; float: left">Delete Confirmation</span>
                </h5>
            </div>
        </div>
        <div id="subText" class="row text-start">
            <div class="col p-2">
                <div>
                    <span>Are you sure you want to delete {{ admin.full_name }}?</span>
                </div>
            </div>
        </div>

        <div id="navigation" class="row my-2">
            <div class="col">
                <span @click="deleteUser()" style="float: right; width: 150px" class="mx-2 btn btn-usfc-danger">Delete</span>
                <span
                    @click="
                        this.$store.state.app.openModal = '';
                        this.$store.state.app.managerForm.idSelects = [];
                    "
                    style="float: right"
                    class="mx-2 btn btn-cancel">
                    Cancel</span
                >
            </div>
        </div>
    </div>
</template>

<script>
    import "jquery/dist/jquery.js";

    import "bootstrap/dist/css/bootstrap.min.css";
    import "bootstrap/dist/js/bootstrap.bundle.min.js";
    import "bootstrap-icons/font/bootstrap-icons.css";
    import $ from "jquery";
    import axios from "axios";

    export default {
        name: "adminCreate",
        components: {},
        computed: {
            currentRouteName() {
                return this.$route.name;
            },
            userFullName() {
                if (this.$store.state.userData.full_name.length > 30) {
                    return this.$store.state.userData.full_name.substring(0, 28) + "...";
                } else {
                    return this.$store.state.app.userData.full_name;
                }
            },
            role() {
                if (this.$store.state.userData.access_level == 5) {
                    return "Manager";
                } else if (this.$store.state.userData.access_level == 4) {
                    return "Admin";
                } else if (this.$store.state.userData.access_level == 3) {
                    return "Sales";
                } else if (this.$store.state.userData.access_level == 2) {
                    return "Client";
                } else {
                    return "Transporter";
                }
            },
        },
        data() {
            return {
                stageIndex: 0,
                stages: [
                    // status (0: upcoming, 1: active, 2: done)
                    {
                        name: "Fill Personal Profile",
                        status: 1,
                    },
                    {
                        name: "Create Password",
                        status: 0,
                    },
                ],
                selects: "",
                loadForm: "signin",
                menu: "Manage",
                submenu: "Users",
                userAction: false,
                publicRouteNames: ["Signin", "ResetPass", "PassChanged"],
                admin: "",
            };
        },
        methods: {
            deleteUser() {
                let token = JSON.parse(localStorage.getItem("token"));
                axios
                    .delete(this.$store.state.app.apiBasepath + "/user/delete/" + this.$route.params.id, { headers: { Authorization: `Bearer ${token}` } })
                    .then((res) => {
                        console.log("200: delete" + res);
                        this.$store.state.app.openModal = "";
                        this.$store.state.app.managerForm.idSelects = [];
                        this.$alertSys("User has been deleted", "success");

                        this.$router.push("/manage/users");
                        this.admin = res.data;
                    })
                    .catch((err) => {
                        console.log(token);
                        console.log(err);
                    });
            },
            getDetail(id) {
                let token = JSON.parse(localStorage.getItem("token"));

                axios
                    .get(this.$store.state.app.apiBasepath + "/user/admin-details/" + id, { headers: { Authorization: `Bearer ${token}` } })
                    .then((res) => {
                        console.log("200: " + res);
                        this.admin = res.data;
                    })
                    .catch(() => {
                        console.log("500: " + token);
                    });
            },
            togglePassConfirm() {
                $(".toggle-password-confirm").toggleClass("bi-eye bi-eye-slash-fill");
                var input = $("#pwdconf");
                if (input.attr("type") == "password") {
                    input.attr("type", "text");
                } else {
                    input.attr("type", "password");
                }
            },
            nextStage() {
                this.stages[this.stageIndex].status = 2;
                this.stages[this.stageIndex + 1].status = 1;
                $("#stages").scrollLeft("2000px");
                this.stageIndex++;
            },
            prevStage() {
                this.stages[this.stageIndex].status = 0;
                this.stages[this.stageIndex - 1].status = 1;
                $("#stages").scrollLeft("200px");
                this.stageIndex--;
            },
            logout() {
                localStorage.clear();
                location.reload();
            },
            currentMenu() {
                if (["Publish Rates", "Client Rates", "Request Change"].includes(this.$route.name)) {
                    return "Rates";
                }

                if (["Users", "References"].includes(this.$route.name)) {
                    return "Manages";
                }

                if (["Receipt", "SLA"].includes(this.$route.name)) {
                    return "Reports";
                } else {
                    return this.$route.name;
                }
            },
            currentSubmenu() {
                let menu = this.currentMenu();
                if (["Dashboard", "Receipts"].includes(menu)) {
                    return "";
                } else {
                    return this.$route.name;
                }
            },
            getMenu(val) {
                this.menu = val;
            },
            getSub(val) {
                this.submenu = val;
            },
            toggleUserAction() {
                this.userAction = !this.userAction;
            },
            setFocusUser(ele) {
                $(ele.target).focus();
                console.log(ele.target);
            },
            keepLogin(getToken) {
                axios
                    .post(this.$store.state.app.apiBasepath + "/user/keep-login", {}, { headers: { Authorization: `Bearer ${JSON.parse(getToken)}` } })
                    .then((response) => {
                        localStorage.setItem("token", JSON.stringify(response.data.token));

                        let userData = response.data.keepLogin;
                        this.$store.state.userData = userData;
                    })
                    .catch(() => {
                        this.$router.push("/signin");
                    });
            },
        },
        mounted() {
            this.getDetail(this.$store.state.app.managerForm.idSelects[0]);
        },
    };
</script>

<style scoped>
    #closeModal {
        float: right;
        transform: rotate(45deg);
        border-radius: 50%;
        cursor: pointer;
        transition: 0.3s;
        border: 1px solid transparent;
    }

    #closeModal:hover {
        border: 1px solid black;
    }

    #navigation {
        position: absolute;
        bottom: 0;
        right: 0;
    }

    #stages > .col-auto {
        opacity: 0.5;
    }

    #stages > .col-auto.active {
        opacity: 1;
    }

    #pictureImg {
        cursor: pointer;
        width: 110px;
        width: 110px;
        object-fit: cover;
        object-position: center;
        border-radius: 50%;
    }

    #pictureImgBtn {
        cursor: pointer;
        border-radius: 50%;
        height: 32px;
        width: 32px;
        display: inline-block;
        background-color: #3699ff;

        position: absolute;
        top: 180px;
        left: 53%;
    }

    #pictureImgBtn > #pictureBtn {
        transform: scale(1.2);
        filter: invert(100%) sepia(100%) saturate(0%) hue-rotate(73deg) brightness(103%) contrast(103%);
    }

    #profilePicture {
        height: 20%;
    }

    #stages {
        overflow-x: scroll;
    }
    #stages::-webkit-scrollbar {
        width: 0px;
        background: transparent; /* make scrollbar transparent */
    }

    .blur-overlay {
        display: block;
        filter: blur(3px) brightness(90%);
        -webkit-filter: blur(3px) brightness(90%);
        -moz-filter: blur(3px) brightness(90%);
        -o-filter: blur(3px) brightness(90%);
        -ms-filter: blur(3px) brightness(90%);
        pointer-events: none;
    }

    .spacer {
        min-width: 250px;
        width: 20vw;
    }

    /* VUE TRANSITION */
    .fade-enter-active,
    .fade-leave-active {
        transition: opacity 0.2s ease;
    }
    .fade-enter-from,
    .fade-leave-to {
        opacity: 0;
    }

    /* css  */
    myroute {
        cursor: pointer;
    }

    .btn-back {
        color: #3699ff;
        outline: 1px solid #3699ff;
    }

    .btn-back:hover {
        color: #3699ff;
        outline: 1px solid #3699ff;
        box-shadow: 0 0 1px 1px rgba(0, 0, 0, 0.5);
    }

    .usfc-bg {
        background-color: #fbfbfb;
    }

    #app {
        font-family: Avenir, Helvetica, Arial, sans-serif;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        text-align: center;
        color: #2c3e50;
    }

    input[type="password"] {
        font-family: Verdana;
        letter-spacing: 0.25em;
        font-weight: 900;
    }

    .toggle-password,
    .toggle-password-confirm {
        cursor: pointer;
    }

    .blue-filter {
        filter: invert(45%) sepia(69%) saturate(2325%) hue-rotate(196deg) brightness(108%) contrast(101%);
    }
    .profile-card {
        background-color: white;
        width: 75px;
        height: 100%;
        border-radius: 20px;
        cursor: pointer;
    }

    /* .profile-card:hover {
        border: solid 1px rgba(54, 153, 255, 0.25);
      } */

    .prevent-select {
        -webkit-user-select: none; /* Safari */
        -ms-user-select: none; /* IE 10 and IE 11 */
        user-select: none; /* Standard syntax */
    }

    .parent_arrow {
        transform: rotate(180deg);
    }

    .user-button {
        z-index: 999;
        position: absolute;
        width: auto;
        right: 20px;
        top: 15vh;
        background-color: white;
        box-shadow: 5px 5px 10px rgba(0, 0, 0, 0.05);
        text-align: center;
        border-radius: 6px;
    }

    ul.user-button li {
        position: relative;
        padding: 10px 0;
        width: 200px;
        display: block;
        font-weight: 400;
        font-size: 14px;
        cursor: pointer;
        vertical-align: middle;
        line-height: 2em;
    }

    ul.user-button {
        position: absolute;
        top: 75px;
        right: 0;
        padding-left: 0;
    }

    li:hover {
        opacity: 1;
        background: rgba(54, 153, 255, 0.1);
        border-radius: 6px;
        filter: invert(42%) sepia(100%) saturate(546%) hue-rotate(178deg) brightness(107%) contrast(101%);
    }

    .sign-out-button {
        filter: invert(51%) sepia(27%) saturate(6806%) hue-rotate(329deg) brightness(111%) contrast(104%);
    }

    .sign-out-button:hover {
        filter: invert(51%) sepia(27%) saturate(6806%) hue-rotate(329deg) brightness(111%) contrast(104%);
    }

    .top-panel {
        height: 10vh;
    }

    a {
        color: inherit;
        text-decoration: none;
    }

    /* INPUT FORM LABEL STYLE */
    .label-float {
        position: relative;
        padding-top: 13px;
    }

    input {
        width: 100%;
        border: 1px solid transparent;
    }

    select {
        width: 100%;
        border: 1px solid transparent;
    }

    .btn {
        font-size: small;
        min-width: 90px;

        border-radius: 6px;
        padding: 10px 25px;
    }

    .btn.btn-cancel {
        border-radius: 6px;
        padding: 10px 25px;
    }

    .btn.btn-cancel:hover {
        border: 1px solid rgba(0, 0, 0, 0.2);
    }

    .btn.btn-cancel:active {
        border: 1px solid rgba(0, 0, 0, 1);
    }

    .btn.btn-usfc-primary {
        background-color: #3699ff;
        color: white;
        border-radius: 6px;
        padding: 10px 25px;
    }

    .btn-usfc-primary:hover {
        background-color: #3699ff;
        color: white;
        box-shadow: 5px 5px 15px 10px rgba(0, 0, 0, 0.05);
    }

    .btn.btn-usfc-danger {
        background-color: #f25b5b;
        color: white;
        border-radius: 6px;
        padding: 10px 25px;
        border: 1px solid transparent;
    }

    .btn-usfc-danger:hover {
        color: #f25b5b;
        background-color: white;
        border: 1px solid #f25b5b;
    }

    .label-float input {
        border: none;
        background-color: whitesmoke;
        border-radius: 5px;
        outline: none;
        min-width: 250px;
        padding: 15px 20px;
        font-size: 16px;
        transition: all 0.1s linear;
        -webkit-transition: all 0.1s linear;
        -moz-transition: all 0.1s linear;
        -webkit-appearance: none;
        top: 10px;
    }

    .label-float select {
        border: none;
        background-color: whitesmoke;
        border-radius: 5px;
        outline: none;
        min-width: 250px;
        padding: 15px 20px;
        font-size: 16px;
        transition: all 0.1s linear;
        -webkit-transition: all 0.1s linear;
        -moz-transition: all 0.1s linear;
        -webkit-appearance: none;
        top: 10px;
    }

    .label-float input:focus {
        border: 2px solid #3699ff;
        background-color: transparent;
    }

    .label-float select:focus {
        border: 2px solid #3699ff;
        background-color: transparent;
    }

    .label-float input::placeholder {
        color: black;
    }

    .label-float select::placeholder {
        color: black;
    }

    .label-float label {
        pointer-events: none;
        position: absolute;
        top: calc(50% - 8px);
        left: 15px;
        transition: all 0.1s linear;
        -webkit-transition: all 0.1s linear;
        -moz-transition: all 0.1s linear;
        background-color: transparent;
        padding: 5px;
        box-sizing: border-box;
        color: rgba(0, 0, 0, 0.5);
    }

    .label-float input:required:invalid + label {
        color: red;
    }
    .label-float input:focus:required:invalid {
        border: 2px solid red;
    }
    .label-float input:required:invalid + label:before {
        content: "*";
    }
    .label-float input:focus + label,
    .label-float input:not(:placeholder-shown) + label {
        font-size: 13px;
        top: 10px;
        color: #3951b2;
        opacity: 1;
        background-color: transparent;
    }

    .label-float select:required:invalid + label {
        color: red;
    }
    .label-float select:focus:required:invalid {
        border: 2px solid red;
    }
    .label-float select:required:invalid + label:before {
        content: "*";
    }

    label.option-selected {
        font-size: 13px;
        transform: translateY(calc(-55%));
        color: #3951b2;
        background-color: transparent;
    }

    /* FA ICONS CUSTOM */
    .fa-check {
        margin: 0 5px;
        position: relative;
        top: -2px;
        font-size: 12px;
        color: white;
        border-radius: 50%;
        padding: 3px;
        background-color: #3699ff;
    }

    /* * {
    border: 1px solid red;
  } */
</style>
