<template>
    <div class="report-container">
        <div class="report-filter">
            <div class="row">
                <div v-if="this.$store.state.userData.access_level > 2" class="col-6">
                    <div class="label-float">
                        <label :class="bodyForm.userId == null ? '' : 'option-selected'">
                            <span v-if="bodyForm.userId">Choose Client</span>
                            <span v-else> Choose Client</span>
                        </label>
                        <label v-if="bodyForm.userId == null" :class="bodyForm.userId == null ? '' : 'option-selected'" style="display: block; width: 90%; text-align: right; opacity: 0.4"> </label>
                        <select v-model="bodyForm.userId" placeholder=" ">
                            <option :value="null"><span style="color: rgba(0, 0, 0, 0.5)">Choose Client</span></option>
                            >
                            <option v-for="(item, index) in allClient" :key="index" :value="item.id">{{ item.company }}</option>
                        </select>
                    </div>
                </div>
                <div class="col">
                    <div class="label-float">
                        <Datepicker format="dd/MM/yyyy" :auto-apply="true" :enable-time-picker="false" v-model="bodyForm.start_date" position="center" placeholder="Select Start Period" :start-date="dateNow" :keep-action-row="false" />
                        <label :class="'option-selected'">Start Period</label>
                    </div>
                </div>
                <div class="col">
                    <div class="label-float">
                        <Datepicker format="dd/MM/yyyy" :auto-apply="true" :enable-time-picker="false" v-model="bodyForm.end_date" position="center" placeholder="Select End Period" :start-date="this.bodyForm.start_date" :min-date="this.bodyForm.start_date" :keep-action-row="false" />
                        <label :class="'option-selected'">End Period</label>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col text-end px-3 mx-1"><button :disabled="!bodyForm.userId || !bodyForm.start_date || !bodyForm.end_date" @click="submit()" style="float: right" class="mx-2 btn btn-usfc-primary">Submit</button></div>
            </div>
        </div>
        <div class="report-content">
            <div v-if="dataExists && chartData?.total" class="title">SLA Report</div>
            <div class="content">
                <div v-if="dataExists && chartData?.total" class="grades">
                    <div class="grade">
                        <div class="perc">{{ chartData.A.percentage }}%</div>
                        <div class="d-flex text-a" style="align-items: center; gap: 12px; transform: translateX(-28px)">
                            <div class="dot_a"></div>
                            Grade A
                        </div>
                        <div class="total">{{ chartData.A.total }} Receipts</div>
                    </div>
                    <div class="grade" style="padding-top: 30%">
                        <div class="perc">{{ chartData.B.percentage }}%</div>
                        <div class="d-flex text-b" style="align-items: center; gap: 12px; transform: translateX(-28px)">
                            <div class="dot_b"></div>
                            Grade B
                        </div>
                        <div class="total">{{ chartData.B.total }} Receipts</div>
                    </div>
                    <div class="grade">
                        <div class="perc">{{ chartData.C.percentage }}%</div>
                        <div class="d-flex text-c" style="align-items: center; gap: 12px; transform: translateX(-28px)">
                            <div class="dot_c"></div>
                            Grade C
                        </div>
                        <div class="total">{{ chartData.C.total }} Receipts</div>
                    </div>
                </div>
                <div class="chart">
                    <div v-if="!chartData?.total">
                        <img src="@/assets/empty_state_pie.svg" style="width: 350px" />
                        <div class="text-center">{{ this.chartData.total == 0 ? "No report data is avaliable on the selected data for this client" : "You will see your SLA report here" }}</div>
                    </div>

                    <Doughnut v-else id="chart" :options="chartOptions" :data="mutableDoughnut" />
                </div>
            </div>
        </div>
    </div>
</template>
<script>
    import moment from "moment";
    import axios from "axios";
    import Datepicker from "@vuepic/vue-datepicker";
    import { Doughnut } from "vue-chartjs";
    import _ from "lodash";
    import { Chart as ChartJS, Title, Tooltip, Legend, ArcElement, CategoryScale, LinearScale } from "chart.js";
    import ChartDataLabels from "chartjs-plugin-datalabels";

    ChartJS.register(Title, Tooltip, Legend, ArcElement, CategoryScale, LinearScale, ChartDataLabels);
    export default {
        components: {
            Datepicker,
            Doughnut,
        },
        data() {
            return {
                bodyForm: {
                    start_date: null,
                    end_date: null,
                    userId: null,
                },
                chartData: { total: null },
                DoughnutChartData: {
                    labels: [],
                    datasets: [{ data: [], backgroundColor: ["#0abc06", "#f6931f", "#f25b5b"] }],
                },
                chartOptions: {
                    responsive: true,
                    hoverOffset: 50,
                    layout: {
                        padding: 30,
                    },
                    plugins: {
                        legend: {
                            display: false,
                            labels: {
                                color: "rgb(255, 99, 132)",
                            },
                        },
                        datalabels: {
                            formatter: (value, ctx) => {
                                let sum = 0;
                                let dataArr = ctx.chart.data.datasets[0].data;
                                dataArr.map((data) => {
                                    sum += data;
                                });
                                let percentage = ((value * 100) / sum).toFixed(2) + "%";
                                return percentage;
                            },
                            color: "#FFF",
                        },
                    },
                },
            };
        },
        computed: {
            dataExists() {
                return this.DoughnutChartData?.datasets[0]?.data?.length > 0;
            },
            allClient() {
                return this.$store.state.app.managerForm.allClients;
            },
            dateNow() {
                return new Date();
            },
            mutableDoughnut() {
                return this.DoughnutChartData;
            },
        },
        methods: {
            submit() {
                let token = JSON.parse(localStorage.getItem("token"));

                axios
                    .get(`${this.$store.state.app.apiBasepath}/report/sla/${this.bodyForm.userId}?start_date=${moment(this.bodyForm.start_date).format("YYYY-MM-DD")}&end_date=${moment(this.bodyForm.end_date).format("YYYY-MM-DD")}`, { headers: { Authorization: `Bearer ${token}` } })
                    .then((res) => {
                        this.DoughnutChartData.labels = [];
                        this.DoughnutChartData.datasets[0].data = [];
                        this.chartData.total = null;

                        setTimeout(() => {
                            this.chartData = res.data;
                            const temp = _.cloneDeep(this.chartData);
                            delete temp.total;
                            for (const [key, value] of Object.entries(temp)) {
                                console.log(value);
                                this.DoughnutChartData.labels.push("Grade " + key + ":");
                                this.DoughnutChartData.datasets[0].data.push(value.total);
                            }
                        }, 100);
                    })
                    .catch(() => {
                        console.log("500: " + token);
                    });
            },
            getAllClients() {
                let token = JSON.parse(localStorage.getItem("token"));

                const x = this.$store.state.userData.access_level;
                if (x >= 4) {
                    axios
                        .get(this.$store.state.app.apiBasepath + "/user/all-client", { headers: { Authorization: `Bearer ${token}` } })
                        .then((res) => {
                            console.log("200: " + res);
                            this.$store.state.app.managerForm.allClients = res.data.AllClient.rows;
                            this.ascSort();
                            console.log(this.allClient);
                        })
                        .catch(() => {
                            console.log("500: " + token);
                        });
                } else if (x === 3) {
                    axios
                        .get(this.$store.state.app.apiBasepath + "/user/sales-details/" + this.$store.state.userData.id, { headers: { Authorization: `Bearer ${token}` } })
                        .then((res) => {
                            console.log("200: " + res);
                            this.$store.state.app.managerForm.allClients = res.data.client;
                            this.ascSort();
                            console.log(this.allClient);
                        })
                        .catch(() => {
                            console.log("500: " + token);
                        });
                } else if (x === 2) {
                    this.bodyForm.userId = this.$store.state.userData.id;
                }
            },
            ascSort() {
                function compare(a, b) {
                    if (a.company < b.company) return -1;
                    if (a.company > b.company) return 1;
                }

                this.$store.state.app.managerForm.allClients.sort(compare);
            },
        },
        mounted() {
            this.getAllClients();
        },
    };
</script>
<style lang="scss" scoped>
    #chart {
        max-width: 400px;
        max-height: 400px;
    }
    .dp__main + .option-selected {
        transform: translateX(15px) translateY(-22px) !important;
    }
    .label-float {
        label {
            font-size: 12px !important;
            z-index: 99;
            transform: translateX(0px) translateY(-15px) !important;
        }

        &:has(.dp__main) {
            padding-top: 0;
        }
    }
    .report-container {
        height: 87vh;
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        background: transparent;

        .report-filter {
            padding-bottom: 22px;
            width: 100%;
            .dp__main {
                padding: 15px;
                input {
                    background-color: whitesmoke;
                    padding: 15px 20px;
                    height: 100%;
                    border: none;
                }

                .dp__input_icon {
                    left: auto !important;
                    right: 0 !important;
                }
            }
        }

        .report-content {
            width: 100%;
            flex-grow: 1;
            display: flex;
            flex-direction: column;
            justify-content: center;
            flex-wrap: wrap;
            padding: 22px;
            .title {
                /* Others/Soft-Black */

                color: #777777;
                font-weight: 700;
                font-size: 16px;
                line-height: 22px;
                padding: 15px 0;
            }

            .content {
                display: flex;
                flex-direction: row;
                justify-content: space-between;

                .grades {
                    display: flex;
                    width: 50%;
                    padding-top: 5%;
                    padding-left: 10%;

                    .grade {
                        text-align: start;
                        // justify-content: space-between;
                        display: flex;
                        flex-direction: column;
                        gap: 6px;
                        flex-grow: 1;

                        .perc {
                            font-size: 18px;
                            font-weight: 800;
                            opacity: 0.6;
                        }

                        div {
                        }

                        .text {
                            display: inline-block;
                            &-a {
                                color: #0abc06;
                            }
                            &-b {
                                color: #f6931f;
                            }
                            &-c {
                                color: #f25b5b;
                            }
                        }

                        .total {
                            opacity: 0.5;
                        }

                        .dot {
                            display: inline-block;
                            &_a {
                                flex-grow: 0;
                                width: 16px;
                                height: 16px;
                                border-radius: 50%;
                                background-color: #0abc06;
                            }
                            &_b {
                                flex-grow: 0;
                                width: 16px;
                                height: 16px;
                                border-radius: 50%;
                                background-color: #f6931f;
                            }
                            &_c {
                                flex-grow: 0;
                                width: 16px;
                                height: 16px;
                                border-radius: 50%;
                                background-color: #f25b5b;
                            }
                        }
                    }
                }

                .chart {
                    flex-grow: 1;
                    justify-content: center;
                    justify-items: center;
                    align-items: center;
                    display: flex;
                    flex-direction: column;
                    flex-grow: 1;
                }
            }
        }
    }
</style>
