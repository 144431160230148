<template>
    <div class="d-inline">
        <input v-model="this.$store.state.app.managerForm.search" class="searchbar fa" type="text" style="padding: 1% 17% 1% 1% !importan; font-family: Open Sans, FontAwesome" placeholder="&#xF002;  search.. " />
    </div>
    <div @click="this.$store.state.app.openModal = 'transporterCreate'" v-if="this.$store.state.app.managerForm.idSelects.length == 0" style="float: right">
        <button class="btn btn-usfc" style="border-radius: 6px"><span>Create Transporter</span> <span> + </span></button>
    </div>

    <div v-else style="float: right">
        <button @click="this.$store.state.app.managerForm.idSelects = []" class="btn cancel mx-2" style="border-radius: 6px">
            <span class="px-4" style="font-weight: 300">Cancel</span>
        </button>
        <button class="btn red-filter" style="border-radius: 6px">
            <span><img src="/icons/all/twotone/trash.svg" style="padding-right: 10px; transform: scale(0.9); position: relative; top: -2px" class="blue-filter" />Delete ({{ this.$store.state.app.managerForm.idSelects.length }})</span>
        </button>
    </div>
    <div class="row my-3">
        <!-- <div class="d-inline">
      <span class="mx-2" style="cursor: pointer"><img src="/icons/custom/picker-deselect.svg" /></span>
      <span class="mx-4"> &emsp; </span>
      <span class="mx-2" style="cursor: pointer">
        Name
        <iconGroup class="blue-filter">
          <img src="/icons/all/twotone/arrow-up.svg" class="scaled" />
          <img src="/icons/all/twotone/arrow-down-1.svg" class="scaled" style="position: relative; left: -15px" />
        </iconGroup>
      </span>
    </div> -->
        <TableView ref="transporterTable" />
    </div>
</template>

<script>
    import TableView from "./TableView.vue";
    /* eslint-disable */

    export default {
        name: "ManagerComponent",
        props: {},
        components: { TableView },
        data() {
            return {
                loadForm: "signin",
                menu: "Manage",
                submenu: "Users",
                userAction: false,
            };
        },
        computed: {
            getLoadForm() {
                return this.loadForm;
            },
            currentMenu() {
                return this.menu;
            },
            currentSubmenu() {
                return this.submenu;
            },
            isUserAction() {
                return this.userAction;
            },
        },
        methods: {
            toggleModal() {
                this.$store.state.app.overlay.blur = true;
            },
        },
    };
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
    .usfc-bg {
        background-color: #fbfbfb;
    }

    .btn {
        transition: all 0.15s ease;
    }

    .btn-usfc {
        background-color: #3699ff;
        color: white;
        font-size: 0.9em;
        padding: 10px 15px;
    }

    .btn-usfc:hover {
        background-color: white;
        color: black;
        border: 1px solid #3699ff;
    }

    .scaled {
        transform: scale(0.8);
    }

    .searchbar {
        background-color: #fbfbfb;
        border: none;
        border-radius: 6px;
        padding: 1% 17% 1% 1%;
    }

    .searchbar::placeholder {
        /* Most modern browsers support this now. */
        color: rgba(0, 0, 0, 0.2);
        padding: 10px;
        font-size: 0.9em;
    }
    .cancel:hover {
        font-weight: bold;
        border: solid 1px rgba(0, 0, 0, 0.2);
    }

    .cancel:active {
        font-weight: bold;
        border: solid 1px rgba(0, 0, 0, 1);
    }

    input.searchbar {
        border: solid 1px rgba(54, 153, 255, 0);
    }

    input.searchbar:focus {
        outline: none !important;
        border: solid 1px rgba(54, 153, 255, 0.5);
    }

    .red-filter {
        background: rgba(54, 153, 255, 0.1);
        filter: invert(51%) sepia(27%) saturate(6806%) hue-rotate(329deg) brightness(111%) contrast(104%);
    }

    .red-filter:hover {
        background: rgba(54, 153, 255, 0.25);
    }

    .blue-filter {
        filter: invert(45%) sepia(69%) saturate(2325%) hue-rotate(196deg) brightness(108%) contrast(101%);
    }
    .profile-card {
        background-color: white;
        width: 7%;
        height: 100%;
        border-radius: 20px;
        cursor: pointer;
    }

    /* .profile-card:hover {
    border: solid 1px rgba(54, 153, 255, 0.25);
  } */

    .prevent-select {
        -webkit-user-select: none; /* Safari */
        -ms-user-select: none; /* IE 10 and IE 11 */
        user-select: none; /* Standard syntax */
    }

    .parent_arrow {
        transform: rotate(180deg);
    }

    .user-button {
        position: absolute;
        right: 3vw;
        top: 15vh;
        width: 12vw;
        background-color: white;
        box-shadow: 5px 5px 10px rgba(0, 0, 0, 0.05);
        text-align: left;
        border-radius: 6px;
    }

    ul {
        height: 5%;
    }

    li {
        text-align: center;
        display: inline;
        padding: 1% 0;
        margin: 0;
        width: 8%;
        font-weight: 400;
        cursor: pointer;
        vertical-align: middle;
    }

    li.active {
        opacity: 1;
        background: rgba(54, 153, 255, 0.1);
        border-radius: 6px;
        filter: invert(42%) sepia(100%) saturate(546%) hue-rotate(178deg) brightness(107%) contrast(101%);
    }

    li:hover {
        opacity: 1;
        background: rgba(54, 153, 255, 0.1);
        border-radius: 6px;
        filter: invert(42%) sepia(100%) saturate(546%) hue-rotate(178deg) brightness(107%) contrast(101%);
    }

    .sign-out-button {
        filter: invert(51%) sepia(27%) saturate(6806%) hue-rotate(329deg) brightness(111%) contrast(104%);
    }

    .sign-out-button:hover {
        filter: invert(51%) sepia(27%) saturate(6806%) hue-rotate(329deg) brightness(111%) contrast(104%);
    }

    .top-panel {
        height: 10vh;
    }

    .frame-content {
        min-height: 80vh;
    }
    .illustration {
        align-items: center;
        flex-wrap: wrap;
    }

    .vh-100 {
        height: 100vh;
    }
    h3 {
        margin: 40px 0 0;
    }
    ul {
        list-style-type: none;
        padding: 0;
    }
    li {
        display: inline-block;
        margin: 0 10px;
    }
    a {
        color: #42b983;
    }
</style>
