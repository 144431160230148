<template>
  <div id="errpage" class="container-fluid m-0 p-0">
    <div class="row h-100">
      <div class="col my-auto text-center">
        <h1 class="blue-filter">{{ this.$store.state.globalStates.err.code }}</h1>
        <h2>{{ this.$store.state.globalStates.err.desc }}</h2>
        <input name="action" @click="backTo()" type="submit" value="Go Back" class="btn-usfc my-3" />
 
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    name: "ErrorPage",
    props: {
      msg: String,
    },
    methods: {
      backTo() {
        history.back();
        setTimeout(() => {
          this.clearErrState();
        }, 250);
      },
      clearErrState() {
        this.$store.state.globalStates.err.stat = false;
        this.$store.state.globalStates.err.code = "";
        this.$store.state.globalStates.err.desc = "";
      },
    },
  };
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
  #errpage {
    height: 100vh;
    width: 100vw;
    background: white;
    position: fixed;
    top: 0;
    z-index: 999;
  }

  .btn-usfc {
    /* Blue/Primary */
    color: white;
    background: #3699ff;
    border-radius: 6px;
    border: 1px solid transparent;
    width: 15vw;
    height: 36px;
    font-family: "Open Sans";
    font-style: normal;
    font-weight: 600;
  }

  .blue-filter {
    filter: invert(45%) sepia(69%) saturate(2325%) hue-rotate(196deg) brightness(108%) contrast(101%);
  }
  h1 {
    font-size: 72px;
    font-weight: 900;
  }

  h2 {
    font-size: 24px;
    font-weight: 900;
  }

  * {
    user-select: none;
  }
</style>
