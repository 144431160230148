<template>
    <div style="min-height: 90vh">
        <div id="modalTitle" class="row">
            <div class="col p-2">
                <h5>
                    <span class="p-2" style="font-weight: 700; float: left">Booking Approval</span>
                    <span @click="this.$store.state.app.openModal = ''" id="closeModal"><img class="d-flex justify-content-center" src="/icons/all/outline/add.svg" /></span>
                </h5>
            </div>
        </div>
        <div id="stages" class="row d-flex flex-nowrap">
            <template v-for="stage in stages" v-bind:key="stage.name">
                <!-- <span class="tracker" style="opacity: 0; width: 0; transition: all 0.4s ease" :style="stage.status == 2 ? 'width: 32px; opacity: 1;' : ''">
                    <Transition name="fade" v-if="stage.status == 2"> <i class="fa fa-check" aria-hidden="true"></i> </Transition>
                </span> -->
                <div @click="stageIndex = stage.index" style="transition: all 0.4s ease" :class="stageIndex === stage.index ? 'active' : ''" class="col-auto tab">{{ stage.name }}</div>
            </template>
            <!-- <div class="col-5">
                <span class="tracker"> </span>
            </div> -->
        </div>
        <form-wrapper v-if="stageIndex === 0">
            <div v-if="bodyForm.transporter" class="row my-1">
                <div class="col-2">
                    <img v-if="bodyForm.transporter.photo === null" src="/icons/default.svg" style="object-fit: cover; height: 42px; border-radius: 50%; width: 42px" />
                    <img v-else :src="'https://s3-usfc-avatar-image.s3.ap-southeast-1.amazonaws.com/' + bodyForm.transporter.photo" style="object-fit: cover; height: 42px; border-radius: 50%; width: 42px" />
                </div>
                <div class="col">
                    <div class="row">{{ bodyForm.transporter.full_name }}</div>
                    <div class="row">{{ bodyForm.transporter.phone }}</div>
                </div>
            </div>
            <div v-else id="forms" class="row text-start">
                <form>
                    <div class="label-float">
                        <div class="d-inline">
                            <input v-model="this.$store.state.app.managerForm.formSearch" class="searchbar fa" type="text" style="padding: 1% 17% 1% 1%; font-family: Open Sans, FontAwesome" placeholder="&#xF002;  search.. " />
                        </div>
                    </div>
                    <br />
                    <div class="px-3 py-0 my-0 prevent-select">
                        <table class="table" v-if="this.$store.state.app.managerForm.formSearch != ''">
                            <tbody style="height: 20vh">
                                <tr @click="chooseTransporter(sales.id)" class="sales" v-for="sales in filteredTransporter" v-bind:key="sales.id">
                                    <th scope="row" class="col-2"><img src="/icons/default_company.svg" style="object-fit: cover; height: 42px; border-radius: 50%; width: 42px" /></th>
                                    <td class="col text-start">
                                        <span style="line-height: 2.5em">{{ sales.full_name }}</span>
                                    </td>
                                    <td v-if="this.bodyForm.userId == sales.id" scope="row" style="position: relative; top: 5px">
                                        <span style="float: right">
                                            <img src="/icons/custom/picker.svg" />
                                        </span>
                                    </td>
                                    <td v-else scope="row" style="position: relative; top: 5px">
                                        <span style="float: right">
                                            <img src="/icons/custom/picker-deselect.svg" />
                                        </span>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                        <div class="text-center" v-else style="font-size: 14px; opacity: 0.4">Type the transporter name above</div>
                    </div>
                </form>
            </div>
        </form-wrapper>
        <form-wrapper v-show="stageIndex === 1">
            <selectLocation @openSelector="showSelector" :show="show" @onUpdate="gasLocationsUpdate" :locations="gasLocations" />
        </form-wrapper>
        <form-wrapper v-if="stageIndex === 2">
            <div id="forms" class="row m-2" style="overflow-y: auto" :style="isApproved ? 'max-height: 75vh' : 'max-height: 65vh'">
                <div class="col-12 mb-3" style="border: 1px dashed black">
                    <div class="row text-start py-2" style="background: rgba(0, 0, 0, 0.1)">
                        <div style="font-weight: 600; font-size: 16px">Estimated Cost</div>
                    </div>
                    <div class="p-2">
                        <div class="row">
                            <div class="col">
                                <div class="row pb-2 mb-1" style="border-bottom: 1px solid rgba(0, 0, 0, 0.2)">
                                    <div class="col text-start">Rate</div>
                                    <div class="col text-start"></div>
                                    <div class="col text-start">Weight (kg)</div>
                                    <div class="col text-start"></div>
                                    <div class="col text-end">Amount</div>
                                </div>
                                <div class="row">
                                    <div class="col text-start">{{ `Rp ${getBodyForm.client_rate.rate.toLocaleString()}` }}</div>
                                    <div class="col">x</div>
                                    <div class="col text-start">{{ `${getBodyForm.total_accounted_weight.toLocaleString()} (Kg)` }}</div>
                                    <div class="col">=</div>
                                    <div class="col text-end">Rp {{ Number(calculateEstimation).toLocaleString() }}</div>
                                </div>
                            </div>
                        </div>
                        <br />

                        <div class="row" style="font-weight: 600; font-size: 18px">
                            <div class="col text-start"></div>
                            <div class="col text-end">Rp {{ Number(calculateEstimation).toLocaleString() }}</div>
                        </div>
                    </div>
                </div>
                <div class="col-12 mb-3" style="border: 1px dashed black">
                    <div class="row text-start py-2" style="background: rgba(0, 0, 0, 0.1)">
                        <div style="font-weight: 600; font-size: 16px">Additional Fee</div>
                    </div>
                    <div v-if="getApproverData.insurance_fee || getApproverData.repack_fee || getApproverData.other_fee">
                        <div class="p-2">
                            <div class="row" v-if="getApproverData.insurance_fee">
                                <div class="col text-start">Item Value: Rp {{ Number(getApproverData.insurance_item_value).toLocaleString() }}</div>
                                <div class="col text-end">Rp {{ Number(getApproverData.insurance_fee).toLocaleString() }} (Insurance)</div>
                            </div>
                            <div class="row" v-if="getApproverData.repack_fee">
                                <div class="col text-start">{{ getApproverData.repack_value || "(Not Available)" }}:</div>
                                <div class="col text-end">Rp {{ Number(getApproverData.repack_fee).toLocaleString() }} (Repack)</div>
                            </div>
                            <div class="row" v-if="getApproverData.other_fee">
                                <div class="col text-start">{{ getApproverData.other_value || "(Not Available)" }}:</div>
                                <div class="col text-end">Rp {{ Number(getApproverData.other_fee).toLocaleString() }} (Other)</div>
                            </div>
                        </div>
                        <div class="p-2">
                            <div class="row" style="font-weight: 600; font-size: 18px">
                                <div class="col text-start"></div>
                                <div class="col text-end">Rp {{ Number(calculateAdditional).toLocaleString() }}</div>
                            </div>
                        </div>
                    </div>
                    <div v-else class="p-2">
                        <div class="row m-2">
                            <div class="row text-center">
                                <div class="col" style="font-weight: 600; font-size: 16px">No additional fee added</div>
                            </div>
                            <!-- <div class="row my-2 text-center">
                                <div class="col">Confirm all the details are correct before submitting!</div>
                            </div> -->
                        </div>
                    </div>
                </div>
                <div class="col-12" style="border: 1px dashed black">
                    <div class="row text-start py-2" style="background: rgba(0, 0, 0, 0.1)">
                        <div style="font-weight: 600; font-size: 16px">Total Cost</div>
                    </div>
                    <div class="p-2">
                        <div class="row">
                            <div class="col text-start">Estimated Cost</div>
                            <div class="col text-end">Rp {{ Number(calculateEstimation).toLocaleString() }}</div>
                        </div>
                        <div class="row">
                            <div class="col text-start">Additional Fee</div>
                            <div class="col text-end">Rp {{ Number(getApproverData.added_cost).toLocaleString() }}</div>
                        </div>
                        <br />
                        <div class="row">
                            <div class="col text-start">Subtotal</div>
                            <div class="col text-end">Rp {{ Number(calculateTotalCost).toLocaleString() }}</div>
                        </div>
                        <div class="row">
                            <div class="col text-start">{{ getApproverData.tax_value || "(Not Available)" }}:</div>
                            <div class="col text-end">Rp {{ Number(getApproverData.tax_fee).toLocaleString() }}</div>
                        </div>

                        <br />
                        <div class="row" style="font-weight: 600; font-size: 18px">
                            <div class="col text-start">Final Cost:</div>
                            <div class="col text-end" style="color: #3699ff">Rp {{ calculateTotalCostAfterTax.toLocaleString() }}</div>
                        </div>
                    </div>
                </div>
                <div class="col-12">
                    <div class="row my-2">Confirm all the details are correct before submitting!</div>
                </div>
            </div>
        </form-wrapper>

        <div v-if="stageIndex === 2 && ![1, 3].includes(bodyForm.details.booking_status)" id="navigation" class="row my-2 p-0" style="right: 10px">
            <div class="col">
                <span v-if="stages.length == stageIndex + 1" @click="approve()" style="float: right" class="mx-2 btn btn-usfc-primary">Approve Booking</span>
                <button :disabled="!bodyForm.userId" v-else @click="nextStage()" style="float: right" class="mx-2 btn btn-usfc-primary">Next</button>
                <span @click="this.$store.state.app.openModal = ''" v-if="stageIndex == 0" style="float: right" class="mx-2 btn btn-cancel"> Cancel</span>
                <span @click="prevStage()" v-else style="float: right" class="mx-2 btn btn-back"> Back</span>
            </div>
        </div>
        <div v-if="stageIndex === 1 && [2, 3].includes(bodyForm.details.booking_status) && (this.gasLocations != this.tempGasLocations) & !show" id="navigation" class="row my-2 p-0" style="right: 10px">
            <div class="col">
                <span @click="saveGasLocations()" style="float: right" class="mx-2 btn btn-usfc-primary"> Update Gas Stations</span>
                <span @click="this.gasLocations = this.tempGasLocations" style="float: right" class="mx-2 btn btn-cancel">Revert</span>
            </div>
        </div>
    </div>
</template>

<script>
    import "jquery/dist/jquery.js";

    import "bootstrap/dist/css/bootstrap.min.css";
    import "bootstrap/dist/js/bootstrap.bundle.min.js";
    import "bootstrap-icons/font/bootstrap-icons.css";
    import $ from "jquery";
    import axios from "axios";
    import selectLocation from "./selectLocation.vue";

    export default {
        name: "adminCreate",
        components: { selectLocation },
        computed: {
            calculateEstimation() {
                if (this.isApproved) return this.getBodyForm.total_cost - this.calculateAdditional;
                return this.getBodyForm.total_cost;
            },
            // calculateEstimation() {
            //     const taxRevert = 1.011;
            //     if (this.isApproved) return (+this.calculateTotalCostAfterTax - +this.calculateAdditional - +this.getApproverData.tax_fee) / taxRevert;
            //     return +this.calculateTotalCostAfterTax - +this.calculateAdditional - +this.getApproverData.tax_fee;
            // },
            calculateAdditional() {
                return +this.getApproverData.insurance_fee + +this.getApproverData.repack_fee + +this.getApproverData.other_fee;
            },
            calculateTotalCost() {
                return this.calculateEstimation + this.calculateAdditional;
            },
            // calculateTotalCost() {
            //     return (this.isApproved ? +this.getBodyForm.total_cost - +this.getApproverData.tax_fee : +this.getBodyForm.total_cost) + (this.isApproved ? 0 : +this.calculateAdditional);
            // },
            calculateTotalCostAfterTax() {
                if (this.isApproved) return this.getBodyForm.total_cost + this.getApproverData.tax_fee;
                return this.calculateTotalCost * 0.011 + this.calculateTotalCost;
            },
            // calculateTotalCostAfterTax() {
            //     if (this.isApproved) return this.calculateTotalCost;
            //     return +this.calculateTotalCost + +this.getApproverData.tax_fee;
            // },
            isApproved() {
                return this.getBodyForm.booking_status !== 4;
            },
            getBodyForm() {
                return this.$store.state.app.currentBodyForm;
            },
            getApproverData() {
                return this.getBodyForm.approver;
            },
            filteredTransporter() {
                if (this.$store.state.app.managerForm.formSearch == "") {
                    this.ascSort();
                    return this.$store.state.app.managerForm.filteredTransporter;
                }

                return this.$store.state.app.managerForm.allTransporter.filter((client) => {
                    return client.full_name.toLowerCase().includes(this.$store.state.app.managerForm.formSearch.toLowerCase());
                });
            },
            currentRouteName() {
                return this.$route.name;
            },
            userFullName() {
                if (this.$store.state.userData.full_name.length > 30) {
                    return this.$store.state.userData.full_name.substring(0, 28) + "...";
                } else {
                    return this.$store.state.app.userData.full_name;
                }
            },
            role() {
                if (this.$store.state.userData.access_level == 5) {
                    return "Manager";
                } else if (this.$store.state.userData.access_level == 4) {
                    return "Admin";
                } else if (this.$store.state.userData.access_level == 3) {
                    return "Sales";
                } else if (this.$store.state.userData.access_level == 2) {
                    return "Client";
                } else {
                    return "Transporter";
                }
            },
        },
        data() {
            return {
                show: false,
                photo64: "",
                photoFile: "",
                access_level: 2,
                stageIndex: 0,
                pwdconf: "",
                stages: [
                    // status (0: upcoming, 1: active, 2: done)
                    {
                        name: "Choose Transporter",
                        status: 1,
                        index: 0,
                    },
                    {
                        name: "Gas Station Locations",
                        status: 0,
                        index: 1,
                    },
                    {
                        name: "Order Summary",
                        status: 0,
                        index: 2,
                    },
                ],
                bodyForm: {
                    full_name: "",
                    email: "",
                    access_level: 2,
                    username: "",
                    phone: "",
                    password: "",
                    birth_date: "",
                    nationality: "",
                    gender: "",
                    photo: "",
                    company: "",
                    street: "",
                    district: "",
                    province: "",
                    city: "",
                    postal_code: "",
                    userId: null,
                },
                tempGasLocations: [],
                gasLocations: [],
                loadForm: "signin",
                menu: "Manage",
                submenu: "Users",
                userAction: false,
                publicRouteNames: ["Signin", "ResetPass", "PassChanged"],
            };
        },
        methods: {
            async getDetail(id) {
                let token = JSON.parse(localStorage.getItem("token"));

                const data = await axios.get(this.$store.state.app.apiBasepath + "/booking-receipt/details/" + id, { headers: { Authorization: `Bearer ${token}` } }).then((res) => {
                    this.$store.state.app.breadcrumb.currentSubSubMenu = res.data.details.booking_number;

                    return res.data;
                });

                if (data) {
                    this.bodyForm = data;

                    if (data.transporter) this.stages[0].name = "Transporter";
                    if (this.$store.state.userData.access_level < 4) this.stages.splice(1, 1);
                }

                const gas = await axios.get(this.$store.state.app.apiBasepath + "/booking-receipt/gas-station-locations/" + id, { headers: { Authorization: `Bearer ${token}` } }).then((res) => {
                    return res.data.rows;
                });

                if (gas) {
                    this.gasLocations = gas;

                    await Promise.all(
                        this.gasLocations.map(async (gas) => {
                            gas.lat = gas.latitude;
                            gas.lng = gas.longitude;
                            gas.formatted_address = await this.getLocDetails({ lat: gas.lat, lng: gas.lng });
                            console.log(gas, "rob");
                            return gas;
                        })
                    );

                    this.tempGasLocations = this.gasLocations;
                }
            },

            async getLocDetails(coords) {
                try {
                    const response = await axios.get("https://api.macra.co.id/maps/place?searchQuery=" + `${coords.lat},${coords.lng}` + "&lat=" + coords.lat + "&lng=" + coords.lng);
                    console.log(response.data, "555");
                    return response.data.results[0].formatted_address;
                } catch (error) {
                    console.log(error);
                }
            },

            async saveGasLocations() {
                let token = JSON.parse(localStorage.getItem("token"));
                const basepath = this.$store.state.app.apiBasepath;

                //localhost:2002/dev/usfc/booking-receipt/gas-station-locations/103?gasLocationId=2

                const data = await axios.delete(basepath + "/booking-receipt/gas-station-locations/" + this.$route.params.id, { headers: { Authorization: `Bearer ${token}` } });

                const promises = this.gasLocations.map((obj) => {
                    return new Promise((resolve, reject) => {
                        const modifiedObj = {
                            title: obj.title,
                            latitude: obj.lat,
                            longitude: obj.lng,
                        };

                        axios
                            .post(basepath + "/booking-receipt/gas-station-locations/" + this.$route.params.id, modifiedObj, { headers: { Authorization: `Bearer ${token}` } })
                            .then((response) => {
                                resolve(response.data); // Resolve the promise with the response data
                            })
                            .catch((error) => {
                                reject(error); // Reject the promise with the error
                            });
                    });
                });

                if (data) {
                    Promise.all(promises);
                    this.tempGasLocations = this.gasLocations;

                    console.log(this.tempGasLocations === this.gasLocations, "same");
                }
                // Execute all promises
            },
            gasLocationsUpdate(e) {
                console.log(this.tempGasLocations, "aaa");
                this.gasLocations = e;
                this.show = false;

                console.log(this.gasLocations, "parent");
            },
            showSelector(e) {
                this.show = e;

                console.log(e, "openselector");
            },
            chooseTransporter(id) {
                if (this.bodyForm.userId == id) {
                    this.bodyForm.userId = null;
                } else {
                    this.bodyForm.userId = id;
                }
            },
            ascSort() {
                function compare(a, b) {
                    if (a.full_name < b.full_name) return -1;
                    if (a.full_name > b.full_name) return 1;
                }

                this.page = 1;
                this.sortType = "ASC";
                this.$store.state.app.managerForm.allTransporter.sort(compare);
            },
            getallTransporter() {
                let token = JSON.parse(localStorage.getItem("token"));

                axios
                    .get(this.$store.state.app.apiBasepath + "/user/all-transporter?shippingTypeId=" + this.getBodyForm.client_rate.shipping_type.id, { headers: { Authorization: `Bearer ${token}` } })
                    .then((res) => {
                        this.$store.state.app.managerForm.allTransporter = res.data.rows;
                        this.ascSort();
                    })
                    .catch((err) => {
                        console.log("500: " + err);
                    });
            },
            changePic() {
                $("#pict").click();
            },

            showPreview() {
                let pic = document.getElementById("pict");
                if (pic.files.length > 0) {
                    var src = URL.createObjectURL(pic.files[0]);
                    var preview = document.getElementById("pictureImg");
                    preview.src = src;
                }
            },
            togglePass() {
                $(".toggle-password").toggleClass("bi-eye bi-eye-slash-fill");
                var input = $("#pwd");
                if (input.attr("type") == "password") {
                    input.attr("type", "text");
                } else {
                    input.attr("type", "password");
                }
            },
            next() {
                console.log(this.bodyForm);
            },
            togglePassConfirm() {
                $(".toggle-password-confirm").toggleClass("bi-eye bi-eye-slash-fill");
                var input = $("#pwdconf");
                if (input.attr("type") == "password") {
                    input.attr("type", "text");
                } else {
                    input.attr("type", "password");
                }
            },
            nextStage() {
                if (document.getElementById("pict")) {
                    if (document.getElementById("pict").files.length > 0) {
                        this.photoFile = document.getElementById("pict");
                    } else {
                        this.photoFile = null;
                    }
                }

                this.stages[this.stageIndex].status = 2;
                this.stages[this.stageIndex + 1].status = 1;
                $("#stages").animate({ scrollLeft: "+=100" }, 600);
                this.stageIndex++;
            },
            prevStage() {
                this.stages[this.stageIndex].status = 0;
                this.stages[this.stageIndex - 1].status = 1;
                $("#stages").animate({ scrollLeft: "-=100" }, 600);
                this.stageIndex--;
            },
            photoInput() {
                $("#photoInput").click();
            },
            randomizeName() {
                return this.$CryptoJS.AES.encrypt(Date(), "@Macra2021").toString().substring(0, 16);
            },
            logout() {
                localStorage.clear();
                location.reload();
            },
            currentMenu() {
                if (["Publish Rates", "Client Rates", "Request Change"].includes(this.$route.name)) {
                    return "Rates";
                }

                if (["Users", "References"].includes(this.$route.name)) {
                    return "Manages";
                }

                if (["Receipt", "SLA"].includes(this.$route.name)) {
                    return "Reports";
                } else {
                    return this.$route.name;
                }
            },
            currentSubmenu() {
                let menu = this.currentMenu();
                if (["Dashboard", "Receipts"].includes(menu)) {
                    return "";
                } else {
                    return this.$route.name;
                }
            },
            getMenu(val) {
                this.menu = val;
            },
            getSub(val) {
                this.submenu = val;
            },
            toggleUserAction() {
                this.userAction = !this.userAction;
            },
            setFocusUser(ele) {
                $(ele.target).focus();
                console.log(ele.target);
            },
            keepLogin(getToken) {
                axios
                    .post(this.$store.state.app.apiBasepath + "/user/keep-login", {}, { headers: { Authorization: `Bearer ${JSON.parse(getToken)}` } })
                    .then((response) => {
                        localStorage.setItem("token", JSON.stringify(response.data.token));

                        let userData = response.data.keepLogin;
                        this.$store.state.userData = userData;
                    })
                    .catch(() => {
                        this.$router.push("/signin");
                    });
            },
            encode64() {
                async function listarchivos(fileInput) {
                    var base64; //in this variable i need the base64
                    var fileToLoad = fileInput;
                    base64 = await getBase64(fileToLoad).then((data) => {
                        return data;
                    });
                    console.log(base64);
                    return base64;
                }

                //This is my function for get base64, but not return the string base64
                function getBase64(file) {
                    return new Promise((resolve, reject) => {
                        const reader = new FileReader();
                        reader.readAsDataURL(file);
                        reader.onload = () => resolve(reader.result);
                        reader.onerror = (error) => reject(error);
                        return Promise.resolve(reader.result);
                    });
                }

                let result = listarchivos(this.photoFile);
                return result;
            },
            async approve() {
                let token = JSON.parse(localStorage.getItem("token"));
                const basepath = this.$store.state.app.apiBasepath;

                const promises = this.gasLocations.map((obj) => {
                    return new Promise((resolve, reject) => {
                        const modifiedObj = {
                            title: obj.title,
                            latitude: obj.lat,
                            longitude: obj.lng,
                        };

                        axios
                            .post(basepath + "/booking-receipt/gas-station-locations/" + this.$route.params.id, modifiedObj, { headers: { Authorization: `Bearer ${token}` } })
                            .then((response) => {
                                resolve(response.data); // Resolve the promise with the response data
                            })
                            .catch((error) => {
                                reject(error); // Reject the promise with the error
                            });
                    });
                });

                const body = {
                    insurance_item_value: this.getApproverData.insurance ? this.getApproverData?.insurance_item_value || 0 : 0,
                    repack_value: this.getApproverData.repack ? this.getApproverData?.repack_value || null : null,
                    other_value: this.getApproverData.other ? this.getApproverData?.other_value || null : null,
                    tax_value: this.getApproverData?.tax_value || "PPN Logistic",
                    insurance_fee: this.getApproverData.insurance ? +this.getApproverData?.insurance_fee || 0 : 0,
                    repack_fee: this.getApproverData.repack ? +this.getApproverData?.repack_fee || 0 : 0,
                    other_fee: this.getApproverData.other ? +this.getApproverData?.other_fee || 0 : 0,
                    tax_fee: this.calculateTotalCost * 0.011 || 0,
                    total_cost: Number(this.calculateTotalCost),
                    booking_status: 2,
                    userId: this.bodyForm.userId,
                };

                axios
                    .post(basepath + "/booking-receipt/approve-admin/" + this.$route.params.id, body, { headers: { Authorization: `Bearer ${token}` } })
                    .then(() => {
                        // Execute all promises
                        Promise.all(promises);
                        location.reload();
                    })
                    .catch(() => {
                        this.$alertSys("An error occured", "ERR");
                    });
            },
            ascSortClient() {
                function compare(a, b) {
                    if (a.full_name < b.full_name) return -1;
                    if (a.full_name > b.full_name) return 1;
                }

                this.page = 1;
                this.sortType = "ASC";
                this.$store.state.app.managerForm.allClients.sort(compare);
                this.paginate();
            },
            descSort() {
                function compare(a, b) {
                    if (a.full_name < b.full_name) return 1;
                    if (a.full_name > b.full_name) return -1;
                }

                this.page = 1;
                this.sortType = "DESC";
                this.$store.state.app.managerForm.allClients.sort(compare);
                this.paginate();
            },
            getAllAdmins() {
                let token = JSON.parse(localStorage.getItem("token"));

                axios
                    .get(this.$store.state.app.apiBasepath + "/user/all-client", { headers: { Authorization: `Bearer ${token}` } })
                    .then((res) => {
                        console.log("200: " + res);
                        this.$store.state.app.managerForm.allClients = res.data.AllClient.rows;
                        this.ascSortClient();
                    })
                    .catch(() => {
                        console.log("500: " + token);
                    });
            },
            paginate() {
                // human-readable page numbers usually start with 1, so we reduce 1 in the first argument
                for (let i = 0; i < this.totalPages; i++) {
                    this.$store.state.app.managerForm.filteredClients[i] = JSON.parse(JSON.stringify(this.$store.state.app.managerForm.allClients.slice(i * 6, (i + 1) * 6)));
                }
            },
        },

        mounted() {
            this.getDetail(this.$route.params.id);
            this.getallTransporter();
        },
    };
</script>

<style lang="scss" scoped>
    tbody {
        display: block;
        min-height: 55vh;
        width: 100%;
        overflow: auto;

        &::-webkit-scrollbar-track {
            -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
            background-color: #f5f5f5;
        }

        &::-webkit-scrollbar {
            width: 6px;
            background-color: #f5f5f5;
        }

        &::-webkit-scrollbar-thumb {
            background-color: #3699ff;
            border-radius: 6px;
        }

        tr {
            display: table;
            width: 100%;
        }
    }
    #forms::-webkit-scrollbar {
        width: 0px;
        background: transparent; /* make scrollbar transparent */
    }

    #forms.profile {
        max-height: 45vh;
    }
    #forms.company {
        height: auto;
    }

    #closeModal {
        float: right;
        transform: rotate(45deg);
        border-radius: 50%;
        cursor: pointer;
        transition: 0.3s;
        border: 1px solid transparent;
    }

    #closeModal:hover {
        border: 1px solid black;
    }

    #navigation {
        position: absolute;
        bottom: 0;
        right: 0;
    }

    #stages {
        margin: 0;
        display: flex;
        cursor: default;
        width: 100%;
        .tab {
            flex-grow: 1;
            padding: 12px 22px;
            color: rgba(0, 0, 0, 0.5);
            background-color: #f7f7f7;
            border-bottom: 3px solid rgb(202, 202, 202);

            &:hover {
                border-bottom: 3px solid #3699ff;
                color: rgba(0, 0, 0, 1);
            }
            &.active {
                border-bottom: 3px solid #3699ff;
                color: rgba(0, 0, 0, 1);
            }
        }
    }

    // #stages > .col-auto {
    //     opacity: 0.5;
    // }

    #stages > .col-auto.active {
        opacity: 1;
    }

    #pictureImg {
        cursor: pointer;
        width: 110px;
        height: 110px;
        object-fit: cover;
        object-position: center;
        border-radius: 50%;
    }

    #pictureImgBtn {
        cursor: pointer;
        border-radius: 50%;
        height: 32px;
        width: 32px;
        display: inline-block;
        background-color: #3699ff;

        position: absolute;
        top: 180px;
        left: 53%;
    }

    #pictureImgBtn > #pictureBtn {
        transform: scale(1.2);
        filter: invert(100%) sepia(100%) saturate(0%) hue-rotate(73deg) brightness(103%) contrast(103%);
    }

    #profilePicture {
        height: 20%;
    }

    #stages {
        overflow-x: scroll;
    }
    #stages::-webkit-scrollbar {
        width: 0px;
        background: transparent; /* make scrollbar transparent */
    }

    .blur-overlay {
        display: block;
        filter: blur(3px) brightness(90%);
        -webkit-filter: blur(3px) brightness(90%);
        -moz-filter: blur(3px) brightness(90%);
        -o-filter: blur(3px) brightness(90%);
        -ms-filter: blur(3px) brightness(90%);
        pointer-events: none;
    }

    .spacer {
        min-width: 250px;
        width: 20vw;
    }

    /* VUE TRANSITION */
    .fade-enter-active,
    .fade-leave-active {
        transition: opacity 0.2s ease;
    }
    .fade-enter-from,
    .fade-leave-to {
        opacity: 0;
    }

    /* css  */
    myroute {
        cursor: pointer;
    }

    .sales {
        cursor: pointer;
    }

    .sales:hover {
        outline: 1px solid #3699ff;
    }

    .btn-back {
        color: #3699ff;
        outline: 1px solid #3699ff;
    }

    .btn-back:hover {
        color: #3699ff;
        outline: 1px solid #3699ff;
        box-shadow: 0 0 1px 1px rgba(0, 0, 0, 0.5);
    }

    .usfc-bg {
        background-color: #fbfbfb;
    }

    #app {
        font-family: Avenir, Helvetica, Arial, sans-serif;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        text-align: center;
        color: #2c3e50;
    }

    input[type="password"] {
        font-family: Verdana;
        letter-spacing: 0.25em;
        font-weight: 900;
    }

    .toggle-password,
    .toggle-password-confirm {
        cursor: pointer;
    }

    .blue-filter {
        filter: invert(45%) sepia(69%) saturate(2325%) hue-rotate(196deg) brightness(108%) contrast(101%);
    }
    .profile-card {
        background-color: white;
        width: 75px;
        height: 100%;
        border-radius: 20px;
        cursor: pointer;
    }

    /* .profile-card:hover {
        border: solid 1px rgba(54, 153, 255, 0.25);
      } */

    .prevent-select {
        -webkit-user-select: none; /* Safari */
        -ms-user-select: none; /* IE 10 and IE 11 */
        user-select: none; /* Standard syntax */
    }

    .parent_arrow {
        transform: rotate(180deg);
    }

    .user-button {
        z-index: 999;
        position: absolute;
        width: auto;
        right: 20px;
        top: 15vh;
        background-color: white;
        box-shadow: 5px 5px 10px rgba(0, 0, 0, 0.05);
        text-align: center;
        border-radius: 6px;
    }

    ul.user-button li {
        position: relative;
        padding: 10px 0;
        width: 200px;
        display: block;
        font-weight: 400;
        font-size: 14px;
        cursor: pointer;
        vertical-align: middle;
        line-height: 2em;
    }

    ul.user-button {
        position: absolute;
        top: 75px;
        right: 0;
        padding-left: 0;
    }

    li:hover {
        opacity: 1;
        background: rgba(54, 153, 255, 0.1);
        border-radius: 6px;
        filter: invert(42%) sepia(100%) saturate(546%) hue-rotate(178deg) brightness(107%) contrast(101%);
    }

    .sign-out-button {
        filter: invert(51%) sepia(27%) saturate(6806%) hue-rotate(329deg) brightness(111%) contrast(104%);
    }

    .sign-out-button:hover {
        filter: invert(51%) sepia(27%) saturate(6806%) hue-rotate(329deg) brightness(111%) contrast(104%);
    }

    .top-panel {
        height: 10vh;
    }

    a {
        color: inherit;
        text-decoration: none;
    }

    /* INPUT FORM LABEL STYLE */
    .label-float {
        position: relative;
        padding-top: 13px;
    }

    input {
        width: 100%;
        padding: 2px 0;
        border: 1px solid transparent;
    }

    select {
        width: 100%;
        border: 1px solid transparent;
    }

    .btn {
        font-size: small;
        min-width: 90px;

        border-radius: 6px;
        padding: 10px 25px;
    }

    .btn.btn-cancel {
        border-radius: 6px;
        padding: 10px 25px;
    }

    .btn.btn-cancel:hover {
        border: 1px solid rgba(0, 0, 0, 0.2);
    }

    .btn.btn-cancel:active {
        border: 1px solid rgba(0, 0, 0, 1);
    }

    .btn.btn-usfc-primary {
        background-color: #3699ff;
        color: white;
        border-radius: 6px;
        padding: 10px 25px;
    }

    .btn-usfc-primary:hover {
        background-color: #3699ff;
        color: white;
        box-shadow: 5px 5px 15px 10px rgba(0, 0, 0, 0.05);
    }

    .label-float input {
        border: none;
        background-color: whitesmoke;
        border-radius: 5px;
        outline: none;
        min-width: 250px;
        padding: 15px 20px;
        font-size: 16px;
        transition: all 0.1s linear;
        -webkit-transition: all 0.1s linear;
        -moz-transition: all 0.1s linear;
        -webkit-appearance: none;
        top: 10px;
    }

    .label-float select {
        border: none;
        background-color: whitesmoke;
        border-radius: 5px;
        outline: none;
        min-width: 250px;
        padding: 15px 20px;
        font-size: 16px;
        transition: all 0.1s linear;
        -webkit-transition: all 0.1s linear;
        -moz-transition: all 0.1s linear;
        -webkit-appearance: none;
        top: 10px;
    }

    .label-float input:focus {
        border: 2px solid #3699ff;
        background-color: transparent;
    }

    .label-float select:focus {
        border: 2px solid #3699ff;
        background-color: transparent;
    }

    .label-float input::placeholder {
        color: black;
    }

    .label-float select::placeholder {
        color: black;
    }

    .label-float label {
        pointer-events: none;
        position: absolute;
        top: calc(50% - 8px);
        left: 15px;
        transition: all 0.1s linear;
        -webkit-transition: all 0.1s linear;
        -moz-transition: all 0.1s linear;
        background-color: transparent;
        padding: 5px;
        box-sizing: border-box;
        color: rgba(0, 0, 0, 0.5);
    }

    .label-float input:required:invalid + label {
        color: red;
    }
    .label-float input:focus:required:invalid {
        border: 2px solid red;
    }
    .label-float input:required:invalid + label:before {
        content: "*";
    }
    .label-float input:focus + label,
    .label-float input:not(:placeholder-shown) + label {
        font-size: 13px;
        top: 10px;
        color: #3951b2;
        opacity: 1;
        background-color: transparent;
    }

    .label-float select:required:invalid + label {
        color: red;
    }
    .label-float select:focus:required:invalid {
        border: 2px solid red;
    }
    .label-float select:required:invalid + label:before {
        content: "*";
    }

    label.option-selected {
        font-size: 13px;
        transform: translateY(calc(-55%));
        color: #3951b2;
        background-color: transparent;
    }

    /* FA ICONS CUSTOM */
    .fa-check {
        margin: 0 5px;
        position: relative;
        top: -2px;
        font-size: 12px;
        color: white;
        border-radius: 50%;
        padding: 3px;
        background-color: #3699ff;
    }

    /* * {
    border: 1px solid red;
  } */
</style>
