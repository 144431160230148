<template>
  <div class="form mx-sm-4">
    <form>
      <div class="text-start" style="margin: 10% 0 30% 0">
        <router-link to="/signin" style="text-decoration: none; color: #3699ff; font-weight: bold"><i class="bi bi-arrow-left"></i> Back</router-link>
      </div>
      <div class="form-group">
        <h4 style="font-weight: bold">Reset Password</h4>
        <span class="spantexts">Create your new password, and make sure its different from your previous one.</span>
      </div>
      <div class="label-float">
        <input @input="validate()" id="pwd" type="password" placeholder=" " autocomplete="off" />
        <label>New password</label>
        <span style="float: right; position: relative; top: -40px; left: -10px" @click="togglePass()" toggle="#password-field" class="bi bi-eye-slash-fill toggle-password"></span>
      </div>
      <br />
      <div class="label-float" style="top: -20px">
        <input @input="validate()" id="pwdconf" type="password" placeholder=" " autocomplete="off" />
        <label>Re-enter new password</label>
        <span style="float: right; position: relative; top: -40px; left: -10px" @click="togglePassConfirm()" toggle="#password-field" class="bi bi-eye-slash-fill toggle-password-confirm"></span>
      </div>
      <div id="responseMsg" class="spantexts text-dangers pb-1" style="position: relative; top: -15px">
        <span class="spantexts" to="/">{{ errMessage }}</span>
      </div>
      <!-- ADD DISABLED SETELAH APPLY API -->'
      <button @click="resetPass()" :disabled="!valid" class="btn btn-primary w-100" style="position: relative; top: -25px">Reset Password</button>
    </form>
  </div>
</template>

<script>
  import axios from "axios";
  import $ from "jquery";

  export default {
    name: "SigninForm",
    props: {},
    data() {
      return {
        valid: false,
      };
    },
    computed: {
      errMessage() {
        return this.valdiation;
      },
    },
    methods: {
      len(input) {
        if (input.length < 8) {
          return false;
        } else {
          return true;
        }
      },
      matchchars(input) {
        let validation = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]+/g;
        if (!input.match(validation)) {
          return false;
        } else {
          return true;
        }
      },
      validate() {
        let pwda = $("#pwd").val();

        console.log("a : " + this.len(pwda));
        console.log("b : " + this.matchchars(pwda));
        if (this.len(pwda) && this.matchchars(pwda)) {
          this.valid = true;
          $("#responseMsg").html("");
        } else {
          $("#responseMsg").html("Must contain atleast 8 characters, including uppercase, lowercase, and special characters");
        }
      },
      togglePass() {
        $(".toggle-password").toggleClass("bi-eye bi-eye-slash-fill");
        var input = $("#pwd");
        if (input.attr("type") == "password") {
          input.attr("type", "text");
        } else {
          input.attr("type", "password");
        }
      },
      togglePassConfirm() {
        $(".toggle-password-confirm").toggleClass("bi-eye bi-eye-slash-fill");
        var input = $("#pwdconf");
        if (input.attr("type") == "password") {
          input.attr("type", "text");
        } else {
          input.attr("type", "password");
        }
      },
      resetPass() {
        event.preventDefault();
        let token = this.$route.path.split("/");
        token = token[2];
        let body = {
          password: $("#pwdconf").val(),
        };

        if ($("#pwd").val() == $("#pwdconf").val()) {
          this.valid = false;
          axios
            .patch(this.$store.state.app.apiBasepath + "/user/reset-password/", body, { headers: { Authorization: `Bearer ${token}` } })
            .then(() => {
              this.$router.push("/passwordchanged/" + token);
            })
            .catch(() => {
              $("#pwd").attr("required", true);
              $("#pwdconf").attr("required", true);
              $("#responseMsg").html("New password must be different from the previous used passwords");
              setTimeout(() => {
                $("#responseMsg").html("");
                $("#pwd").attr("required", false);
                $("#pwdconf").attr("required", false);
                this.valid = true;
              }, 5000);
            });
        } else {
          $("#pwd").attr("required", true);
          $("#pwdconf").attr("required", true);
          $("#responseMsg").html("Make sure both passwords are the same");
          setTimeout(() => {
            $("#responseMsg").html("");
            $("#pwd").attr("required", false);
            $("#pwdconf").attr("required", false);
          }, 5000);
        }
      },
    },
    mounted() {
      let token = this.$route.path.split("/");
      token = token[2];

      axios
        .get(this.$store.state.app.apiBasepath + "/user/token-validation/", { headers: { Authorization: `Bearer ${token}` } })
        .then(() => {})
        .catch(() => {
          this.$router.push("/signin");
        });
    },
  };
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
  .spantexts {
    font-size: 0.9em;
  }

  .text-dangers {
    color: red;
  }

  .form {
    padding: 10%;
    text-align: left;
  }

  .right-text {
    text-align: right;
  }
  h4 {
    margin: 40px 0 0;
  }
  ul {
    list-style-type: none;
    padding: 0;
  }
  li {
    display: inline-block;
    margin: 0 10px;
  }
  a {
    color: #42b983;
  }

  .label-float {
    position: relative;
    padding-top: 13px;
  }

  input {
    width: 100%;
  }

  .label-float input {
    border: 1px solid lightgrey;
    background-color: whitesmoke;
    border-radius: 5px;
    outline: none;
    min-width: 250px;
    padding: 15px 20px;
    font-size: 16px;
    transition: all 0.1s linear;
    -webkit-transition: all 0.1s linear;
    -moz-transition: all 0.1s linear;
    -webkit-appearance: none;
    top: 10px;
  }

  .label-float input:focus {
    border: 2px solid #3951b2;
    background-color: transparent;
  }

  .label-float input::placeholder {
    color: black;
  }

  .label-float label {
    pointer-events: none;
    position: absolute;
    top: calc(50% - 8px);
    left: 15px;
    transition: all 0.1s linear;
    -webkit-transition: all 0.1s linear;
    -moz-transition: all 0.1s linear;
    background-color: whitesmoke;
    padding: 5px;
    box-sizing: border-box;
  }

  /* USE IF LABEL NEEDS TO BE RED */
  /* .label-float input:required:invalid + label{
  color: red;
} */

  .label-float input:required:invalid {
    border: 2px solid red;
  }
  .label-float input:required:invalid + label:before {
    content: "";
  }
  .label-float input:focus + label,
  .label-float input:not(:placeholder-shown) + label {
    font-size: 13px;
    top: 10px;
    color: #3951b2;
    background-color: transparent;
  }
</style>
