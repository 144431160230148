<template>
  <div :class="this.$store.state.app.MainPopup.type == 'success' ? 'shown' : ''" class="popup mx-3 success row text-start px-2">
    <div style="display: inline" class="col"><img src="/popper/success.svg" style="transform: scale(1)" /></div>
    <div style="display: inline" class="col-9 text-start">{{ this.$store.state.app.MainPopup.message }}</div>
  </div>
  <div :class="this.$store.state.app.MainPopup.type == 'err' ? 'shown' : ''" class="popup mx-3 error row text-start px-2">
    <div style="display: inline" class="col"><img src="/popper/error.svg" style="transform: scale(1)" /></div>
    <div style="display: inline" class="col-9 text-start">{{ this.$store.state.app.MainPopup.message }}</div>
  </div>
</template>

<script>
  /* eslint-disable */

  export default {
    name: "MainPopup",
    props: {},
    components: {},
    data() {
      return {};
    },
    computed: {},
    methods: {},
    mounted() {},
  };
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
  .popup {
    z-index: 999;
    font-size: 12px;
    display: block;
    width: auto;
    max-width: 500px;
    position: fixed;
    bottom: -10vh;
    left: 20vw;
    line-height: 65px;
    border-radius: 6px;
    max-height: 100px;
    transition: all 0.5s ease-in-out;
  }

  .popup.shown {
    bottom: 5vh;
  }

  .success {
    background-color: #d8ecfc;
  }

  .error {
    background-color: #f1d0d0;
  }
</style>
