<template>
    <div v-if="getNotificationItems.length > 0" :class="[open ? 'shown' : '']" class="notification-items">
        <div
            v-for="item in getNotificationItems"
            @click="
                openNotification(item);
                readNotification(item.id);
                item.is_read = true;
            "
            :key="item.id"
            class="item">
            <div class="title">
                <div class="dot" v-if="!item.is_read"></div>
                {{ item.title }}
            </div>
            <div class="desc">
                {{ renderDescLimited(item.content, 130) }}
            </div>
        </div>
    </div>
    <div v-else :class="[open ? 'shown' : '']" class="notification-items">
        <div class="item">
            <div class="empty" style="align-content: center">You have no unread notification!</div>
        </div>
    </div>
</template>
<script>
    import axios from "axios";
    export default {
        props: {
            open: {
                type: Boolean,
                required: true,
                default: false,
            },
        },
        data() {
            return {
                message: "Hello, world!",
                count: 0,
            };
        },
        computed: {
            reversedMessage() {
                return this.message.split("").reverse().join("");
            },
            getNotificationItems() {
                return this.$store.state.app.userNotification;
            },
        },
        methods: {
            openNotification(item) {
                if (item.type === "booking_receipt") window.open(`/bookings/details/${item.bookingReceiptId}`);
            },
            incrementCount() {
                this.count++;
            },
            async readNotification(id) {
                let token = JSON.parse(localStorage.getItem("token"));

                await axios.patch(this.$store.state.app.apiBasepath + "/notification/read/" + id, null, { headers: { Authorization: `Bearer ${token}` } });

                this.$store.state.app.userNotification.map((item) => {
                    if (item.id === id) {
                        return { ...item, is_read: true };
                    }
                    return item;
                });
            },
            renderDescLimited(text, limit) {
                return text.length > limit ? `${text.slice(0, limit)}...` : text;
            },
        },
        mounted() {},
    };
</script>
<style lang="scss" scoped>
    @import "./notificationItems.scss";
</style>
