<template>
    <div class="notification-wrapper">
        <div @click="getNotifications()" :class="[open ? 'open' : '', unread ? 'has-unread' : '']" class="button">
            <img src="/icons/all/twotone/notification.svg" style="position: relative; padding: 2px" class="blue-filter" />
        </div>
    </div>
</template>
<script>
    import axios from "axios";
    export default {
        props: {
            open: {
                type: Boolean,
                required: true,
                default: false,
            },
            unread: {
                type: Boolean,
                required: true,
                default: true,
            },
        },
        data() {
            return {
                message: "Hello, world!",
                count: 0,
            };
        },
        computed: {
            reversedMessage() {
                return this.message.split("").reverse().join("");
            },
        },
        methods: {
            incrementCount() {
                this.count++;
            },
            renderDescLimited(text, limit) {
                return `${text.slice(0, limit)}...`;
            },
            async getNotifications() {
                let token = JSON.parse(localStorage.getItem("token"));

                const data = await axios.get(this.$store.state.app.apiBasepath + "/notification/all?is_read=false", { headers: { Authorization: `Bearer ${token}` } }).then((res) => {
                    return res.data;
                });

                if (data) {
                    this.$store.state.app.userNotification = data.rows;
                    console.log(this.$store.state.app.userNotification);
                }
            },
        },
        mounted() {},
    };
</script>
<style lang="scss" scoped>
    @import "./notificationButton.scss";
</style>
