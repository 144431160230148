<template>
    <div class="col bg-white text-start prevent-select" style="border-radius: 6px">
        <form-wrapper v-if="!this.$route.path.split('/')[3]">
            <div class="row">
                <TabSelection v-if="this.$store.state.app.managerForm.currentComp == 'users'" />
                <TabRefSelection v-if="this.$store.state.app.managerForm.currentComp == 'references'" />
            </div>
            <div class="row">
                <div v-if="this.$route.path.split('/').pop() == 'users'">
                    <ManageAdmin v-if="this.$store.state.app.managerForm.currentTab == 'admin'" />
                    <ManageClient v-if="this.$store.state.app.managerForm.currentTab == 'clients'" />
                    <ManageSales v-if="this.$store.state.app.managerForm.currentTab == 'sales'" />
                    <ManageTransporter v-if="this.$store.state.app.managerForm.currentTab == 'transporter'" />
                </div>
                <div v-if="this.$route.path.split('/').pop() == 'references'">
                    <ManageOrigin v-if="this.$store.state.app.managerForm.currentRefTab == 'origin'" />
                    <ManageDestination v-if="this.$store.state.app.managerForm.currentRefTab == 'destination'" />
                    <ManageShipping v-if="this.$store.state.app.managerForm.currentRefTab == 'shipping'" />
                    <ManageProvider v-if="this.$store.state.app.managerForm.currentRefTab == 'provider'" />
                </div>
            </div>
        </form-wrapper>
        <router-view v-if="this.$route.path.split('/')[3] && this.$route.path.split('/')[2] == 'users'" />
        <ReportComponent v-if="this.$route.path.split('/')[1] == 'reports' && !noBG" />
    </div>
    <ReportComponent v-if="this.$route.path.split('/')[1] == 'reports' && noBG" />
</template>

<script>
    import $ from "jquery";
    import TabSelection from "./usersComps/tabSelection.vue";
    import ManageAdmin from "./usersComps/adminComps/adminContents.vue";
    import ManageClient from "./usersComps/clientsComps/clientsContents.vue";
    import ManageSales from "./usersComps/salesComps/salesContents.vue";
    import ManageTransporter from "./usersComps/transporterComps/transporterContents.vue";
    import ManageUserDetail from "./usersComps/adminComps/userDetail.vue";
    import axios from "axios";
    import TabRefSelection from "./referencesComps/tabRefSelection.vue";
    import ManageOrigin from "./referencesComps/originComps/originContents.vue";
    import ManageDestination from "./referencesComps/destinationComps/destinationContents.vue";
    import ManageShipping from "./referencesComps/shippingComps/shippingContents.vue";
    import ManageProvider from "./referencesComps/providerComps/providerContents.vue";
    import ReportComponent from "../reportComponents/reportComponent.vue";

    /* eslint-disable */

    export default {
        name: "ManagerComponent",
        props: {},
        components: {
            TabSelection,
            ManageAdmin,
            ManageClient,
            ManageSales,
            ManageTransporter,
            ManageUserDetail,
            TabRefSelection,
            ManageOrigin,
            ManageDestination,
            ManageShipping,
            ManageProvider,
            ReportComponent,
        },
        data() {
            return {
                loadForm: "signin",
                menu: "Manage",
                submenu: "Users",
                userAction: false,
            };
        },
        computed: {
            noBG() {
                return ["Sales Performance", "Transporter Performance"].includes(this.$route.name);
            },
            getLoadForm() {
                return this.loadForm;
            },
            currentMenu() {
                return this.menu;
            },
            currentSubmenu() {
                return this.submenu;
            },
            isUserAction() {
                return this.userAction;
            },
        },
        methods: {
            forgotPass(val) {
                this.loadForm = "wait";
                setTimeout(() => {
                    this.loadForm = val;
                }, 250);
            },
            getMenu(val) {
                this.menu = val;
            },
            getSub(val) {
                this.submenu = val;
            },
            toggleUserAction() {
                this.userAction = !this.userAction;
            },
            setFocusUser(ele) {
                $(ele.target).focus();
                console.log(ele.target);
            },
            keepLogin(getToken) {
                axios
                    .post(this.$store.state.app.apiBasepath + "/user/keep-login", {}, { headers: { Authorization: `Bearer ${JSON.parse(getToken)}` } })
                    .then((response) => {
                        localStorage.setItem("token", JSON.stringify(response.data.token));

                        let userData = response.data.keepLogin;
                        this.$store.state.userData = userData;
                    })
                    .catch(() => {
                        this.$router.push("/signin");
                    });
            },
            accessFilter(x) {
                let pass = false;

                console.log(x, this.$route.name);

                switch (x) {
                    case 5:
                        pass = true;
                        break;
                    case 4:
                        pass = false;
                        break;
                    case 3:
                        switch (this.$route.name) {
                            case "Receipt":
                                pass = true;
                                break;

                            case "SLA":
                                pass = false;
                                break;
                            default:
                                break;
                        }
                        break;
                    case 2:
                        switch (this.$route.name) {
                            case "Receipt":
                                pass = true;
                                break;

                            case "SLA":
                                pass = false;
                                break;
                            default:
                                break;
                        }
                        break;
                    case 1:
                        pass = false;
                        break;

                    default:
                        break;
                }

                return pass;
            },
        },
        mounted() {
            let token = localStorage.getItem("token");
            if (token == null || undefined) {
                this.$router.push("/signin");
            } else {
                this.keepLogin(token);
            }
            if (this.$route.name == "Users") {
                return 1;
            }
            if (!this.accessFilter(this.$store.state.userData.access_level)) {
                this.$router.push("/dashboard");
            }
        },
    };
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
    .usfc-bg {
        background-color: #fbfbfb;
    }

    .blue-filter {
        filter: invert(45%) sepia(69%) saturate(2325%) hue-rotate(196deg) brightness(108%) contrast(101%);
    }
    .profile-card {
        background-color: white;
        width: 7%;
        height: 100%;
        border-radius: 20px;
        cursor: pointer;
    }

    /* .profile-card:hover {
    border: solid 1px rgba(54, 153, 255, 0.25);
  } */

    .prevent-select {
        -webkit-user-select: none; /* Safari */
        -ms-user-select: none; /* IE 10 and IE 11 */
        user-select: none; /* Standard syntax */
    }

    .parent_arrow {
        transform: rotate(180deg);
    }

    .user-button {
        position: absolute;
        right: 3vw;
        top: 15vh;
        width: 12vw;
        background-color: white;
        box-shadow: 5px 5px 10px rgba(0, 0, 0, 0.05);
        text-align: left;
        border-radius: 6px;
    }

    ul.user-button li {
        display: block;
        margin: 2% 0;
        padding: 5%;
        font-size: 2vh;
        font-weight: 400;
        cursor: pointer;
        vertical-align: middle;
        line-height: 2em;
    }

    li:hover {
        opacity: 1;
        background: rgba(54, 153, 255, 0.1);
        border-radius: 6px;
        filter: invert(42%) sepia(100%) saturate(546%) hue-rotate(178deg) brightness(107%) contrast(101%);
    }

    .sign-out-button {
        filter: invert(51%) sepia(27%) saturate(6806%) hue-rotate(329deg) brightness(111%) contrast(104%);
    }

    .sign-out-button:hover {
        filter: invert(51%) sepia(27%) saturate(6806%) hue-rotate(329deg) brightness(111%) contrast(104%);
    }

    .top-panel {
        height: 10vh;
    }

    .frame-content {
        min-height: 80vh;
    }
    .illustration {
        align-items: center;
        flex-wrap: wrap;
    }

    .vh-100 {
        height: 100vh;
    }
    h3 {
        margin: 40px 0 0;
    }
    ul {
        list-style-type: none;
        padding: 0;
    }
    li {
        display: inline-block;
        margin: 0 10px;
    }
    a {
        color: #42b983;
    }
</style>
