<template>
    <div class="px-3 py-0 my-0 prevent-select" style="min-height: 400px">
        <FormLoading v-if="this.$store.state.app.managerForm.formLoading" />
        <EmptyState v-if="this.$store.state.app.managerForm.noResult" />
        <table v-if="!this.$store.state.app.managerForm.formLoading && !this.$store.state.app.managerForm.noResult" class="table">
            <thead>
                <tr>
                    <th class="col-1">
                        <span> </span>
                    </th>

                    <th class="col-3">
                        <span> Receipt Number </span>
                    </th>

                    <th v-if="isAdmin || isManager" class="col-3">
                        <span> Client </span>
                    </th>

                    <th class="col-2">
                        <span @click="sort()" style="cursor: pointer">
                            Date Created
                            <iconGroup class="blue-filter">
                                <img v-if="getSortType == 'DESC'" src="/icons/all/twotone/arrow-up.svg" class="scaled" />
                                <img v-else src="/icons/all/twotone/arrow-down-1.svg" class="scaled" />
                            </iconGroup>
                        </span>
                    </th>

                    <th class="col-2 text-center">
                        <span> Status </span>
                    </th>
                    <th class="col-2">
                        <span> SLA </span>
                    </th>
                </tr>
                <!-- </thead>
                <tbody v-if="this.$store.state.app.managerForm.search == ''">
                    <tr v-for="admin in filteredOrigin[page - 1]" :key="admin?.id" :class="this.$store.state.app.managerForm.refSelects.includes(admin?.id) ? 'idSelected' : 'point'">
                    <th @click="removeSelected(admin?.id)" v-if="this.$store.state.app.managerForm.refSelects.includes(admin?.id)" scope="row" style="cursor: pointer">
                        <img src="/icons/custom/picker.svg" />
                    </th>
                    <th @click="this.$store.state.app.managerForm.refSelects.push(admin?.id)" v-else scope="row" style="cursor: pointer">
                        <img src="/icons/custom/picker-deselect.svg" />
                    </th>

                    <td @click="openDetails(admin?.id, 'originView')" v-if="this.$store.state.app.managerForm.refSelects.length < 1">{{ admin?.city }}</td>
                    <td @click="removeSelected(admin?.id)" style="cursor: pointer" v-else>{{ admin?.city }}</td>

                    <td @click="openDetails(admin?.id, 'originView')" v-if="this.$store.state.app.managerForm.refSelects.length < 1">{{ admin?.province }}</td>
                    <td @click="removeSelected(admin?.id)" style="cursor: pointer" v-else>{{ admin?.province }}</td>
                    </tr>
                </tbody> -->
            </thead>

            <tbody>
                <tr v-for="admin in filteredOrigin[page - 1]" :key="admin?.id" :class="this.$store.state.app.managerForm.refSelects.includes(admin?.id) ? 'idSelected' : 'point'">
                    <td>
                        <img v-if="getSortType == 'ASC'" :src="transportationIcon(admin.booking_receipt?.client_rate?.shipping_type?.transportation_mode)" class="blue-filter" />
                    </td>
                    <td @click="openDetails(admin?.id, this.$store.state.userData.access_level)" v-if="this.$store.state.app.managerForm.refSelects.length < 1">{{ admin.booking_receipt.booking_number }}</td>

                    <td @click="openDetails(admin?.id, this.$store.state.userData.access_level)" v-if="this.$store.state.app.managerForm.refSelects.length < 1 && (isAdmin || isManager)">{{ admin.booking_receipt?.user.company }}</td>
                    <td @click="openDetails(admin?.id, this.$store.state.userData.access_level)" v-if="this.$store.state.app.managerForm.refSelects.length < 1">{{ new Date(admin.createdAt).toLocaleDateString("id-ID") }}</td>
                    <td @click="openDetails(admin?.id, this.$store.state.userData.access_level)" v-if="this.$store.state.app.managerForm.refSelects.length < 1">
                        <div :class="`update-status receipt__${admin.delivery_status}`"></div>
                    </td>
                    <td @click="openDetails(admin?.id, this.$store.state.userData.access_level)" v-if="this.$store.state.app.managerForm.refSelects.length < 1">
                        <div :class="`sla grade__${defineSLA(admin.sla_grade)}`"></div>
                    </td>
                </tr>
            </tbody>
        </table>
    </div>
    <nav aria-label="Page navigation example">
        <ul class="pagination justify-content-end">
            <paginate v-model="page" style="list-style-type: none" class="d-flex" :page-count="totalPages" :prev-text="'Prev'" :next-text="'Next'" :container-class="'className'"> </paginate>
        </ul>
    </nav>
</template>

<script>
    /* eslint-disable */

    import axios from "axios";

    import EmptyState from "@/reusables/EmptyState.vue";
    import FormLoading from "@/reusables/formLoading.vue";
    import Paginate from "vuejs-paginate-next";

    export default {
        name: "ManagerComponent",
        props: {},
        components: {
            Paginate,
            EmptyState,
            FormLoading,
        },
        data() {
            return {
                sortType: "DESC",
                page: 1,
                refSelects: [],
                admins: [],
                allOrigin: [],
                loadForm: "signin",
                menu: "Manage",
                submenu: "Users",
                userAction: false,
            };
        },
        computed: {
            filteredOrigin() {
                return this.$store.state.app.managerForm.filteredReceipts;
            },

            getLoadForm() {
                return this.loadForm;
            },
            totalPages() {
                return Math.ceil(this.$store.state.app.managerForm.allReceipts.length / 6);
            },
            currentMenu() {
                return this.menu;
            },
            currentSubmenu() {
                return this.submenu;
            },
            isUserAction() {
                return this.userAction;
            },
            getSortType() {
                return this.sortType;
            },
            isClient() {
                if (this.$store.state.userData.access_level === 2) return true;
                return false;
            },
            isSales() {
                if (this.$store.state.userData.access_level === 3) return true;
                return false;
            },
            isAdmin() {
                if (this.$store.state.userData.access_level === 4) return true;
                return false;
            },
            isManager() {
                if (this.$store.state.userData.access_level === 5) return true;
                return false;
            },
        },
        methods: {
            defineSLA(grade) {
                if (grade <= 100) return "a";

                if (grade > 100 && grade <= 125) return "b";

                if (grade > 125) return "c";
            },
            transportationIcon(mode) {
                const iconMap = {
                    Land: "/icons/all/twotone/truck.svg",
                    Air: "/icons/all/twotone/airplane.svg",
                    Water: "/icons/all/twotone/ship.svg",
                };
                return iconMap[mode];
            },
            openDetails(id, access_level) {
                this.$store.state.app.managerForm.userDetail = access_level;
                this.$router.push("/receipts/details/" + id);
            },

            sort() {
                if (this.getSortType == "DESC") {
                    this.ascSort();
                } else {
                    this.descSort();
                }

                this.$store.state.app.managerForm.refSelects = [];
            },
            ascSort() {
                function compare(a, b) {
                    if (a.createdAt < b.createdAt) return 1;
                    if (a.createdAt > b.createdAt) return -1;
                }

                this.page = 1;
                this.sortType = "ASC";
                this.$store.state.app.managerForm.allReceipts.sort(compare);
                this.paginate();
            },
            descSort() {
                function compare(a, b) {
                    if (a.createdAt.toLowerCase() < b.createdAt.toLowerCase()) return -1;
                    if (a.createdAt.toLowerCase() > b.createdAt.toLowerCase()) return 1;
                }

                this.page = 1;
                this.sortType = "DESC";
                this.$store.state.app.managerForm.allReceipts.sort(compare);
                this.paginate();
            },
            getAllAdmins() {
                let token = JSON.parse(localStorage.getItem("token"));
                this.$store.state.app.managerForm.noResult = false;
                this.$store.state.app.managerForm.formLoading = true;
                const access = this.$store.state.userData.access_level;
                let path;
                if (access === 2) path = "by-client";
                else if (access === 3) path = "by-sales";
                else if (access === 4 || access === 5) path = "all";
                else path = null;
                console.log(access, path, "aa");

                axios
                    .get(`${this.$store.state.app.apiBasepath}/receipt/${path}?except=archived`, { headers: { Authorization: `Bearer ${token}` } })
                    .then((res) => {
                        if (res.data.count > 0) {
                            this.$store.state.app.managerForm.noResult = false;
                            this.$store.state.app.managerForm.formLoading = false;
                        } else {
                            this.$store.state.app.managerForm.noResult = true;
                            this.$store.state.app.managerForm.formLoading = false;
                        }
                        this.$store.state.app.managerForm.allReceipts = res.data.rows;
                        this.ascSort();
                    })
                    .catch((err) => {
                        console.log(err);
                    });
            },
            paginate() {
                // human-readable page numbers usually start with 1, so we reduce 1 in the first argument
                for (let i = 0; i < this.totalPages; i++) {
                    this.$store.state.app.managerForm.filteredReceipts[i] = JSON.parse(JSON.stringify(this.$store.state.app.managerForm.allReceipts.slice(i * 6, (i + 1) * 6)));
                }
            },
            ascendingSort() {
                this.allReceipts.sort((a, b) => a - b);
            },
            selectAll() {
                Object.values(this.filteredReceipts[this.page - 1]).forEach((val) => {
                    this.$store.state.app.managerForm.refSelects.push(val.id);
                });
            },
            removeSelected(val) {
                const index = this.$store.state.app.managerForm.refSelects.indexOf(val);
                if (index > -1) {
                    // only splice array when item is found
                    this.$store.state.app.managerForm.refSelects.splice(index, 1); // 2nd parameter means remove one item only
                }
            },
        },
        mounted() {
            this.$store.state.app.managerForm.noResult = false;
            this.$store.state.app.managerForm.formLoading = true;
            setTimeout(() => {
                this.getAllAdmins();
            }, 300);
        },
    };
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
    .update-status {
        color: black;
        font-size: 12px;
        width: 150px;
        text-align: center;
        padding: 3px;
        font-weight: 500;
        line-height: 16px;
        border-radius: 20px;
        opacity: 0.75;

        &.receipt__1 {
            background: rgba(0, 0, 0, 0.09);
            &::before {
                content: "Awaiting Pickup";
            }
        }

        &.receipt__2 {
            background: #c5def9;
            color: #083e76;
            &::before {
                content: "On Process";
            }
        }

        &.receipt__3 {
            background: #bcf2bb;
            color: #107e0d;
            &::before {
                content: "Delivered";
            }
        }

        &.receipt__4 {
            background: #bcf2bb;
            color: #107e0d;
            &::before {
                content: "Delivered";
            }
        }

        &.receipt__5 {
            background: rgba(255, 171, 171, 0.7);
            color: #971919;
            &::before {
                content: "Cancelled";
            }
        }

        &.pending {
            opacity: 0.75;
            background-color: #ffd3ab;
            border-radius: 20px;
            color: #c46b1b;
        }

        &.rejected {
            opacity: 0.75;
            background-color: rgba(255, 171, 171, 0.7);
            border-radius: 20px;
            color: #971919;
        }

        &.approved {
            opacity: 0.75;
            background: #bcf2bb;
            border-radius: 20px;
            color: #107e0d;
        }
    }

    .sla {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        text-align: center;
        &.grade__a {
            text-align: center;
            background: #0abc06;
            color: white;
            width: 22px;
            height: 22px;
            border-radius: 50%;
            font-size: 12px;
            opacity: 0.7;
            &::before {
                position: relative;
                left: 7px;
                content: "A";
            }
        }

        &.grade__b {
            text-align: center;
            background: #f6931f;
            color: white;
            width: 22px;
            height: 22px;
            border-radius: 50%;
            font-size: 12px;
            opacity: 0.7;
            &::before {
                position: relative;
                left: 7px;
                content: "B";
            }
        }

        &.grade__c {
            text-align: center;
            background: #f25b5b;
            color: white;
            width: 22px;
            height: 22px;
            border-radius: 50%;
            font-size: 12px;
            opacity: 0.7;
            &::before {
                position: relative;
                left: 7px;
                content: "C";
            }
        }
    }

    th:hover > img {
        filter: drop-shadow(0 0 0 #3699ff);
    }

    td {
        line-height: 6vh;
        vertical-align: middle;
    }

    option {
        line-height: 50px;
    }

    .pagination {
        cursor: pointer;

        &:hover {
            filter: none;
            background-color: transparent;
        }
    }

    .point {
        cursor: pointer;

        &:hover {
            outline: 1px solid #3699ff;
        }
    }

    .idSelected {
        outline: 1px solid #3699ff;
    }

    .page-item:hover {
        filter: none;
    }

    .usfc-bg {
        background-color: #fbfbfb;
    }

    .white-filter {
        filter: invert(100%) brightness(1) contrast(200%);
    }

    .scaled {
        transform: scale(0.8);
    }

    .blue-filter {
        filter: invert(45%) sepia(69%) saturate(2325%) hue-rotate(196deg) brightness(108%) contrast(101%);
    }

    .profile-card {
        background-color: white;
        width: 7%;
        height: 100%;
        border-radius: 20px;
        cursor: pointer;
    }

    li.page-item {
        color: black;
    }

    th > img {
        cursor: pointer;
    }

    .pagination > li {
        outline: none;
        border: none;
        filter: none;
    }

    .page-item.active .page-link {
        color: black;
        font-weight: bold;
    }

    li {
        text-align: center;
        width: 35px;
        margin: 0 5px;
        transition: all 0.2s ease;

        &:hover {
            filter: none;
            translate: 0 -10px;
            cursor: pointer;
        }
    }

    .page-item .page-link {
        color: darkgrey;
    }

    .page-item.disabled .page-link {
        opacity: 0.5;
    }

    .pagination > li:first-child > a,
    .pagination > li:first-child > span {
        background-color: #3699ff;
        color: white;
    }

    .pagination > li:last-child > a,
    .pagination > li:last-child > span {
        background-color: #3699ff;
    }

    .page-link {
        border-radius: 10px;
    }

    tbody tr {
        line-height: 290%;
    }

    .prevent-select {
        -webkit-user-select: none;
        -ms-user-select: none;
        user-select: none;
    }

    .parent_arrow {
        transform: rotate(180deg);
    }

    .user-button {
        position: absolute;
        right: 3vw;
        top: 15vh;
        width: 12vw;
        background-color: white;
        box-shadow: 5px 5px 10px rgba(0, 0, 0, 0.05);
        text-align: left;
        border-radius: 6px;
    }

    .sign-out-button {
        filter: invert(51%) sepia(27%) saturate(6806%) hue-rotate(329deg) brightness(111%) contrast(104%);

        &:hover {
            filter: invert(51%) sepia(27%) saturate(6806%) hue-rotate(329deg) brightness(111%) contrast(104%);
        }
    }

    .top-panel {
        height: 10vh;
    }

    .frame-content {
        min-height: 80vh;
    }

    .illustration {
        align-items: center;
        flex-wrap: wrap;
    }

    .vh-100 {
        height: 100vh;
    }

    h3 {
        margin: 40px 0 0;
    }
</style>
