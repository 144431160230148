<template>
    <div class="preloader d-flex">
        <lottie-animation class="m-auto" :loop="true" ref="anim" :animationData="require('@/assets/preloader.json')" style="transform: scale(0.5); border-radius: 50%" />
    </div>
</template>

<script>
    /* eslint-disable */

    export default {
        name: "ManagerComponent",
        props: {},
        components: {},
        data() {
            return {};
        },
        computed: {},
        methods: {},
    };
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
    .preloader {
        z-index: 99;
        position: relative;
        background-color: white;
        border-radius: 50%;
        height: 400px;
        width: 100%;
    }
</style>
