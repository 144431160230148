<template>
  <div class="text-center" style="transform: scale(0.5)">
    <img v-if="this.$store.state.app.managerForm.noResult_type == 'rate'" src="@/assets/empty_state_rate.svg" />
    <img v-else-if="this.$store.state.app.managerForm.noResult_type == 'pie'" src="@/assets/empty_state_pie.svg" />
    <img v-else-if="this.$store.state.app.managerForm.noResult_type == 'table'" src="@/assets/empty_state_table.svg" />
    <img v-else-if="this.$store.state.app.managerForm.noResult_type == 'data'" src="@/assets/empty_state_data.svg" />
    <img v-else src="@/assets/empty_state.svg" />
    <h2 style="font-weight: 600">No Result Found</h2>
  </div>
</template>

<script>
  /* eslint-disable */

  export default {
    name: "ManagerComponent",
    props: {},
    components: {},
    data() {
      return {};
    },
    computed: {},
    methods: {},
  };
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped></style>
