<template>
    <div class="alert-container">
        <div v-if="alert.type" class="message">
            <div :class="`icon icon__${alert?.type.toLowerCase()}`">
                <span v-if="alert.type && alert.type.toLowerCase() === 'pending'">...</span>
                <span v-else-if="alert.type && alert.type.toLowerCase() === 'success'">&check;</span>
                <span v-else-if="alert.type && alert.type.toLowerCase() === 'err'">X</span>
                <span v-else>N/A</span>
            </div>
            <div>
                <div class="title">
                    {{ alert.type || "ERRTYPE" }}
                </div>
                <div class="content">
                    {{ alert.message || "ERRMSG" }}
                </div>
            </div>
        </div>

        <div v-if="alert.type" :class="` ${alert?.type?.toLowerCase() == 'pending' ? 'progress-bar' : 'progress-bar__finish'}`">
            <div class="progress-bar-value"></div>
        </div>
    </div>
</template>
<script>
    export default {
        computed: {
            alert() {
                return this.$store.state.alert;
            },
        },
    };
</script>
<style lang="scss" scoped>
    .alert-container {
        z-index: 9999;
        background-color: white;
        min-width: 400px;
        min-height: 75px;

        position: fixed;
        bottom: 0;
        left: 0;

        padding: 22px;
        margin: 22px;
        border-radius: 12px;

        box-shadow: 0 0 15px 2px rgba(0, 0, 0, 0.2);

        display: flex;
        flex-direction: column;
        text-align: start;
        gap: 12px;

        &.hidden {
            pointer-events: none;
        }

        .message {
            display: flex;
            align-items: center;
            // max-width: 35vw;

            .icon {
                margin: 0 12px;

                background-color: rgba(54, 153, 255, 1);
                width: 32px;
                height: 32px;
                display: flex;
                align-items: center;
                justify-content: center;
                color: white;

                border-radius: 50%;

                &__err {
                    background-color: rgb(255, 54, 54);
                }
            }
            .title {
                font-size: 12px;
            }
        }
    }

    .progress-bar {
        height: 12px;
        border-radius: 6px;
        background-color: rgba(54, 153, 255, 0.2);
        width: 100%;
        overflow: hidden;

        &__finish {
            display: none;
            background-color: rgba(54, 153, 255, 1);
        }
    }

    .progress-bar-value {
        width: 100%;
        height: 100%;
        background-color: rgba(54, 153, 255, 1);
        animation: indeterminateAnimation 1s infinite linear;
        transform-origin: 0% 50%;
    }

    @keyframes indeterminateAnimation {
        0% {
            transform: translateX(0) scaleX(0);
        }
        40% {
            transform: translateX(0) scaleX(0.4);
        }
        100% {
            transform: translateX(100%) scaleX(0.5);
        }
    }
</style>
