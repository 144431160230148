<template>
  <Transition name="fade" mode="out-in">
    <div class="form mx-sm-4">
      <form>
        <div class="text-center" style="margin: 55% 0 5% 0">
          <img src="/checkmark.svg" style="width: 35%" />
        </div>
        <div class="form-group text-center">
          <h4 class="mb-3" style="font-weight: bold">Password Successfully Changed!</h4>
          <span class="spantexts">Now you can use your new password to sign in to your account</span>
        </div>
        <div class="form-group text-center mt-4" style="font-size: 14px;">
          <router-link to="/signin" style="text-decoration: none; color: #3699ff; font-weight: bold"> Press here if you're not redirected automatically</router-link>
        </div>
      </form>
    </div>
  </Transition>
</template>

<script>
  import { nextTick } from "vue";

  export default {
    name: "PassChanged",
    props: {
      msg: String,
    },
    methods: {
      async forgotPass(val) {
        await nextTick();
        this.$parent.forgotPass(val);
      },
  },
  mounted() {
      setTimeout(() => {
        this.$router.push('/signin')
      }, 5000);  
    },
  };
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
  .spantexts {
    font-size: 0.9em;
  }

  .text-dangers {
    color: red;
  }

  .form {
    padding: 10%;
    text-align: left;
  }

  .right-text {
    text-align: right;
  }
  h4 {
    margin: 40px 0 0;
  }
  ul {
    list-style-type: none;
    padding: 0;
  }
  li {
    display: inline-block;
    margin: 0 10px;
  }
  a {
    color: #42b983;
  }

  .label-float {
    position: relative;
    padding-top: 13px;
  }

  input {
    width: 100%;
  }

  .label-float input {
    border: 1px solid lightgrey;
    background-color: whitesmoke;
    border-radius: 5px;
    outline: none;
    min-width: 250px;
    padding: 15px 20px;
    font-size: 16px;
    transition: all 0.1s linear;
    -webkit-transition: all 0.1s linear;
    -moz-transition: all 0.1s linear;
    -webkit-appearance: none;
    top: 10px;
  }

  .label-float input:focus {
    border: 2px solid #3951b2;
    background-color: transparent;
  }

  .label-float input::placeholder {
    color: black;
  }

  .label-float label {
    pointer-events: none;
    position: absolute;
    top: calc(50% - 8px);
    left: 15px;
    transition: all 0.1s linear;
    -webkit-transition: all 0.1s linear;
    -moz-transition: all 0.1s linear;
    background-color: whitesmoke;
    padding: 5px;
    box-sizing: border-box;
  }

  /* USE IF LABEL NEEDS TO BE RED */
  /* .label-float input:required:invalid + label{
  color: red;
} */

  .label-float input:required:invalid {
    border: 2px solid red;
  }
  .label-float input:required:invalid + label:before {
    content: "";
  }
  .label-float input:focus + label,
  .label-float input:not(:placeholder-shown) + label {
    font-size: 13px;
    top: 10px;
    color: #3951b2;
    background-color: transparent;
  }
</style>
