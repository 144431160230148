<template>
    <div style="min-height: 90vh">
        <div id="modalTitle" class="row">
            <div class="col p-2">
                <h5>
                    <span class="p-2" style="font-weight: 700; float: left">Book New Shipment </span>
                    <span @click="this.$store.state.app.openModal = ''" id="closeModal"><img class="d-flex justify-content-center" src="/icons/all/outline/add.svg" /></span>
                </h5>
            </div>
        </div>
        <div id="stages" class="row d-flex flex-nowrap">
            <template v-for="stage in stages" v-bind:key="stage.name">
                <span class="tracker" style="opacity: 0; width: 0; transition: all 0.4s ease" :style="stage.status == 2 ? 'width: 32px; opacity: 1;' : ''">
                    <Transition name="fade" v-if="stage.status == 2"> <i class="fa fa-check" aria-hidden="true"></i> </Transition>
                </span>
                <div style="transition: all 0.4s ease" :class="stage.status == 1 ? 'active' : ''" class="col-auto">{{ stage.name }}</div>
            </template>
            <div class="col-5">
                <span class="tracker"> </span>
            </div>
        </div>
        <div class="row">
            <Transition>
                <div v-if="form.warning" class="py-1" style="font-size: 10px; font-weight: 200; background-color: rgba(242, 91, 91, 0.25); color: #f25b5b; position: absolute; z-index: 99; top: 72px">{{ form.warning }}</div>
            </Transition>
        </div>
        <form-wrapper v-if="stageIndex == 0">
            <div id="forms" class="row text-start d-flex flex-nowrap profile">
                <form>
                    <div class="label-float">
                        <label :class="recipientDetails.pic_name == '' ? '' : 'option-selected'">
                            <span v-if="recipientDetails.pic_name != ''">Recipient</span>
                            <span v-else><span style="font-size: 10px; position: relative; top: -2px"></span> Select Recipient</span>
                        </label>
                        <label v-if="recipientDetails == ''" :class="recipientDetails == '' ? '' : 'option-selected'" style="display: block; width: 90%; text-align: right; opacity: 0.4"><img style="transform: scale(0.9)" src="/icons/all/outline/arrow-down-1.svg" /></label>
                        <select @change="this.setData()" id="stage1" v-model="recipientDetails" placeholder=" ">
                            <!-- <option v-if="recipientDetails != ''" value="">
                <span v-if="recipientDetails != ''">Select Origin</span>
              </option> -->
                            <option :value="data" v-show="data.company != this.$store.state.app.recipientsForm.allRecipients[index - 1]?.company" v-for="(data, index) in this.$store.state.app.recipientsForm.allRecipients" :key="data.id">{{ data.company }}</option>
                        </select>
                    </div>
                </form>
            </div>
            <div id="details" class="text-start m-3" v-if="showData.recipientDetails">
                <div class="row">
                    <div style="font-size: 12px; opacity: 0.75">PIC Name</div>
                    <div>{{ recipientDetails.pic_name }}</div>
                </div>
                <div class="row">
                    <div style="font-size: 12px; opacity: 0.75">Phone Number</div>
                    <div>{{ recipientDetails.phone }}</div>
                </div>
                <div class="row">
                    <div style="font-size: 12px; opacity: 0.75">Province</div>
                    <div>{{ recipientDetails.province }}</div>
                </div>
                <div class="row">
                    <div style="font-size: 12px; opacity: 0.75">City</div>
                    <div>{{ recipientDetails.city }}</div>
                </div>
                <div class="row">
                    <div style="font-size: 12px; opacity: 0.75">District</div>
                    <div>{{ recipientDetails.district }}</div>
                </div>
                <div class="row">
                    <div style="font-size: 12px; opacity: 0.75">Postal Code</div>
                    <div>{{ recipientDetails.postal_code }}</div>
                </div>
                <div class="row">
                    <div style="font-size: 12px; opacity: 0.75">Street</div>
                    <div>{{ recipientDetails.street }}</div>
                </div>
            </div>
        </form-wrapper>
        <form-wrapper v-if="stageIndex == 1">
            <div id="forms" class="row text-start">
                <form>
                    <div class="row mb-2">
                        <div class="col">
                            <div class="label-float">
                                <label :class="origin == '' ? '' : 'option-selected'">
                                    <span v-if="origin != ''">Origin</span>
                                    <span v-else><span style="font-size: 10px; position: relative; top: -2px">(1)</span> Select Origin</span>
                                </label>
                                <label v-if="origin == ''" :class="origin == '' ? '' : 'option-selected'" style="display: block; width: 90%; text-align: right; opacity: 0.4"><img style="transform: scale(0.9)" src="/icons/all/outline/arrow-down-1.svg" /></label>
                                <select @input="validateDrop(0)" id="stage1" v-model="origin" placeholder=" ">
                                    <option v-if="origin != ''" value="">
                                        <span v-if="origin != ''">Select Origin</span>
                                    </option>
                                    <option v-show="data.origin.city != allData[index - 1]?.origin.city" v-for="(data, index) in allData" :key="data.id">{{ data.origin.city }}</option>
                                </select>
                            </div>
                        </div>
                    </div>
                    <div class="row mb-2">
                        <div class="col">
                            <div class="label-float">
                                <label :class="destination == '' ? '' : 'option-selected'">
                                    <span v-if="destination != ''">Destination</span>
                                    <span v-else><span style="font-size: 10px; position: relative; top: -2px">(2)</span> Select Destination</span>
                                </label>
                                <label v-if="destination == ''" :class="destination == '' ? '' : 'option-selected'" style="display: block; width: 90%; text-align: right; opacity: 0.4"><img style="transform: scale(0.9)" src="/icons/all/outline/arrow-down-1.svg" /></label>
                                <select @input="validateDrop(1)" id="stage2" :disabled="origin == ''" v-model="destination" placeholder=" ">
                                    <option v-if="destination != ''" value="">
                                        <span v-if="destination != ''">Select Destination</span>
                                    </option>
                                    <option v-show="data.destination.city != filteredDestination[index - 1]?.destination.city" v-for="(data, index) in filteredDestination" :key="data.id">{{ data.destination.city }}</option>
                                </select>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col">
                            <div class="label-float">
                                <label :class="shipping_type == '' ? '' : 'option-selected'">
                                    <span v-if="shipping_type != ''">Shipping Type</span>
                                    <span v-else><span style="font-size: 10px; position: relative; top: -2px">(3)</span> Select Shipping Type</span>
                                </label>
                                <label v-if="shipping_type == ''" :class="shipping_type == '' ? '' : 'option-selected'" style="display: block; width: 90%; text-align: right; opacity: 0.4"><img style="transform: scale(0.9)" src="/icons/all/outline/arrow-down-1.svg" /></label>
                                <select @input="validateDrop(2)" id="stage3" :disabled="destination == ''" v-model="shipping_type" placeholder=" ">
                                    <option v-if="shipping_type != ''" value="">
                                        <span v-if="shipping_type != ''">Select Shipping Type</span>
                                    </option>
                                    <option v-show="data.shipping_type.name != filteredShippingType[index - 1]?.shipping_type.name" v-for="(data, index) in filteredShippingType" :key="data.id">{{ data.shipping_type.name }}</option>
                                </select>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col">
                            <div class="label-float">
                                <label :class="service == '' ? '' : 'option-selected'">
                                    <span v-if="service != ''">Service</span>
                                    <span v-else><span style="font-size: 10px; position: relative; top: -2px">(4)</span> Select Service</span>
                                </label>
                                <label v-if="service == ''" :class="provider == '' ? '' : 'option-selected'" style="display: block; width: 90%; text-align: right; opacity: 0.4"><img style="transform: scale(0.9)" src="/icons/all/outline/arrow-down-1.svg" /></label>
                                <select @input="validateDrop(3)" id="stage4" :disabled="shipping_type == ''" v-model="service" placeholder=" ">
                                    <option v-if="service != ''" value="">
                                        <span v-if="service != ''">Select Service</span>
                                    </option>
                                    <option v-show="data.service.name != filteredService[index - 1]?.service.name" v-for="(data, index) in filteredService" :key="data.id">{{ data.service.name }}</option>
                                </select>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col">
                            <div class="label-float">
                                <label :class="provider == '' ? '' : 'option-selected'">
                                    <span v-if="provider != ''">Provider</span>
                                    <span v-else><span style="font-size: 10px; position: relative; top: -2px">(4)</span> Select Provider</span>
                                </label>
                                <label v-if="provider == ''" :class="provider == '' ? '' : 'option-selected'" style="display: block; width: 90%; text-align: right; opacity: 0.4"><img style="transform: scale(0.9)" src="/icons/all/outline/arrow-down-1.svg" /></label>
                                <select @input="validateDrop(3)" id="stage4" :disabled="service == ''" v-model="provider" placeholder=" ">
                                    <option v-if="provider != ''" value="">
                                        <span v-if="provider != ''">Select Provider</span>
                                    </option>
                                    <option v-show="data.provider.name != filteredProvider[index - 1]?.provider.name" v-for="(data, index) in filteredProvider" :key="data.id">{{ data.provider.name }}</option>
                                </select>
                            </div>
                        </div>
                    </div>
                </form>
            </div>
        </form-wrapper>
        <form-wrapper v-if="stageIndex == 2">
            <div id="forms" class="row text-start d-flex flex-nowrap" style="height: 70vh; overflow-y: auto">
                <form>
                    <div class="row mt-3">
                        <div class="col">
                            <div style="font-weight: 600">1. Select Pinpoint</div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col">
                            <div class="label-float">
                                <input @blur="clearSuggestion()" @input="debouncedOnChange" v-model="gmaps.searchQuery" id="address" type="text" placeholder=" " autocomplete="off" />
                                <label>Find Address</label>

                                <div @click="requestGeo()" style="cursor: pointer"><img style="position: absolute; top: 30px; right: 10px" src="@/assets/gps.svg" /></div>
                            </div>
                        </div>
                    </div>
                    <div v-if="gmaps.suggestions.length > 0" id="suggestions" class="row p-1 bg-white">
                        <div class="col p-0">
                            <div @click="selectLocation(suggestion.geometry)" v-for="suggestion in gmaps.suggestions" :key="suggestion.place_id" class="row py-1 px-0 mx-0 suggestion">
                                <div class="col-1 d-flex"><img class="m-auto" src="@/assets/location.svg" /></div>
                                <div class="col">
                                    <div class="row" style="font-weight: 600">
                                        {{ suggestion.name }}
                                    </div>
                                    <div class="row" style="font-size: 12px">
                                        {{ suggestion.formatted_address }}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="row">
                        <div class="col">
                            <!-- KEY AIzaSyCzYRio6GWqdf03CAd9Gs1sjS8b6fuIAyM -->
                            <GoogleMap
                                api-key="AIzaSyCzYRio6GWqdf03CAd9Gs1sjS8b6fuIAyM"
                                @click="markerCallback($event.latLng)"
                                style="width: 100%; height: 200px; filter: saturate(2)"
                                :center="gmaps.center"
                                :controlSize="24"
                                :fullscreen-control="false"
                                :clickableIcons="false"
                                :map-type-control="false"
                                :street-view-control="false"
                                :zoom="gmaps.zoom">
                                <Marker v-if="this.gmaps.marker.lat != null" :options="{ position: gmaps.marker, draggable: true, icon: '/icons/location.svg' }" @dragend="markerCallback($event.latLng)" />
                            </GoogleMap>
                        </div>
                    </div>
                    <div v-if="gmaps.selected.area != null" id="selectedLocation" class="row p-1 m-0 bg-white" style="width: 100%; position: relative; background: #ffffff; box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.15); border-radius: 6px">
                        <div class="col">
                            <div class="row py-1 px-0 mx-0 selected">
                                <div class="col">
                                    <!-- <div class="row" style="font-weight: 600">{{ gmaps.selected.area }}</div> -->
                                    <div class="row" style="font-size: 12px">{{ gmaps.selected.formatted_address }}</div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="row mt-3">
                        <div class="col">
                            <div>Address Details<sup class="text-danger">*</sup></div>
                        </div>
                    </div>

                    <div class="row">
                        <div class="col">
                            <div class="label-float-tall">
                                <textarea v-model="pickup_info.address_details" style="font-size: 12px" rows="4" placeholder="" />
                            </div>
                        </div>
                    </div>

                    <div class="row mt-3">
                        <div class="col">
                            <div style="font-weight: 600">2. PIC Info</div>
                        </div>
                    </div>

                    <div class="row">
                        <div class="col">
                            <div class="label-float">
                                <input v-model="pickup_info.pic_name" id="pic_name" type="text" placeholder=" " autocomplete="off" />
                                <label>PIC Name</label>
                            </div>
                        </div>
                    </div>

                    <div class="row">
                        <div class="col">
                            <div class="label-float">
                                <input v-model="pickup_info.phone_number" id="pic_name" type="text" placeholder=" " autocomplete="off" />
                                <label>Phone Number</label>
                            </div>
                        </div>
                    </div>

                    <div class="row mt-3">
                        <div class="col">
                            <div style="font-size: 14px; font-weight: 600">3. Pickup Time <span style="font-size: 12px; opacity 0.75; font-weight: 200;">(09.00 - 20.00 WIB)</span></div>
                            <div class="mb-3 mt-2" style="font-size: 12px; opacity: 0.75">
                                The minimum reservation time for pickup is three hours on the same day after booking, and the maximum booking time for pickup on the same day is 17.00 WIB. Otherwise, you might choose a pickup time on the next day.
                            </div>
                            <div class="row">
                                <div class="col">
                                    <Datepicker format="dd/MM/yyyy HH:mm" :enable-time-picker="true" :is-24="true" v-model="dateTime" position="center" minutes-increment="10" minutes-grid-increment="10" placeholder="Select pickup Date and Time" :keep-action-row="true" :min-date="new Date()" />
                                </div>

                                <!-- <div class="col-2">
                                    <select style="position: relative; top: 2px; cursor: pointer; height: 25px" class="py-1" v-model="dateTime.zone">
                                        <option>WIB</option>
                                        <option>WITA</option>
                                        <option>WIT</option>
                                    </select>
                                </div> -->
                            </div>
                        </div>
                    </div>
                </form>
            </div>
        </form-wrapper>
        <form-wrapper v-if="stageIndex == 3">
            <div id="forms" class="row text-start d-flex flex-nowrap">
                <div class="col-8 ms-3">Number of Baggings ({{ baggings.length }})</div>
                <div class="col me-3 text-end popup-trigger" @mouseover="showLimits = true" @mouseleave="showLimits = false">Bagging limitations (?)</div>
                <div class="popup-info" :style="showLimits ? 'opacity: 1' : 'opacity: 0'">
                    <div class="row">Selected Shipping Type</div>
                    <div class="row" style="font-weight: 600">{{ this.filteredProvider[0].shipping_type.name }}</div>
                    <div class="row mt-3">Shipping Type Limitations</div>
                    <div class="row">
                        <table style="min-width: 250px">
                            <tr>
                                <td>Max. Length</td>
                                <td>: {{ this.filteredProvider[0].shipping_type.max_long }} cm</td>
                            </tr>
                            <tr>
                                <td>Max. Width</td>
                                <td>: {{ this.filteredProvider[0].shipping_type.max_width }} cm</td>
                            </tr>
                            <tr>
                                <td>Max. Height</td>
                                <td>: {{ this.filteredProvider[0].shipping_type.max_height }} cm</td>
                            </tr>
                            <tr>
                                <td>Max. Total Weight</td>
                                <td>: {{ this.filteredProvider[0].shipping_type.max_weight }} kg</td>
                            </tr>
                            <tr>
                                <td>Max. Cargo Volume</td>
                                <td>: {{ this.getMaxVol }} m<sup>3</sup></td>
                            </tr>
                        </table>
                    </div>
                </div>
            </div>
            <div id="bagging-container" class="bagging-container mt-2">
                <div v-for="index in baggings.length" :key="index" :id="'bagging' + (index - 1)" class="row text-start d-flex flex-nowrap bagging-card">
                    <div class="col-1 mt-4 ms-2" style="width: 25px">{{ index }}.</div>
                    <div class="col">
                        <div class="row">
                            <div class="col">
                                <div class="label-float">
                                    <input v-model="baggings[index - 1].good_details" id="fullname" type="text" placeholder=" " autocomplete="off" />
                                    <label>Goods Detail</label>
                                </div>
                            </div>
                        </div>

                        <div class="row">
                            <div class="col">
                                <div class="label-float">
                                    <input
                                        :class="baggings[index - 1].long > this.filteredProvider[0].shipping_type.max_long ? 'text-danger' : ''"
                                        @input="baggingDimensionalWeight(index - 1)"
                                        v-model="baggings[index - 1].long"
                                        id="fullname"
                                        type="number"
                                        min="0"
                                        placeholder=" "
                                        autocomplete="off" />
                                    <label>Length (cm)</label>
                                </div>
                            </div>
                            <div class="col-1 p-0 text-center" style="width: 10px"><span style="position: relative; top: 45%">X</span></div>
                            <div class="col">
                                <div class="label-float">
                                    <input :class="baggings[index - 1].width > this.filteredProvider[0].shipping_type.max_width ? 'text-danger' : ''" @input="baggingDimensionalWeight(index - 1)" v-model="baggings[index - 1].width" id="fullname" type="number" placeholder=" " autocomplete="off" />
                                    <label>Width (cm)</label>
                                </div>
                            </div>
                            <div class="col-1 p-0 text-center" style="width: 10px"><span style="position: relative; top: 45%">X</span></div>
                            <div class="col">
                                <div class="label-float">
                                    <input
                                        :class="baggings[index - 1].height > this.filteredProvider[0].shipping_type.max_height ? 'text-danger' : ''"
                                        @input="baggingDimensionalWeight(index - 1)"
                                        v-model="baggings[index - 1].height"
                                        id="fullname"
                                        type="number"
                                        min="0"
                                        placeholder=" "
                                        autocomplete="off" />
                                    <label>Height (cm)</label>
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col">
                                <div class="label-float">
                                    <input @input="baggingDimensionalWeight(index - 1)" v-model="baggings[index - 1].actual_weight" id="fullname" type="number" min="0" placeholder=" " autocomplete="off" />
                                    <label>Weight</label>
                                </div>
                            </div>
                            <div class="col">
                                <div class="label-float">
                                    <input disabled v-model="baggings[index - 1].dimensional_weight" id="fullname" type="text" placeholder=" " autocomplete="off" />
                                    <label>Dimensional Weight (kg)</label>
                                </div>
                            </div>
                        </div>

                        <div class="row pb-5 mx-1 mt-3 pt-2" style="border-top: solid 1px rgba(0, 0, 0, 0.1)">
                            <div class="col px-0">
                                <div style="">Accounted Weight (kg)</div>
                                <div>{{ baggings[index - 1].accounted_weight }}</div>
                            </div>
                            <div class="col px-0">
                                <div style="">Item Vol (m<sup>3</sup>)</div>
                                <div>{{ (baggings[index - 1].long * 0.01 * (baggings[index - 1].width * 0.01) * (baggings[index - 1].height * 0.01)).toFixed(1) }}</div>
                            </div>
                        </div>
                    </div>
                    <div @click="deleteBagging(index - 1)" class="col-1 delete"><img src="/icons/all/twotone/trash.svg" class="scaled" /></div>
                </div>
            </div>

            <div id="add_bagging" class="row bagging-add">
                <div class="col-1"></div>
                <div class="col"></div>
                <div class="col-1 button" @click="addBagging()"><img src="/icons/all/outline/add.svg" class="scaled" /></div>
            </div>
        </form-wrapper>
        <form-wrapper v-if="stageIndex == 4">
            <div id="details" class="text-start m-3">
                <div class="row">
                    <div class="col">
                        <div class="row" style="font-weight: 600; font-size: 16px">Weight and Cost</div>
                        <div class="row">
                            <div class="col">
                                <div class="row my-2" style="opacity: 0.5; font-size: 12px">Total Bagging(s)</div>
                                <div class="row" style="font-size: 16px">{{ summary.total_bagging }}</div>
                            </div>
                            <div class="col"></div>
                            <div class="col">
                                <div class="row my-2" style="opacity: 0.5; font-size: 12px">Accounted Weight (kg)</div>
                                <div class="row" style="font-size: 16px">{{ summary.total_acc_wgt }}</div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col">
                        <div class="row" style="font-weight: 600; font-size: 16px">Shipping</div>
                        <div class="row">
                            <div class="col">
                                <div class="row my-2" style="opacity: 0.5; font-size: 12px">Shipping Type</div>
                                <div class="row" style="font-size: 16px">{{ shipping_type }}</div>
                            </div>

                            <div class="col">
                                <div class="row my-2" style="opacity: 0.5; font-size: 12px">Service</div>
                                <div class="row" style="font-size: 16px">{{ service }}</div>
                            </div>
                            <div class="col">
                                <div class="row my-2" style="opacity: 0.5; font-size: 12px">Rate per kg</div>
                                <div class="row" style="font-size: 16px">
                                    <div class="col-1 px-0">Rp</div>
                                    <div class="col text-end">{{ Number(this.filteredProvider[0].rate).toLocaleString("en") }}</div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col px-0" style="display: flex; justify-content: flex-start">
                        <label class="custom-checkbox">
                            <input v-model="additional.insurance" type="checkbox" />
                            <span class="checkmark"></span>
                            <span class="mx-3"> Insurance </span>
                        </label>
                    </div>
                </div>

                <div class="row">
                    <div class="col px-0">
                        <label style="margin: 5px 0; font-weight: 600">Additional Information</label>
                        <div class="label-float-tall">
                            <textarea v-model="additional.additional_info" style="font-size: 12px; resize: none" rows="4" placeholder="" />
                        </div>
                    </div>
                </div>

                <div class="row">
                    <div class="col px-0">
                        <label style="font-weight: 600">Payment Method</label>
                        <div class="label-float">
                            <label :class="additional.payment_method == '' ? '' : 'option-selected'">
                                <span v-if="additional.payment_method != ''"></span>
                                <span v-else> Select Payment Method</span>
                            </label>
                            <label v-if="additional.payment_method == ''" :class="additional.payment_method == '' ? '' : 'option-selected'" style="display: block; width: 90%; text-align: right; opacity: 0.4"><img style="transform: scale(0.9)" src="/icons/all/outline/arrow-down-1.svg" /></label>
                            <select id="payment" v-model="additional.payment_method" placeholder=" ">
                                <option v-if="additional.payment_method != ''" value="">
                                    <span v-if="additional.payment_method != ''">Select Payment Method</span>
                                </option>
                                >
                                <option value="COD">Cash on Delivery (COD)</option>
                                <option value="CBD">Cash Before Delivery (CBD)</option>
                                <option value="DFOD">Delivery Fee on Delivery (DFOD)</option>
                                <option value="TOP">Terms of Payment (TOP)</option>
                            </select>
                        </div>
                    </div>
                </div>
            </div>
        </form-wrapper>

        <div id="navigation" class="row my-2">
            <div v-if="stageIndex == 4" class="row">
                <div class="col">
                    <div class="row" style="font-weight: 600; font-size: 16px">
                        <div class="col px-0 text-start">Estimated Cost</div>
                        <div class="col"></div>
                        <div class="col">
                            <div class="row">
                                <div class="col-1" style="font-weight: 200">Rp</div>
                                <div class="col text-end">{{ Number(summary.est_cost).toLocaleString("en") }}</div>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col">
                            <div class="row my-2" style="font-size: 10px; font-style: italic">*Insurance and additional charge are not included.</div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row px-0">
                <div v-if="stageIndex == 3" class="col">
                    <div class="row px-0">
                        <div class="col text-start" style="font-size: 12px; line-height: 21px">
                            <div>
                                <span :class="this.totalWeight >= this.filteredProvider[0].minimum_weight ? 'text-success' : 'text-danger'">{{ this.totalWeight }}</span
                                >/{{ this.filteredProvider[0].minimum_weight }} of Min. Accounted Weight (kg)
                            </div>
                        </div>
                    </div>
                    <div class="row px-0">
                        <div class="col text-start" style="font-size: 12px; line-height: 21px">
                            <div>
                                <span :class="this.totalWeight <= this.filteredProvider[0].shipping_type.max_weight ? 'text-success' : 'text-danger'">{{ this.totalWeight }}</span
                                >/{{ this.filteredProvider[0].shipping_type.max_weight }} of Max. Accounted Weight (kg)
                            </div>
                        </div>
                    </div>
                    <div class="row px-0">
                        <div class="col text-start" style="font-size: 12px; line-height: 21px">
                            <div>
                                <span :class="this.totalVolume <= this.getMaxVol ? 'text-success' : 'text-danger'">{{ this.totalVolume }}</span
                                >/{{ this.getMaxVol }} of Max. Cargo Volume (m<sup>3</sup>)
                            </div>
                        </div>
                    </div>
                </div>

                <div :class="stageIndex == 3 ? 'col-5' : 'col'" class="px-0" style="display: flex; align-items: end; justify-content: end">
                    <span @click="this.$store.state.app.openModal = ''" v-if="stageIndex == 0" style="float: right" class="mx-2 btn btn-cancel"> Cancel</span>
                    <span @click="prevStage()" v-else style="float: right" class="mx-2 btn btn-back"> Back</span>
                    <button v-if="stages.length == stageIndex + 1" @click="createBooking()" style="float: right" class="mx-2 btn btn-usfc-primary">Book New Shipment</button>
                    <button v-else-if="form.validating" style="float: right; cursor: not-allowed" id="nextStage" class="mx-2 btn btn-usfc-primary wait">...</button>
                    <button v-else @click="validateStage(stageIndex)" style="float: right" id="nextStage" class="mx-2 btn btn-usfc-primary">Next</button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import "jquery/dist/jquery.js";
    import "bootstrap/dist/css/bootstrap.min.css";
    import "bootstrap/dist/js/bootstrap.bundle.min.js";
    import "bootstrap-icons/font/bootstrap-icons.css";
    import $ from "jquery";
    import axios from "axios";
    import Datepicker from "@vuepic/vue-datepicker";
    import { GoogleMap, Marker } from "vue3-google-map";
    import "@vuepic/vue-datepicker/dist/main.css";
    import _ from "lodash";
    // import moment from "moment";

    export default {
        name: "adminCreate",
        components: {
            Datepicker,
            GoogleMap,
            Marker,
        },

        data() {
            return {
                showLimits: false,
                additional: {
                    insurance: false,
                    additional_info: "",
                    payment_method: "",
                },
                pickup_info: {
                    address_details: "",
                    pic_name: "",
                    phone_number: "",
                    pickup_time: "",
                },
                form: {
                    validating: false,
                    warning: null,
                    bodyForm: {},
                },
                markers: [
                    {
                        position: {
                            lat: -1.4484759,
                            lng: 109.944888,
                        },
                    },
                ],
                gmaps: {
                    zoom: 4,
                    searchQuery: "",
                    center: { lat: 0.4484759, lng: 113.944888 },
                    options: {
                        panControl: false,
                        zoomControl: true,
                        mapTypeControl: false,
                        scaleControl: true,
                        streetViewControl: false,
                        overviewMapControl: false,
                        rotateControl: false,
                    },
                    markerOptions: {
                        icon: "/icons/location.svg",
                    },
                    suggestions: [],
                    marker: {
                        lat: null,
                        lng: null,
                    },
                    selected: {
                        area: null,
                        formatted_address: "",
                        coords: {
                            lat: null,
                            lng: null,
                        },
                    },
                },

                hasAction: false,
                dateTime: null,
                showData: {
                    recipientDetails: false,
                },
                recipientDetails: {
                    pic_name: "",
                    phone: "",
                    province: "",
                    city: "",
                    district: "",
                    postal_code: "",
                    street: "",
                },
                baggings: [
                    {
                        good_details: "",
                        long: "",
                        width: "",
                        height: "",
                        dimensional_weight: "",
                        actual_weight: "",
                        accounted_weight: +0,
                        bookingReceiptId: null,
                    },
                ],
                totalWeight: 0,
                totalVolume: 0,
                photo64: "",
                photoFile: "",
                access_level: 4,
                stageIndex: 0,
                pwdconf: "",
                stages: [
                    // status (0: upcoming, 1: active, 2: done)
                    {
                        name: "Recipient Information",
                        status: 1,
                        validated: false,
                    },
                    {
                        name: "Shipping Information",
                        status: 0,
                        validated: false,
                    },
                    {
                        name: "Pickup Information",
                        status: 0,
                        validated: false,
                    },
                    {
                        name: "Bagging Information",
                        status: 0,
                        validated: false,
                    },
                    {
                        name: "Order Summary",
                        status: 0,
                        validated: false,
                    },
                ],
                selects: "",
                loadForm: "signin",
                menu: "Manage",
                submenu: "Users",
                userAction: false,
                publicRouteNames: ["Signin", "ResetPass", "PassChanged"],
                bodyForm: {
                    recipientCompanyId: "",
                },
            };
        },

        watch: {
            dateTime: {
                deep: true,
                handler(newValue) {
                    if (this.validateDateTime(newValue)) this.$alertSys("Selected Pickup Time is Available", "success");
                    else {
                        (this.dateTime = null), this.$alertSys("Selected Pickup Time is Unavailable", "ERR");
                    }
                },
            },
        },

        computed: {
            // Timepicker Constraints
            // Min 3 hrs before pickup
            getMaxVol() {
                return (this.filteredProvider[0].shipping_type.max_long * 0.01 * (this.filteredProvider[0].shipping_type.max_width * 0.01) * (this.filteredProvider[0].shipping_type.max_height * 0.01)).toFixed(1);
            },
            summary() {
                let total_acc_wgt = 0;

                this.baggings.map((item) => {
                    total_acc_wgt += +item.accounted_weight;
                });
                let orders = {};

                if (this.stageIndex == 4) {
                    orders = {
                        total_bagging: this.baggings.length,
                        total_acc_wgt: Number(total_acc_wgt).toFixed(2).toLocaleString("en"),
                        est_cost: total_acc_wgt * this.filteredProvider[0].rate,
                    };
                }
                return orders;
            },
            debouncedOnChange() {
                this.clearSuggestion;
                return _.debounce(this.suggestionResult, 700);
            },
            allowedDates() {
                return [new Date(), new Date(new Date().setDate(new Date().getDate() + 1))];
            },

            startDate() {
                return new Date();
            },
            minTime() {
                const h = new Date();
                const dh = new Date(h.getTime() + 180 * 60000);
                return dh;
            },

            minHr() {
                if (this.minTime.getHours() < 9) {
                    return 9;
                } else {
                    return this.minTime.getHours();
                }
            },
            // 2 way computed data of DATA MODELS
            allData() {
                return this.$store.state.app.rateForm.checkRates.data.allData;
            },
            origin: {
                get() {
                    return this.$store.state.app.rateForm.checkRates.filters.origin;
                },
                set(value) {
                    this.$store.state.app.rateForm.checkRates.filters.origin = value;
                },
            },
            destination: {
                get() {
                    return this.$store.state.app.rateForm.checkRates.filters.destination;
                },
                set(value) {
                    this.$store.state.app.rateForm.checkRates.filters.destination = value;
                },
            },
            shipping_type: {
                get() {
                    return this.$store.state.app.rateForm.checkRates.filters.shipping_type;
                },
                set(value) {
                    this.$store.state.app.rateForm.checkRates.filters.shipping_type = value;
                },
            },
            service: {
                get() {
                    return this.$store.state.app.rateForm.checkRates.filters.service;
                },
                set(value) {
                    this.$store.state.app.rateForm.checkRates.filters.service = value;
                },
            },
            provider: {
                get() {
                    return this.$store.state.app.rateForm.checkRates.filters.provider;
                },
                set(value) {
                    this.$store.state.app.rateForm.checkRates.filters.provider = value;
                },
            },
            // OPTION DATASETS

            filteredDestination() {
                function compare(a, b) {
                    if (a.destination.city.toLowerCase() < b.destination.city.toLowerCase()) return -1;
                    if (a.destination.city.toLowerCase() > b.destination.city.toLowerCase()) return 1;
                }

                if (this.$store.state.app.rateForm.checkRates.filters.origin == "") {
                    return this.$store.state.app.rateForm.checkRates.allData;
                }

                return this.$store.state.app.rateForm.checkRates.data.allData
                    .filter((obj) => {
                        return obj.origin.city.toLowerCase().includes(this.$store.state.app.rateForm.checkRates.filters.origin.toLowerCase());
                    })
                    .sort(compare);
            },

            filteredShippingType() {
                function compare(a, b) {
                    if (a.shipping_type.name.toLowerCase() < b.shipping_type.name.toLowerCase()) return -1;
                    if (a.shipping_type.name.toLowerCase() > b.shipping_type.name.toLowerCase()) return 1;
                }

                if (this.$store.state.app.rateForm.checkRates.filters.destination == "") {
                    return this.filteredDestination;
                }

                return this.filteredDestination
                    .filter((obj) => {
                        return obj.origin.city.toLowerCase().includes(this.$store.state.app.rateForm.checkRates.filters.origin.toLowerCase());
                    })
                    .sort(compare);
            },

            filteredService() {
                function compare(a, b) {
                    if (a.service.name.toLowerCase() < b.service.name.toLowerCase()) return -1;
                    if (a.service.name.toLowerCase() > b.service.name.toLowerCase()) return 1;
                }

                if (this.$store.state.app.rateForm.checkRates.filters.shipping_type == "") {
                    return this.filteredShippingType;
                }

                return this.filteredShippingType
                    .filter((obj) => {
                        return obj.shipping_type.name.toLowerCase().includes(this.$store.state.app.rateForm.checkRates.filters.shipping_type.toLowerCase());
                    })
                    .sort(compare);
            },

            filteredProvider() {
                function compare(a, b) {
                    if (a.provider.name.toLowerCase() < b.provider.name.toLowerCase()) return -1;
                    if (a.provider.name.toLowerCase() > b.provider.name.toLowerCase()) return 1;
                }

                if (this.$store.state.app.rateForm.checkRates.filters.service == "") {
                    return this.filteredService;
                }

                return this.filteredService
                    .filter((obj) => {
                        return obj.service.name.toLowerCase().includes(this.$store.state.app.rateForm.checkRates.filters.service.toLowerCase());
                    })
                    .sort(compare);
            },

            currentRouteName() {
                return this.$route.name;
            },
            userFullName() {
                if (this.$store.state.userData.full_name.length > 30) {
                    return this.$store.state.userData.full_name.substring(0, 28) + "...";
                } else {
                    return this.$store.state.app.userData.full_name;
                }
            },
            role() {
                if (this.$store.state.userData.access_level == 5) {
                    return "Manager";
                } else if (this.$store.state.userData.access_level == 4) {
                    return "Admin";
                } else if (this.$store.state.userData.access_level == 3) {
                    return "Sales";
                } else if (this.$store.state.userData.access_level == 2) {
                    return "Client";
                } else {
                    return "Transporter";
                }
            },
        },

        methods: {
            validateDateTime(pickedDateTime) {
                const currentDateTime = new Date();

                // Check if the picked datetime is at least 3 hours forward from the current datetime
                if (pickedDateTime <= new Date(currentDateTime.getTime() + 3 * 60 * 60 * 1000)) {
                    return false;
                }

                // Check if the picked datetime is after 20:00 on each date
                if (pickedDateTime.getHours() >= 20) {
                    return false;
                }

                // Check if the picked datetime is before 09:00 on each date
                if (pickedDateTime.getHours() < 9) {
                    return false;
                }

                return true;
            },
            async createBooking() {
                const token = JSON.parse(localStorage.getItem("token"));
                const selectedRate = this.filteredProvider.pop();
                const rawDate = this.dateTime;

                console.log(rawDate);

                const body = {
                    total_cost: +this.summary.est_cost,
                    booking_status: 3,
                    quantity: this.summary.total_bagging,
                    clientRateId: selectedRate.id,
                    total_accounted_weight: this.summary.total_acc_wgt,
                    pickup_date: rawDate,
                    pickup_address: this.pickup_info.address_details,
                    pickup_latitude: this.gmaps.marker.lat,
                    pickup_longitude: this.gmaps.marker.lng,
                    pic_name: this.pickup_info.pic_name,
                    pic_phone: this.pickup_info.phone_number,
                    recipientCompanyId: this.recipientDetails.id,
                    additional_info: this.additional.additional_info,
                    payment_method: this.additional.payment_method,
                    insurance: this.additional.insurance,
                };

                await axios.post(this.$store.state.app.apiBasepath + "/booking-receipt/add", body, { headers: { Authorization: `Bearer ${token}` } }).then((res) => {
                    this.$store.state.app.openModal = "";
                    this.baggings = this.baggings.map((item) => {
                        console.log(res.data.id, "map ids");
                        return { ...item, bookingReceiptId: res.data.add.id };
                    });

                    this.$store.state.app.watchForUpdates = true;
                    setTimeout(() => {
                        this.$store.state.app.watchForUpdates = false;
                    }, 500);
                    return axios.post(this.$store.state.app.apiBasepath + "/bagging/add", { info: this.baggings }, { headers: { Authorization: `Bearer ${token}` } });
                });
            },
            requestGeo() {
                var options = {
                    enableHighAccuracy: true,
                    timeout: 5000,
                    maximumAge: 0,
                };
                if ("geolocation" in navigator) {
                    navigator.geolocation.getCurrentPosition(this.geoSuccess, this.geoFailure, options);
                } else {
                    console.log("No support");
                }
            },

            geoSuccess(position) {
                console.log("Success");

                console.log(position.coords);
                let coords = {
                    location: {
                        lat: position.coords.latitude,
                        lng: position.coords.longitude,
                    },
                };

                this.selectLocation(coords);
            },

            geoFailure(error) {
                console.log("Failure");
                console.log(error);
            },

            getLocDetails(coords) {
                // const PROXY_URL = "https://api.macra.co.id/";
                axios
                    .get("https://api.macra.co.id/maps/geocode?lat=" + coords.lat + "&lng=" + coords.lng)
                    .then((res) => {
                        console.log(res, "555");
                        let details = res.data.results[0];
                        this.gmaps.selected.area = details.address_components[3].long_name;
                        this.gmaps.selected.formatted_address = details.formatted_address;
                        this.pickup_info.address_details = this.gmaps.selected.formatted_address;
                        this.gmaps.selected.coords = {
                            lat: details.geometry.location.lat,
                            lng: details.geometry.location.lng,
                        };
                    })
                    .catch((err) => {
                        console.log(err);
                    });
            },
            clearSuggestion() {
                setTimeout(() => {
                    this.gmaps.suggestions = [];
                }, 150);
            },
            selectLocation(itemIndex) {
                let loc = itemIndex.location;
                this.gmaps.marker = { lat: loc.lat, lng: loc.lng };
                this.gmaps.center = { lat: loc.lat, lng: loc.lng };
                setTimeout(() => {
                    this.gmaps.suggestions = [];
                    this.gmaps.zoom = 15;
                }, 250);
                this.getLocDetails(loc);
            },
            suggestionResult() {
                this.gmaps.suggestions = [];
                // const PROXY_URL = "https://api.macra.co.id/";
                axios
                    .get("https://api.macra.co.id/maps/place?searchQuery=" + this.gmaps.searchQuery + "&lat=" + this.gmaps.center.lat + "&lng=" + this.gmaps.center.lng)
                    .then((res) => {
                        let results = res.data.results;
                        for (var i = 0; i < res.data.results.length && i < 3; i++) {
                            this.gmaps.suggestions[i] = results[i];
                        }
                    })
                    .catch((err) => {
                        console.log(err);
                    });
            },
            markerCallback(e) {
                let coords = {
                    location: {
                        lat: e.lat(),
                        lng: e.lng(),
                    },
                };
                this.selectLocation(coords);
            },
            validateTime() {
                var thisDate = new Date();
                var future = new Date(thisDate.toLocaleDateString("en-ZA") + " " + this.dateTime.time.hours + ":" + this.dateTime.time.minutes);

                future = future.setTime(future.getTime() + 3600000);
                future = new Date(future);
                this.pickup_info.pickup_time = future.toISOString();
                console.log(this.pickup_info);
            },
            addBagging() {
                let baggingInfo = {
                    good_details: "",
                    long: "",
                    width: "",
                    height: "",
                    dimensional_weight: "",
                    actual_weight: "",
                    accounted_weight: +0,
                    bookingReceiptId: null,
                };
                this.baggings.push(baggingInfo);
                $("#bagging-container").animate(
                    {
                        scrollTop: $("#bagging-container").get(0).scrollHeight,
                    },
                    500
                );
            },
            deleteBagging(itemIndex) {
                if (!this.hasAction) {
                    if (this.baggings.length > 1) {
                        this.hasAction = true;
                        $("#bagging" + itemIndex).toggleClass("fade");
                        setTimeout(() => {
                            this.baggings.splice(itemIndex, 1);
                            $("#bagging" + itemIndex).toggleClass("fade");
                            this.hasAction = false;
                        }, 250);
                    } else {
                        this.hasAction = true;
                        this.baggings[0] = {
                            good_details: "",
                            long: "",
                            width: "",
                            height: "",
                            dimensional_weight: "",
                            actual_weight: "",
                            accounted_weight: "-",
                            bookingReceiptId: null,
                        };
                        setTimeout(() => {
                            this.hasAction = false;
                        }, 250);
                    }
                }
            },
            baggingDimensionalWeight(itemIndex) {
                if (this.baggings[itemIndex].long == "") {
                    this.baggings[itemIndex].long = 0;
                }

                if (this.baggings[itemIndex].width == "") {
                    this.baggings[itemIndex].width = 0;
                }

                if (this.baggings[itemIndex].height == "") {
                    this.baggings[itemIndex].height = 0;
                }

                if (this.baggings[itemIndex].actual_weight == "") {
                    this.baggings[itemIndex].actual_weight = 0;
                }

                this.baggings[itemIndex].accounted_weight = 0;
                this.totalWeight = 0;

                let x = this.baggings[itemIndex];
                console.log(x);
                let y = null;

                if (x.long && x.width && x.height) {
                    if (this.filteredProvider[0].shipping_type.transportation_mode == "Air") {
                        y = 6000;
                    } else {
                        y = 4000;
                    }

                    x.dimensional_weight = (x.long * x.width * x.height) / y;

                    x.item_volume = x.long * 0.01 * (x.width * 0.01) * (x.height * 0.01);

                    this.baggings[itemIndex].dimensional_weight = x.dimensional_weight.toFixed(2);
                    this.baggings[itemIndex].item_volume = x.item_volume.toFixed(1);

                    if (x.actual_weight) {
                        if (x.actual_weight >= x.dimensional_weight) {
                            this.baggings[itemIndex].accounted_weight = x.actual_weight;
                        } else {
                            this.baggings[itemIndex].accounted_weight = x.dimensional_weight;
                        }

                        this.totalWeight = +0;
                        this.totalVolume = +0;

                        for (var i = 0; i < this.baggings.length; i++) {
                            this.totalWeight += +this.baggings[i].accounted_weight;
                            this.totalVolume += +this.baggings[i].item_volume;
                        }
                    }
                }
            },
            validateDrop(index_start) {
                if (index_start == 0) {
                    this.destination = "";
                    this.shipping_type = "";
                    this.service = "";
                    this.provider = "";

                    let optData = "";
                    if (this.$store.state.app.rateForm.checkRates.filters.mode == "Client") {
                        optData = "/" + this.$store.state.userData.id;
                    }

                    let token = JSON.parse(localStorage.getItem("token"));
                    setTimeout(() => {
                        axios
                            .get(this.$store.state.app.apiBasepath + "/" + this.$store.state.app.rateForm.checkRates.filters.mode + "-rate/all" + optData + "?search=origin&value=" + this.origin, { headers: { Authorization: `Bearer ${token}` } })
                            .then((res) => {
                                this.$store.state.app.rateForm.checkRates.data.origins = res.data.rows;
                            })
                            .catch((err) => {
                                console.log(err);
                            });
                    }, 100);
                }

                if (index_start == 1) {
                    this.shipping_type = "";
                    this.service = "";
                    this.provider = "";
                }

                if (index_start == 2) {
                    this.service = "";
                    this.provider = "";
                }

                if (index_start == 3) {
                    this.provider = "";
                }
            },
            setData() {
                this.showData.recipientDetails = false;
                setTimeout(() => {
                    this.showData.recipientDetails = true;
                }, 50);
            },
            validate(state, reason = null) {
                setTimeout(() => {
                    if (state) {
                        this.form.validating = false;
                        this.nextStage();
                    } else {
                        this.form.warning = reason ?? "Please complete the current form";
                        setTimeout(() => {
                            this.form.warning = null;
                        }, 3000);
                        this.form.validating = false;
                    }
                }, 100);
            },
            validateStage(stageId) {
                this.form.validating = true;
                setTimeout(() => {
                    if (stageId == 0) {
                        let valid = 0;
                        if (this.recipientDetails.pic_name != "") {
                            valid++;
                        }

                        if (valid == 1) {
                            this.validate(true);
                        } else {
                            this.validate(false);
                        }
                        console.log(valid);
                    } else if (stageId == 1) {
                        let valid = 0;
                        if (this.origin != "") {
                            valid++;
                        }

                        if (this.destination != "") {
                            valid++;
                        }

                        if (this.shipping_type != "") {
                            valid++;
                        }

                        if (this.service != "") {
                            valid++;
                        }

                        if (this.provider != "") {
                            valid++;
                        }

                        console.log(valid);
                        if (valid == 5) {
                            this.validate(true);
                        } else {
                            this.validate(false);
                        }
                    } else if (stageId == 2) {
                        console.log(this.dateTime);

                        if (this.pickup_info.address_details == "" || this.pickup_info.pic_name == "" || this.pickup_info.phone_number == "") {
                            this.validate(false);
                        } else {
                            this.validate(true);
                        }
                    } else if (stageId == 3) {
                        // let valid = 0;

                        let totalBagging = null;

                        for (let i = 0; i < this.baggings.length; i++) {
                            totalBagging += +this.baggings[i].accounted_weight;
                        }

                        const limits = this.filteredProvider[0].shipping_type;

                        if (totalBagging < this.filteredProvider[0].minimum_weight) {
                            return this.validate(false, "Receipt did not reach minimum weight");
                        }
                        if (totalBagging) {
                            let invalids = false;
                            for (const bag of this.baggings) {
                                if (bag.long > limits.max_long || bag.width > limits.max_width || bag.height > limits.height || totalBagging > limits.max_weight) {
                                    invalids = true;
                                    break;
                                }
                            }
                            if (invalids) return this.validate(false, "Some baggings did not meet validation");
                        }

                        return this.validate(true);
                    } else {
                        console.log("overflow");
                    }
                }, 100);
            },
            changePic() {
                $("#pict").click();
            },

            showPreview() {
                let pic = document.getElementById("pict");
                if (pic.files.length > 0) {
                    var src = URL.createObjectURL(pic.files[0]);
                    var preview = document.getElementById("pictureImg");
                    preview.src = src;
                }
            },
            togglePass() {
                $(".toggle-password").toggleClass("bi-eye bi-eye-slash-fill");
                var input = $("#pwd");
                if (input.attr("type") == "password") {
                    input.attr("type", "text");
                } else {
                    input.attr("type", "password");
                }
            },
            togglePassConfirm() {
                $(".toggle-password-confirm").toggleClass("bi-eye bi-eye-slash-fill");
                var input = $("#pwdconf");
                if (input.attr("type") == "password") {
                    input.attr("type", "text");
                } else {
                    input.attr("type", "password");
                }
            },
            nextStage() {
                this.stages[this.stageIndex].status = 2;
                this.stages[this.stageIndex + 1].status = 1;
                $("#stages").scrollLeft($("#stages").scrollHeight);
                this.stageIndex++;
                $("#nextStage").prop("disabled", true);
            },
            prevStage() {
                this.stages[this.stageIndex].status = 0;
                this.stages[this.stageIndex - 1].status = 1;
                $("#stages").scrollLeft("200px");
                this.stageIndex--;
            },
            photoInput() {
                $("#photoInput").click();
            },
            randomizeName() {
                return this.$CryptoJS.AES.encrypt(Date(), "@Macra2021").toString().substring(0, 16);
            },
            logout() {
                localStorage.clear();
                location.reload();
            },
            currentMenu() {
                if (["Publish Rates", "Client Rates", "Request Change"].includes(this.$route.name)) {
                    return "Rates";
                }

                if (["Users", "References"].includes(this.$route.name)) {
                    return "Manages";
                }

                if (["Receipt", "SLA"].includes(this.$route.name)) {
                    return "Reports";
                } else {
                    return this.$route.name;
                }
            },
            currentSubmenu() {
                let menu = this.currentMenu();
                if (["Dashboard", "Receipts"].includes(menu)) {
                    return "";
                } else {
                    return this.$route.name;
                }
            },
            getMenu(val) {
                this.menu = val;
            },
            getSub(val) {
                this.submenu = val;
            },
            toggleUserAction() {
                this.userAction = !this.userAction;
            },
            setFocusUser(ele) {
                $(ele.target).focus();
                console.log(ele.target);
            },
            keepLogin(getToken) {
                axios
                    .post(this.$store.state.app.apiBasepath + "/user/keep-login", {}, { headers: { Authorization: `Bearer ${JSON.parse(getToken)}` } })
                    .then((response) => {
                        localStorage.setItem("token", JSON.stringify(response.data.token));

                        let userData = response.data.keepLogin;
                        this.$store.state.userData = userData;
                    })
                    .catch(() => {
                        this.$router.push("/signin");
                    });
            },
            encode64() {
                async function listarchivos(fileInput) {
                    var base64; //in this variable i need the base64
                    var fileToLoad = fileInput;
                    base64 = await getBase64(fileToLoad).then((data) => {
                        return data;
                    });
                    console.log(base64);
                    return base64;
                }

                //This is my function for get base64, but not return the string base64
                function getBase64(file) {
                    return new Promise((resolve, reject) => {
                        const reader = new FileReader();
                        reader.readAsDataURL(file);
                        reader.onload = () => resolve(reader.result);
                        reader.onerror = (error) => reject(error);
                        return Promise.resolve(reader.result);
                    });
                }

                let result = listarchivos(this.photoFile);
                return result;
            },
            updateTable() {
                this.$refs.adminTable.this.getAllAdmin();
            },
            register() {
                let photoFile = this.photoFile;
                // return console.log(photoFile);
                let fileName = "";
                if (photoFile) {
                    if (photoFile.files.length > 0) {
                        fileName = this.randomizeName() + "." + this.photoFile.value.split(".").pop();
                        this.bodyForm.photo = fileName;
                    }
                } else {
                    fileName = null;
                }
                let token = JSON.parse(localStorage.getItem("token"));
                this.bodyForm.photo = fileName;
                // return console.log(this.bodyForm);
                if (photoFile != null) {
                    axios
                        .get(" https://dmmrx30km1.execute-api.ap-southeast-1.amazonaws.com/avatar-image-get-presigned-url?fileName=" + fileName, { headers: { "Access-Control-Allow-Origin": "*" } })
                        .then((res) => {
                            axios({
                                method: "PUT",
                                url: res.data.fileUploadURL,
                                data: this.photoFile.files[0],
                                headers: {
                                    "Content-Type": "image/jpeg",
                                },
                            }).then(() => {
                                axios
                                    .post(this.$store.state.app.apiBasepath + "/user/register", this.bodyForm, { headers: { Authorization: `Bearer ${token}` } })
                                    .then(() => {
                                        this.$store.state.app.managerForm.currentTab = "";
                                        this.$alertSys("New Admin successfully added!", "success");
                                        setTimeout(() => {
                                            this.$store.state.app.managerForm.currentTab = "admin";
                                            this.$store.state.app.openModal = "";
                                        }, 250);
                                    })
                                    .catch(() => {
                                        this.$alertSys("An error occured", "err");
                                    });
                            });
                        })
                        .catch(() => {
                            this.$alertSys("An error occured", "err");
                        });
                } else {
                    axios
                        .post(this.$store.state.app.apiBasepath + "/user/register", this.bodyForm, { headers: { Authorization: `Bearer ${token}` } })
                        .then(() => {
                            this.$store.state.app.managerForm.currentTab = "";
                            this.$alertSys("New Admin successfully added!", "success");

                            setTimeout(() => {
                                this.$store.state.app.managerForm.currentTab = "admin";
                                this.$store.state.app.openModal = "";
                            }, 250);
                        })
                        .catch(() => {
                            this.$alertSys("An error occured", "err");
                        });
                }
            },
            ascSort() {
                function compare(a, b) {
                    if (a.company.toLowerCase() < b.company.toLowerCase()) return -1;
                    if (a.company.toLowerCase() > b.company.toLowerCase()) return 1;
                }

                this.page = 1;
                this.sortType = "ASC";
                this.$store.state.app.recipientsForm.allRecipients.sort(compare);
                // this.paginate();
            },
            originSort() {
                function compare(a, b) {
                    if (a.origin.city.toLowerCase() < b.origin.city.toLowerCase()) return -1;
                    if (a.origin.city.toLowerCase() > b.origin.city.toLowerCase()) return 1;
                }

                this.page = 1;
                this.sortType = "ASC";
                this.$store.state.app.rateForm.checkRates.data.allData.sort(compare);
                // this.paginate();
            },
            descSort() {
                function compare(a, b) {
                    if (a.full_name < b.full_name) return 1;
                    if (a.full_name > b.full_name) return -1;
                }

                this.page = 1;
                this.sortType = "DESC";
                this.$store.state.app.managerForm.allAdmins.sort(compare);
                this.paginate();
            },
            getAllAdmins() {
                this.$store.commit("tablePreloader", { status: "start" });
                let token = JSON.parse(localStorage.getItem("token"));

                axios
                    .get(this.$store.state.app.apiBasepath + "/recipient-company/all", { headers: { Authorization: `Bearer ${token}` } })
                    .then((res) => {
                        this.$store.state.app.recipientsForm.allRecipients = res.data.rows;
                        if (res.data.count == 0) {
                            this.$store.commit("tablePreloader", { status: "noresult" });
                        } else {
                            this.$store.commit("tablePreloader", { status: "success" });
                        }

                        this.ascSort();
                    })
                    .catch((err) => {
                        console.log("500: " + err);
                    });
            },
            paginate() {
                // human-readable page numbers usually start with 1, so we reduce 1 in the first argument
                for (let i = 0; i < this.totalPages; i++) {
                    this.$store.state.app.managerForm.filteredAdmins[i] = JSON.parse(JSON.stringify(this.$store.state.app.managerForm.allAdmins.slice(i * 6, (i + 1) * 6)));
                }
            },
        },
        mounted() {
            this.getAllAdmins();

            let token = JSON.parse(localStorage.getItem("token"));
            axios
                .get(this.$store.state.app.apiBasepath + "/" + "client-rate/all/" + this.$store.state.userData.id, { headers: { Authorization: `Bearer ${token}` } })
                .then((res) => {
                    this.$store.state.app.rateForm.checkRates.data.allData = res.data.rows;
                    this.originSort();
                })
                .catch((err) => {
                    console.log(err);
                });

            // this.dateTime.date = new Date();
            // this.dateTime.time = {
            //     hours: this.minTime.getHours(),
            //     minutes: Math.round(this.minTime.getMinutes() / 10) * 10,
            // };

            // EXPREIMEMNTAL
            // $("#stage1").select2({
            //   placeholder: "Select Recipient",
            // });
        },
    };
</script>

<style lang="scss" scoped>
    .dp__main {
        padding: 0;
        input {
            background-color: whitesmoke;
            padding: 15px 20px;
            height: 100%;
            border: none;
        }

        .dp__input_icon {
            left: auto !important;
            right: 0 !important;
        }
    }

    .popup {
        &-trigger {
            cursor: pointer;
            color: #3699ff;

            &:hover {
                text-decoration: underline;
            }
        }

        &-info {
            transition: all 0.3s ease-in-out;

            user-select: none;
            pointer-events: none;
            position: absolute;
            right: 0;

            transform: translateX(-25%) translateY(16px);
            z-index: 12;

            min-width: 200px;
            width: fit-content;
            max-width: 400px;
            padding: 22px;
            background: #fff;
            border-radius: 6px;

            box-shadow: 0 0 15px 1px rgba(0, 0, 0, 0.05);

            .row {
                padding: 0;
            }
        }
    }
    .note {
        background: #eeeeee;
        border-radius: 6px;
        &:focus {
            outline: 1px solid rgba(0, 0, 0, 0.05);
        }

        &:active {
            outline: 1px solid rgba(0, 0, 0, 0.05);
        }
    }
    .custom-checkbox {
        display: inline-block;
        position: relative;
        padding-left: 25px; // Adjust spacing as needed
        cursor: pointer;

        input {
            opacity: 0;
            position: absolute;
            top: 0;
            left: 0;
        }

        .checkmark {
            position: absolute;
            top: 3px;
            left: 0;
            height: 16px;
            width: 16px;
            border: 2px solid #ccc;
            border-radius: 4px;

            &:after {
                content: "";
                position: absolute;
                display: none;
            }
        }

        input:checked ~ .checkmark {
            border-color: transparent; // Remove border color for checked state
            background-color: #2196f3; // Customize checked background color
        }

        input:checked ~ .checkmark:after {
            display: block;
            content: "\2713"; // Checkmark character
            font-size: 12px;
            color: #fff; // Customize checkmark color
            top: -3px;
            left: 2px;
        }
    }

    #selectedLocation {
        position: absolute;
        z-index: 9999;
        margin: 0 10px;
        border-radius: 6px;
        width: 560px;
    }

    #selectedLocation > .selected {
        width: 100%;
        background-color: white;
        cursor: pointer;
        transition: all 0.25s ease;
    }
    #suggestions {
        position: absolute;
        z-index: 9999;
        margin: 0 10px;
        border-radius: 6px;
        width: 560px;
    }

    .suggestion {
        width: 100%;
        background-color: white;
        cursor: pointer;
        transition: all 0.25s ease;
    }

    .suggestion:hover {
        color: #3699ff;
    }

    #map_canvas {
        width: 980px;
        height: 500px;
    }
    #current {
        padding-top: 25px;
    }
    #pickup_maps > a {
        width: 0;
        height: 0;
        opacity: 0;
    }

    #details > .row {
        margin-top: 14px;
    }

    .bagging-add {
        margin: 10px;
    }

    .bagging-add > .button {
        color: white;
        background: #3699ff;
        border-radius: 50%;
        transform: scale(0.75);
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
    }
    .bagging-add > .button:hover {
        box-shadow: 0 0 5px 2px rgba(0, 0, 0, 0.2);
    }

    .button > img {
        transform: scale(0.8);
        min-height: 52px;
        filter: invert(100%) brightness(300%) saturate(100%) contrast(100%);
        cursor: pointer;
    }

    .bagging-container {
        max-height: 60vh;
        overflow-y: auto;
    }

    /*
      *  STYLE 5
      */

    #bagging-container::-webkit-scrollbar-track {
        -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
        background-color: #f5f5f5;
    }

    #bagging-container::-webkit-scrollbar {
        width: 0px;
        background-color: #f5f5f5;
    }

    #bagging-container::-webkit-scrollbar-thumb {
        background-color: #0ae;

        background-image: -webkit-gradient(linear, 0 0, 0 100%, color-stop(0.5, rgba(255, 255, 255, 0.2)), color-stop(0.5, transparent), to(transparent));
    }

    .bagging-card {
        border-radius: 6px;
        min-height: 150px;
        background-color: white;
        box-shadow: 0 0 5px 2px rgba(0, 0, 0, 0.05);
        margin: 10px;
        transition: all 0.25s ease;
        user-select: none;
    }

    .bagging-card > .delete {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        background: #eeeeee;
        transition: all 0.5 ease;
        cursor: pointer;

        border-radius: 0 6px 6px 0;
    }

    .bagging-card > .delete:hover {
        background: rgba(54, 153, 255, 0.1);
        filter: invert(51%) sepia(27%) saturate(6806%) hue-rotate(329deg) brightness(100%) contrast(104%);
        transition: all 0.5 ease;
    }

    #forms::-webkit-scrollbar {
        width: 0px;
        background: transparent; /* make scrollbar transparent */
    }

    #forms.profile {
        max-height: 45vh;
    }
    #closeModal {
        float: right;
        transform: rotate(45deg);
        border-radius: 50%;
        cursor: pointer;
        transition: 0.3s;
        border: 1px solid transparent;
    }

    #closeModal:hover {
        border: 1px solid black;
    }

    #navigation {
        width: 100%;
        position: absolute;
        bottom: 0;
        right: 0;
    }

    #stages > .col-auto {
        opacity: 0.5;
    }

    #stages > .col-auto.active {
        opacity: 1;
    }

    #pictureImg {
        cursor: pointer;
        width: 110px;
        height: 110px;
        object-fit: cover;
        object-position: center;
        border-radius: 50%;
    }

    #pictureImgBtn {
        cursor: pointer;
        border-radius: 50%;
        height: 32px;
        width: 32px;
        display: inline-block;
        background-color: #3699ff;

        position: absolute;
        top: 180px;
        left: 53%;
    }

    #pictureImgBtn > #pictureBtn {
        transform: scale(1.2);
        filter: invert(100%) sepia(100%) saturate(0%) hue-rotate(73deg) brightness(103%) contrast(103%);
    }

    #profilePicture {
        height: 20%;
    }

    #stages {
        overflow-x: scroll;
    }
    #stages::-webkit-scrollbar {
        width: 0px;
        background: transparent; /* make scrollbar transparent */
    }

    .blur-overlay {
        display: block;
        filter: blur(3px) brightness(90%);
        -webkit-filter: blur(3px) brightness(90%);
        -moz-filter: blur(3px) brightness(90%);
        -o-filter: blur(3px) brightness(90%);
        -ms-filter: blur(3px) brightness(90%);
        pointer-events: none;
    }

    .spacer {
        min-width: 250px;
        width: 20vw;
    }

    .opacity-0 {
        opacity: 0;
    }

    /* VUE TRANSITION */
    .fade-enter-active,
    .fade-leave-active {
        transition: opacity 0.2s ease;
    }
    .fade-enter-from,
    .fade-leave-to {
        opacity: 0;
    }

    /* css  */
    myroute {
        cursor: pointer;
    }

    .btn-back {
        color: #3699ff;
        outline: 1px solid #3699ff;
    }

    .btn-back:hover {
        color: #3699ff;
        outline: 1px solid #3699ff;
        box-shadow: 0 0 1px 1px rgba(0, 0, 0, 0.5);
    }

    .usfc-bg {
        background-color: #fbfbfb;
    }

    #app {
        font-family: Avenir, Helvetica, Arial, sans-serif;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        text-align: center;
        color: #2c3e50;
    }

    input[type="password"] {
        font-family: Verdana;
        letter-spacing: 0.25em;
        font-weight: 900;
    }

    .toggle-password,
    .toggle-password-confirm {
        cursor: pointer;
    }

    .blue-filter {
        filter: invert(45%) sepia(69%) saturate(2325%) hue-rotate(196deg) brightness(108%) contrast(101%);
    }
    .profile-card {
        background-color: white;
        width: 75px;
        height: 100%;
        border-radius: 20px;
        cursor: pointer;
    }

    /* .profile-card:hover {
             border: solid 1px rgba(54, 153, 255, 0.25);
           } */

    .prevent-select {
        -webkit-user-select: none; /* Safari */
        -ms-user-select: none; /* IE 10 and IE 11 */
        user-select: none; /* Standard syntax */
    }

    .parent_arrow {
        transform: rotate(180deg);
    }

    .user-button {
        z-index: 999;
        position: absolute;
        width: auto;
        right: 20px;
        top: 15vh;
        background-color: white;
        box-shadow: 5px 5px 10px rgba(0, 0, 0, 0.05);
        text-align: center;
        border-radius: 6px;
    }

    ul.user-button {
        position: absolute;
        top: 75px;
        right: 0;
        padding-left: 0;
    }

    li:hover {
        opacity: 1;
        background: rgba(54, 153, 255, 0.1);
        border-radius: 6px;
        filter: invert(42%) sepia(100%) saturate(546%) hue-rotate(178deg) brightness(107%) contrast(101%);
    }

    .sign-out-button {
        filter: invert(51%) sepia(27%) saturate(6806%) hue-rotate(329deg) brightness(111%) contrast(104%);
    }

    .sign-out-button:hover {
        filter: invert(51%) sepia(27%) saturate(6806%) hue-rotate(329deg) brightness(111%) contrast(104%);
    }

    .top-panel {
        height: 10vh;
    }

    a {
        color: inherit;
        text-decoration: none;
    }

    /* INPUT FORM LABEL STYLE */
    .label-float {
        position: relative;
        padding-top: 13px;
    }

    input {
        width: 100%;
        border: 1px solid transparent;
    }

    select {
        width: 100%;
        border: 1px solid transparent;
    }

    select:focus-visible {
        outline: none;
    }

    .btn {
        font-size: small;
        min-width: 90px;

        border-radius: 6px;
        padding: 10px 25px;
    }

    .btn.btn-cancel {
        border-radius: 6px;
        padding: 10px 25px;
    }

    .btn.btn-cancel:hover {
        border: 1px solid rgba(0, 0, 0, 0.2);
    }

    .btn.btn-cancel:active {
        border: 1px solid rgba(0, 0, 0, 1);
    }

    .btn.btn-usfc-primary {
        background-color: #3699ff;
        color: white;
        border-radius: 6px;
        padding: 10px 25px;
    }

    .wait {
        opacity: 0.5;
        cursor: not-allowed;
    }

    .wait:hover {
        box-shadow: none;
    }
    .btn-usfc-primary:hover {
        background-color: #3699ff;
        color: white;
        box-shadow: 5px 5px 15px 10px rgba(0, 0, 0, 0.05);
    }

    .label-float input {
        border: none;
        background-color: whitesmoke;
        border-radius: 5px;
        outline: none;
        min-width: 30%;
        padding: 15px 20px;
        font-size: 16px;
        transition: all 0.1s linear;
        -webkit-transition: all 0.1s linear;
        -moz-transition: all 0.1s linear;
        -webkit-appearance: none;
        appearance: none;
        top: 10px;
    }

    .label-float select {
        border: none;
        background-color: whitesmoke;
        border-radius: 5px;
        outline: none;
        min-width: 250px;
        padding: 15px 20px;
        font-size: 16px;
        transition: all 0.1s linear;
        -webkit-transition: all 0.1s linear;
        -moz-transition: all 0.1s linear;
        -webkit-appearance: none;
        appearance: none;
        top: 10px;
    }

    .label-float input:focus {
        border: 2px solid #3699ff;
        background-color: transparent;
    }

    .label-float select:focus {
        border: 2px solid #3699ff;
        background-color: transparent;
    }

    .label-float input::placeholder {
        color: black;
    }

    .label-float select::placeholder {
        color: black;
    }

    .label-float label {
        pointer-events: none;
        position: absolute;
        top: calc(50% - 8px);
        left: 15px;
        transition: all 0.1s linear;
        -webkit-transition: all 0.1s linear;
        -moz-transition: all 0.1s linear;
        background-color: transparent;
        padding: 5px;
        box-sizing: border-box;
        color: rgba(0, 0, 0, 0.5);
    }

    .label-float input:required:invalid + label {
        color: red;
    }
    .label-float input:focus:required:invalid {
        border: 2px solid red;
    }
    .label-float input:required:invalid + label:before {
        content: "*";
    }
    .label-float input:focus + label,
    .label-float input:not(:placeholder-shown) + label {
        font-size: 13px;
        top: 10px;
        color: #3951b2;
        opacity: 1;
        background-color: transparent;
    }

    .label-float select:required:invalid + label {
        color: red;
    }
    .label-float select:focus:required:invalid {
        border: 2px solid red;
    }
    .label-float select:required:invalid + label:before {
        content: "*";
    }

    label.option-selected {
        font-size: 13px;
        transform: translateY(calc(-55%));
        color: #3951b2;
        background-color: transparent;
    }

    /* FA ICONS CUSTOM */
    .fa-check {
        margin: 0 5px;
        position: relative;
        top: -2px;
        font-size: 12px;
        color: white;
        border-radius: 50%;
        padding: 3px;
        background-color: #3699ff;
    }

    /* * {
         border: 1px solid red;
       } */
</style>
