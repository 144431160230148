import store from "../store"; // Import your Vuex store instance

export function alertSys(msg, type) {
    store.state.alert = {
        message: null,
        type: null,
    };
    //  TYPE ['ERR', 'PENDING', 'SUCCESS']

    store.state.alert = {
        message: msg,
        type: type.toUpperCase(),
    };

    if (type.toUpperCase() != "PENDING")
        setTimeout(() => {
            store.state.alert = {
                message: null,
                type: null,
            };
        }, 5000);
}

// export function helperMethod2() {
//     // Access the store state, actions, and getters as needed
//     // ...
//     console.log("Helper Method 2");
// }
