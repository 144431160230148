import { createApp } from "vue";
import App from "./App.vue";
import store from "./store";
import router from "./router";
import VueCountdown from "@chenfengyuan/vue-countdown";
import VueCryptojs from "vue-cryptojs";
import _ from "lodash";
import LottieAnimation from "lottie-web-vue";
// import { Select2 } from "select2";
import Datepicker from "@vuepic/vue-datepicker";
import "@vuepic/vue-datepicker/dist/main.css";
import { alertSys } from "./helper/alert";

// import VueSelect from "vue-select";
// import "vue-select/dist/vue-select.css";

const appRoot = createApp(App)
    .component(VueCountdown.name, VueCountdown)
    .component("DatePicker", Datepicker)
    .use(_)
    .use(LottieAnimation)

    .use(store)
    .use(router)
    .use(VueCryptojs);

appRoot.config.globalProperties.$alertSys = alertSys;
appRoot.mount("#app");

// App.config.compilerOptions.isCustomElement = (tag) => tag === "form-wrapper";
// App.config.compilerOptions.isCustomElement = (tag) => tag === "myroute";
// App.config.compilerOptions.isCustomElement = (tag) => tag === "iconGroup";
