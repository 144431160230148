<template>
    <div class="frame">
        <div class="nav">
            <div class="nav-item" :class="[currentTab === 'details' ? 'active' : '']" @click="currentTab = 'details'">Receipt Details</div>
            <div class="nav-item" :class="[currentTab === 'tracking' ? 'active' : '']" @click="currentTab = 'tracking'">Tracking</div>
            <div v v-if="isAdmin || isManager" class="nav-item" :class="[currentTab === 'gas' ? 'active' : '']" @click="currentTab = 'gas'">Gas Stations</div>
            <div class="nav-item" :class="[currentTab === 'summary' ? 'active' : '']" @click="currentTab = 'summary'">Order Summary</div>
            <div v-if="(isAdmin || isManager) && getReceipt.delivery_status === 1" @click="this.$store.state.app.openModal = 'changeTransporter'" class="btn btn-usfc-outline" style="position: absolute; bottom: 25px">Change Transporter</div>
            <div v-if="isClient && getReceipt.delivery_status === 3" @click="this.$store.state.app.openModal = 'receivePackage'" class="btn btn-usfc-outline" style="position: absolute; bottom: 25px">Package Received</div>
        </div>
        <div v-if="currentTab === 'details'" class="detail">
            <div class="receipt">
                <div class="checkpoint mb-2">
                    <div class="title my-2"><span class="dot mx-1"> </span> Origin Location</div>
                    <div class="content lined">
                        <div class="item">{{ receipt.detail?.booking_receipt?.pickup_address }}</div>
                        <div class="item">
                            <div @click="currentTab = 'tracking'" class="mx-1 mt-1 py-2 px-2 row update-location process">
                                <div class="col">
                                    <div style="float: left" class="pulsating-circle mx-2"></div>

                                    <span style="float: left; font-weight: bold; font-size: ">See Current Location</span>
                                    <span style="float: right"><img src="/icons/all/twotone/arrow-left-1.svg" style="transform: rotate(180deg) scale(1.4); height: 12px" /></span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="checkpoint">
                    <div class="title"><span class="dot mx-1"> </span> Destination</div>
                    <div class="content">
                        <div class="item">{{ `${getRecipient.city},${getRecipient.province}. ${getRecipient.postal_code}` }}</div>
                    </div>
                </div>
            </div>
            <div class="container">
                <div class="title">Transporter Information</div>
                <div class="content">
                    <div class="title">Name</div>
                    <div class="content" style="display: flex; flex-direction: row; gap: 6px">
                        {{ receipt?.transporter?.full_name }} <span style="opacity: 0.7"> ({{ receipt?.transporter?.phone }})</span>
                    </div>
                </div>
            </div>
            <div class="container">
                <div class="title">Shipping Information</div>
                <div class="content">
                    <div class="title">Delivery Route</div>
                    <div class="content" style="display: flex; flex-direction: row; gap: 6px">{{ getRate?.origin?.city }} &rarr; {{ getRate?.destination?.city }}</div>
                </div>
                <div class="content">
                    <div class="title">Provider</div>
                    <div class="content" style="display: flex; flex-direction: row; gap: 6px">{{ getRate?.provider?.name }}</div>
                </div>
                <div class="content">
                    <div class="title">Service</div>
                    <div class="content" style="display: flex; flex-direction: row; gap: 6px">{{ getRate?.service?.name }}</div>
                </div>
                <div class="content">
                    <div class="title">Transportation Mode</div>
                    <div class="content" style="display: flex; flex-direction: row; gap: 6px">
                        <span class="blue-filter" style="transform: scale(0.75)">
                            <img v-if="admin?.transportation_mode?.toLowerCase() == 'air'" src="/icons/all/twotone/airplane.svg" />
                            <img v-else-if="admin?.transportation_mode?.toLowerCase() == 'water'" src="/icons/all/twotone/ship.svg" />
                            <img v-else src="/icons/all/twotone/truck.svg" />
                        </span>
                        <span>{{ getRate?.shipping_type?.name }}</span>
                    </div>
                </div>
            </div>
            <div class="container">
                <div class="title">Pickup Information</div>
                <div class="content">
                    <div class="title">Address Details</div>
                    <div class="content" style="display: flex; flex-direction: row; gap: 6px">{{ getBookingReceipt?.pickup_address }}</div>
                </div>
                <div class="content">
                    <div class="title">PIC Name</div>
                    <div class="content" style="display: flex; flex-direction: row; gap: 6px">{{ getBookingReceipt?.pic_name }}</div>
                </div>
                <div class="content">
                    <div class="title">PIC Phone Number</div>
                    <div class="content" style="display: flex; flex-direction: row; gap: 6px">{{ getBookingReceipt?.pic_phone }}</div>
                </div>
                <div class="content">
                    <div class="row">
                        <div class="col">
                            <div class="title">Pickup Date</div>
                            <div class="content" style="display: flex; flex-direction: row; gap: 6px">{{ getTime(getBookingReceipt?.pickup_date, "date") }}</div>
                        </div>
                        <div class="col">
                            <div class="title">Pickup Time</div>
                            <div class="content" style="display: flex; flex-direction: row; gap: 6px">{{ getTime(getBookingReceipt?.pickup_date) }}</div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="container">
                <div class="title">Bagging Information</div>
                <div v-for="(bag, index) in receipt.baggings" :key="bag.id" class="content" :class="index != 0 ? 'mt-5' : ''">
                    <div class="title__numbered" style="font-weight: 600">[ Bagging #{{ index + 1 }} ]</div>
                    <div class="title">Good Details</div>
                    <div class="content" style="display: flex; flex-direction: row; gap: 6px">
                        {{ bag.good_details }}
                    </div>
                    <div class="title">Dimension Measurements</div>
                    <div class="content" style="display: flex; flex-direction: row; gap: 6px">Actual Dimension: (L){{ bag.long }} cm x (W){{ bag.width }} cm x (H){{ bag.height }} cm</div>
                    <div class="content" style="display: flex; flex-direction: row; gap: 6px">Volumetric: {{ (bag.long * 0.01 * (bag.width * 0.01) * (bag.height * 0.01)).toFixed(2) }} m<sup>3</sup></div>
                    <div class="title">Weight Measurements</div>
                    <div class="content" style="display: flex; flex-direction: row; gap: 6px">Actual Weight: {{ bag.actual_weight }} kg</div>
                    <div class="content" style="display: flex; flex-direction: row; gap: 6px">Dimensional Weight: {{ bag.dimensional_weight }} kg</div>
                    <div class="content" style="display: flex; flex-direction: row; gap: 6px">Accounted Weight: {{ bag.accounted_weight > bag.dimensional_weight ? bag.accounted_weight : bag.dimensional_weight }} kg</div>
                </div>
            </div>
            <div class="shipper">
                <div class="col">
                    <div class="title">Shipper Information</div>
                    <div class="content">
                        <div class="title">Company</div>
                        <div class="content">{{ getShipper.company }}</div>
                    </div>
                    <div class="content">
                        <div class="title">PIC Name</div>
                        <div class="content">{{ getShipper.full_name }}</div>
                    </div>
                    <div class="content">
                        <div class="title">Phone Number</div>
                        <div class="content">{{ getShipper.phone }}</div>
                    </div>
                    <div class="content">
                        <div class="title">Province</div>
                        <div class="content">{{ getShipper.province }}</div>
                    </div>
                    <div class="content">
                        <div class="title">City</div>
                        <div class="content">{{ getShipper.city }}</div>
                    </div>
                    <div class="content">
                        <div class="title">District</div>
                        <div class="content">{{ getShipper.district }}</div>
                    </div>
                    <div class="content">
                        <div class="title">Postal Code</div>
                        <div class="content">{{ getShipper.postal_code }}</div>
                    </div>
                    <div class="content">
                        <div class="title">Address</div>
                        <div class="content">{{ getShipper.street }}</div>
                    </div>
                </div>
                <div class="col">
                    <div class="title">Recipient Information</div>
                    <div class="content">
                        <div class="title">Company</div>
                        <div class="content">{{ getRecipient.company }}</div>
                    </div>
                    <div class="content">
                        <div class="title">PIC Name</div>
                        <div class="content">{{ getRecipient.pic_name }}</div>
                    </div>
                    <div class="content">
                        <div class="title">Phone Number</div>
                        <div class="content">{{ getRecipient.phone }}</div>
                    </div>
                    <div class="content">
                        <div class="title">Province</div>
                        <div class="content">{{ getRecipient.province }}</div>
                    </div>
                    <div class="content">
                        <div class="title">City</div>
                        <div class="content">{{ getRecipient.city }}</div>
                    </div>
                    <div class="content">
                        <div class="title">District</div>
                        <div class="content">{{ getRecipient.district }}</div>
                    </div>
                    <div class="content">
                        <div class="title">Postal Code</div>
                        <div class="content">{{ getRecipient.postal_code }}</div>
                    </div>
                    <div class="content">
                        <div class="title">Address</div>
                        <div class="content">{{ getRecipient.street }}</div>
                    </div>
                </div>
            </div>
        </div>

        <!-- LIVE -->
        <div v-if="currentTab === 'tracking' && getBookingReceipt.client_rate.shipping_type.is_real_time" class="tracking">
            <!-- @click="markerCallback($event.latLng)" -->
            <GoogleMap class="map" api-key="AIzaSyCzYRio6GWqdf03CAd9Gs1sjS8b6fuIAyM" :center="gmaps.center" :controlSize="24" :fullscreen-control="false" :clickableIcons="false" :map-type-control="false" :street-view-control="false" :zoom-control="false" :styles="gmaps.styles" :zoom="gmaps.zoom">
                <Marker class="marker" v-if="this.gmaps.marker.lat != null" :options="{ position: gmaps.marker, draggable: false, icon: getMarkerIcon }" @dragend="markerCallback($event.latLng)" />
                <Marker class="marker" v-if="this.gmaps.historyMarker.lat != null" :options="{ position: gmaps.historyMarker, draggable: false, icon: '/icons/location_picker.svg', label: 'Location History', title: 'Location History' }" @dragend="markerCallback($event.latLng)" />
                <Marker class="marker" v-if="this.gmaps.originMarker.lat != null" :options="{ position: gmaps.originMarker, draggable: false, icon: '/icons/custom/map_origin.svg' }" @dragend="markerCallback($event.latLng)" />
                <Marker class="marker" v-if="this.gmaps.destinationMarker.lat != null" :options="{ position: gmaps.destinationMarker, draggable: false, icon: '/icons/custom/map_destination.svg' }" @dragend="markerCallback($event.latLng)" />
            </GoogleMap>
            <div v-if="(isAdmin || isManager) && getReceipt.delivery_status < 3" @click="this.$store.state.app.openModal = 'updateTrip'" class="btn btn-usfc-outline" style="position: absolute; bottom: 25px; right: 50px; z-index: 99">Update Trip</div>
        </div>

        <!-- STATIC -->
        <div v-if="currentTab === 'tracking'" class="trip-history" :class="[tripShow ? 'show' : '', getBookingReceipt.client_rate.shipping_type.is_real_time ? 'floating' : 'static']">
            <div v-if="getBookingReceipt.client_rate.shipping_type.is_real_time" @click="tripShow = !tripShow" class="handle">
                {{ tripShow ? "Hide Trip History" : "Show Trip History" }} <span><img class="toggler blue-filter" :class="[tripShow ? 'show' : '']" src="/icons/all/twotone/arrow-up-1.svg" /></span>
            </div>
            <div class="trip">
                <div class="latest">
                    <div class="status mb-2">
                        <div class="title text-blue">{{ getCurrentDeliveryStatus }}</div>
                        <div class="update-status" :class="`receipt__${getReceipt.delivery_status}`"></div>
                    </div>
                    <div class="position">
                        <div><img class="me-4" :class="[tripShow ? 'show' : '']" src="/icons/custom/location_sm.svg" /></div>
                        <div class="place">
                            <div>{{ getLatestStatus ?? "Latest address unavailable" }}</div>
                            <div v-if="getBookingReceipt.client_rate.shipping_type.is_real_time && getReceipt.delivery_status > 1" @click="viewLocationHistory()" class="text-blue" style="cursor: pointer">{{ getLatestAddress ? "View Location" : "Location Unavailable" }}</div>
                        </div>
                    </div>
                </div>
                <div v-if="delayedTripShow || !getBookingReceipt.client_rate.shipping_type.is_real_time" class="trip-show">
                    <div class="status">
                        <div class="title">Trip History</div>
                    </div>

                    <!-- Delivered -->
                    <div v-if="getReceipt.delivery_status > 2" class="history">
                        <div class="detail">
                            <div class="time">
                                <div class="dot"></div>
                                <div>{{ formatDate(getReceipt.delivered_time) }}</div>
                            </div>
                            <div class="content">
                                <div class="address">{{ getReceipt.delivered_address_address ?? "Update address unavailable" }}</div>
                                <div class="note">
                                    <div class="title">Note</div>
                                    <div class="content">{{ getReceipt.delivered_note ?? "-" }}</div>
                                </div>
                                <div v-if="!getReceipt.delivered_photo && !getReceipt.delivered_document" class="note">
                                    <div class="title">Updated By</div>
                                    <div class="content">{{ getReceipt.updated_by ?? "-" }}</div>
                                </div>
                            </div>
                        </div>
                        <div @click="openPhotoViewer(3, getReceipt.delivered_photo, getReceipt.delivered_document)" class="photo">
                            <img :src="'https://s3-usfc-arrived-image.s3.ap-southeast-1.amazonaws.com/' + getReceipt.delivered_photo" onerror="this.src='/placeholders/unavailable-image.jpg'" alt="Fallback Image" />
                        </div>
                    </div>

                    <!-- Update -->
                    <div v-if="getReceipt.delivery_status > 1 && getReceipt.update_time" class="history">
                        <div class="detail">
                            <div class="time">
                                <div class="dot"></div>
                                <div>{{ formatDate(getReceipt.update_time) }}</div>
                            </div>
                            <div class="content">
                                <div class="address">{{ getReceipt.update_address ?? "Update address unavailable" }}</div>
                                <div class="note">
                                    <div class="title">Note</div>
                                    <div class="content">{{ getReceipt.update_note ?? "-" }}</div>
                                </div>
                                <div v-if="!getReceipt.update_photo && !getReceipt.update_document" class="note">
                                    <div class="title">Updated By</div>
                                    <div class="content">{{ getReceipt.updated_by ?? "-" }}</div>
                                </div>
                            </div>
                        </div>
                        <div @click="openPhotoViewer(2, getReceipt.update_photo, getReceipt.update_document)" class="photo">
                            <img :src="'https://s3-usfc-delivery-status-image.s3.ap-southeast-1.amazonaws.com/' + getReceipt.update_photo" onerror="this.src='/placeholders/unavailable-image.jpg'" alt="Fallback Image" />
                        </div>
                    </div>

                    <!-- Pickup -->
                    <div v-if="getReceipt.delivery_status > 1" class="history">
                        <div class="detail">
                            <div class="time">
                                <div class="dot"></div>
                                <div>{{ formatDate(getReceipt.actual_pickup_time) }}</div>
                            </div>
                            <div class="content">
                                <div class="address">{{ getReceipt.pickup_address ?? "Pickup address unavailable" }}</div>
                                <div class="note">
                                    <div class="title">Note</div>
                                    <div class="content">{{ getReceipt.pickup_note ?? "-" }}</div>
                                </div>
                                <div v-if="!getReceipt.pickup_photo && !getReceipt.pickup_document" class="note">
                                    <div class="title">Updated By</div>
                                    <div class="content">{{ getReceipt.updated_by ?? "-" }}</div>
                                </div>
                            </div>
                        </div>
                        <div @click="openPhotoViewer(1, getReceipt.pickup_photo, getReceipt.pickup_document)" class="photo">
                            <img :src="'https://s3-usfc-pickup-image.s3.ap-southeast-1.amazonaws.com/' + getReceipt.pickup_photo" onerror="this.src='/placeholders/unavailable-image.jpg'" alt="Fallback Image" />
                        </div>
                    </div>
                </div>
            </div>
            <div v-if="(isAdmin || isManager) && !getBookingReceipt.client_rate.shipping_type.is_real_time" @click="this.$store.state.app.openModal = 'updateTrip'" class="btn btn-usfc-outline" style="position: absolute; bottom: 25px; right: 50px">Update Trip</div>
        </div>

        <div v-if="currentTab === 'gas'" class="gas-locations">
            <div v-if="gasLocations.length > 0">
                <div class="location" v-for="gasLocation in gasLocations" :key="gasLocation.id">
                    <div class="header">
                        <div class="title">{{ gasLocation.title }}</div>
                        <div class="address">{{ gasLocation?.formatted_address }}</div>
                    </div>
                    <div v-if="gasLocation.photo" class="content text-center">
                        <div class="mb-2">Receipt Available!</div>
                        <div @click="openGasReceipt(gasLocation.photo, gasLocation.title)" class="view">View Receipt</div>
                    </div>
                    <div v-else class="content unavailable text-center">
                        <div class="px-2 text-danger">Receipt Unavailable</div>
                        <!-- <div class="view">View Receipt</div> -->
                    </div>
                </div>
            </div>
            <div v-else class="text-center text-danger p-4" style="opacity: 0.5; border: 1px solid rgba(0, 0, 0, 0.35); border-radius: 6px">No Gas Station Allocated for This Booking</div>
        </div>

        <div v-if="currentTab === 'summary'" class="detail">
            <div class="container">
                <div class="order-summary">
                    <div>
                        <div style="padding: 12px 0; border-bottom: solid 1px rgba(0, 0, 0, 0.2)">
                            <span style="font-size: 18px; font-weight: 600">Order Summary</span>
                        </div>
                    </div>
                    <div class="text-end">
                        <div style="padding: 12px 0; border-bottom: solid 1px rgba(0, 0, 0, 0.2)">
                            <span style="font-size: 18px; font-weight: 600">{{ getBookingReceipt.booking_number }}</span>
                        </div>
                    </div>
                </div>
                <div id="forms" class="row m-1 mt-3" style="overflow-y: auto">
                    <div class="col-12 mb-3" style="border: 1px dashed black">
                        <div class="row text-start py-2" style="background: rgba(0, 0, 0, 0.1)">
                            <div style="font-weight: 600; font-size: 16px">Estimated Cost</div>
                        </div>
                        <div class="p-2">
                            <div class="row">
                                <div class="col">
                                    <div class="row pb-2 mb-1" style="border-bottom: 1px solid rgba(0, 0, 0, 0.2)">
                                        <div class="col text-start">Rate</div>
                                        <div class="col text-start"></div>
                                        <div class="col text-start">Weight (kg)</div>
                                        <div class="col text-start"></div>
                                        <div class="col text-end">Amount</div>
                                    </div>
                                    <div class="row">
                                        <div class="col text-start">Rp {{ getBookingReceipt?.client_rate?.rate.toLocaleString() }}</div>
                                        <div class="col text-center">x</div>
                                        <div class="col text-start">{{ getBookingReceipt?.total_accounted_weight }} (kg)</div>
                                        <div class="col text-center">=</div>
                                        <div class="col text-end">Rp {{ calculateEstimation.toLocaleString() }}</div>
                                    </div>
                                </div>
                            </div>
                            <br />

                            <div class="row" style="font-weight: 600; font-size: 18px">
                                <div class="col text-start"></div>
                                <div class="col text-end">Rp {{ calculateEstimation.toLocaleString() }}</div>
                            </div>
                        </div>
                    </div>
                    <div class="col-12 mb-3" style="border: 1px dashed black">
                        <div class="row text-start py-2" style="background: rgba(0, 0, 0, 0.1)">
                            <div style="font-weight: 600; font-size: 16px">Additional Fee</div>
                        </div>
                        <div v-if="calculateAdditional">
                            <div class="p-2">
                                <div class="row" v-if="getBookingReceipt.insurance_fee">
                                    <div class="col text-start">Item Valuation: Rp {{ getBookingReceipt.insurance_item_value }}</div>
                                    <div class="col text-end">Rp {{ getBookingReceipt.insurance_fee.toLocaleString() }} (Insurance)</div>
                                </div>
                                <div class="row" v-if="getBookingReceipt.repack_fee">
                                    <div class="col text-start">{{ getBookingReceipt.repack_value }}</div>
                                    <div class="col text-end">Rp {{ getBookingReceipt.repack_fee.toLocaleString() }} (Repack)</div>
                                </div>
                                <div class="row" v-if="getBookingReceipt.other_fee">
                                    <div class="col text-start">{{ getBookingReceipt.other_value }}</div>
                                    <div class="col text-end">Rp {{ getBookingReceipt.other_fee.toLocaleString() }} (Other)</div>
                                </div>
                            </div>
                            <div class="p-2">
                                <div class="row" style="font-weight: 600; font-size: 18px">
                                    <div class="col text-start"></div>
                                    <div class="col text-end">Rp {{ calculateAdditional.toLocaleString() }}</div>
                                </div>
                            </div>
                        </div>
                        <div class="p-2" v-else>
                            <div class="row m-2">
                                <div class="row text-center">
                                    <div class="col" style="font-weight: 600; font-size: 16px">No additional fee added</div>
                                </div>
                                <!-- <div class="row my-2 text-center">
                                <div class="col">Confirm all the details are correct before submitting!</div>
                            </div> -->
                            </div>
                        </div>
                    </div>
                    <div class="col-12" style="border: 1px dashed black">
                        <div class="row text-start py-2" style="background: rgba(0, 0, 0, 0.1)">
                            <div style="font-weight: 600; font-size: 16px">Total Cost</div>
                        </div>
                        <div class="p-2">
                            <div class="row">
                                <div class="col text-start">Estimated Cost</div>
                                <div class="col text-end">Rp {{ calculateEstimation.toLocaleString() }}</div>
                            </div>
                            <div class="row">
                                <div class="col text-start">Additional Fee</div>
                                <div class="col text-end">Rp {{ calculateAdditional.toLocaleString() }}</div>
                            </div>
                            <br />
                            <div class="row">
                                <div class="col text-start">Subtotal</div>
                                <div class="col text-end">Rp {{ getBookingReceipt?.total_cost?.toLocaleString() }}</div>
                            </div>
                            <div class="row">
                                <div class="col text-start">
                                    <span v-if="isAdmin || isManager">
                                        {{ getBookingReceipt.discount_fee && !getBookingReceipt.discount_note ? "Discount" : getBookingReceipt.discount_note }}
                                    </span>
                                    <span> {{ !getBookingReceipt.discount_fee ? "" : getBookingReceipt.discount_note ? getBookingReceipt.discount_note : "Discount" }} </span>
                                </div>
                                <ReceiptDiscount v-if="getReceipt.delivery_status === 3" :booking-receipt="getBookingReceipt" class="col text-end" />
                            </div>
                            <div class="row">
                                <div class="col text-start">{{ "PPN Logistic" }}</div>
                                <div class="col text-end">Rp {{ getBookingReceipt?.tax_fee?.toLocaleString() }}</div>
                            </div>

                            <br />
                            <div class="row" style="font-weight: 600; font-size: 18px">
                                <div class="col text-start">Payable Amount</div>
                                <div class="col text-end" style="color: #3699ff">Rp {{ (getBookingReceipt.total_cost + getBookingReceipt.tax_fee).toLocaleString() }}</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <PhotoViewer :show="showPhoto" :images="imageArray" @onClose="closePhotoViewer" />
    </div>
</template>
<script>
    import { GoogleMap, Marker } from "vue3-google-map";
    import axios from "axios";
    import moment from "moment";
    import PhotoViewer from "@/components/PhotoViewer/PhotoViewer.vue";
    import ReceiptDiscount from "../../modals/modalForms/receiptDiscount.vue";

    export default {
        components: {
            GoogleMap,
            Marker,
            PhotoViewer,
            ReceiptDiscount,
        },
        data() {
            return {
                imageArray: null,
                showPhoto: false,
                intervalId: null,
                receipt: {
                    detail: {},
                    transporter: {},
                },
                gasLocations: [],
                currentTab: "details",
                live: false,
                tripShow: false,
                delayedTripShow: false,
                gmaps: {
                    styles: [
                        {
                            featureType: "all",
                            stylers: [
                                { saturation: 0 }, // Apply saturation effect to the map
                            ],
                        },
                        {
                            featureType: "poi",
                            elementType: "labels",
                            stylers: [
                                { visibility: "off" }, // Hide POI labels
                            ],
                        },
                    ],
                    zoom: 4,
                    searchQuery: "",
                    center: { lat: 0.4484759, lng: 113.944888 },
                    options: {
                        panControl: false,
                        zoomControl: true,
                        mapTypeControl: false,
                        scaleControl: true,
                        streetViewControl: false,
                        overviewMapControl: false,
                        rotateControl: false,
                    },
                    markerOptions: {
                        icon: "/icons/location.svg",
                    },
                    suggestions: [],
                    marker: {
                        lat: null,
                        lng: null,
                    },
                    historyMarker: {
                        lat: null,
                        lng: null,
                    },
                    originMarker: {
                        lat: null,
                        lng: null,
                    },
                    destinationMarker: {
                        lat: null,
                        lng: null,
                    },

                    selected: {
                        area: null,
                        formatted_address: "",
                        coords: {
                            lat: null,
                            lng: null,
                        },
                    },
                },
            };
        },
        watch: {
            tripShow() {
                if (!this.tripShow) this.delayedTripShow = this.tripShow;
                else
                    setTimeout(() => {
                        this.delayedTripShow = this.tripShow;
                    }, 500);
            },
            currentTab(newValue) {
                if (newValue === "tracking") {
                    if (this.getBookingReceipt.client_rate.shipping_type.is_real_time) {
                        if (this.receipt.detail.delivery_status < 3) {
                            this.clearInterval();
                            this.createInterval();
                        }
                    }
                } else this.clearInterval();
            },
        },
        computed: {
            calculateEstimation() {
                return this.getBookingReceipt.total_cost - this.calculateAdditional;
            },
            calculateAdditional() {
                return +this.getBookingReceipt.insurance_fee + +this.getBookingReceipt.repack_fee + +this.getBookingReceipt.other_fee;
            },
            getReceipt() {
                return this.receipt.detail;
            },
            getCurrentDeliveryStatus() {
                const deliveryStatus = this.getReceipt.delivery_status;
                let status = "";

                switch (deliveryStatus) {
                    case 5:
                        status = "Canceled";
                        break;
                    case 4:
                        status = "Completed";
                        break;
                    case 3:
                        status = "Delivered";
                        break;
                    case 2:
                        status = "On Process";
                        break;
                    case 1:
                        status = "Awaiting Pickup";
                        break;
                    default:
                        status = "Bad Receipt";
                        break;
                }

                return status;
            },
            getLatestStatus() {
                const deliveryStatus = this.getReceipt.delivery_status;
                let latest = "";

                switch (deliveryStatus) {
                    case 5:
                        latest = "This Receipt have been Canceled";
                        break;
                    case 4:
                        latest = "This receipt have been Completed";
                        break;
                    case 3:
                        latest = this.getReceipt.delivered_address;
                        break;
                    case 2:
                        if (this.getReceipt.update_time) latest = this.getReceipt.update_address;
                        else latest = this.getReceipt.pickup_address;
                        break;
                    case 1:
                        latest = "Awaiting Pickup by Transporter " + this.receipt?.transporter?.full_name;
                        break;
                    default:
                        latest = "Bad Receipt";
                        break;
                }

                return latest;
            },
            getLatestAddress() {
                const deliveryStatus = this.getReceipt.delivery_status;
                let latest = "";

                switch (deliveryStatus) {
                    case 3:
                        latest = this.getReceipt.delivered_address;
                        break;
                    case 2:
                        if (this.getReceipt.update_time) latest = this.getReceipt.update_address;
                        else latest = this.getReceipt.pickup_address;
                        break;

                    default:
                        latest = "Bad Receipt";
                        break;
                }

                return latest;
            },
            getMarkerIcon() {
                return this.getBookingReceipt.client_rate.shipping_type.transportation_mode === "Land" ? "/icons/custom/map_pickup.svg" : "/icons/custom/goods.svg";
            },
            getRecipient() {
                return this.receipt.detail?.booking_receipt?.recipient_company ?? "";
            },
            getShipper() {
                return this.receipt.detail?.booking_receipt?.user ?? "";
            },
            getRate() {
                return this.getBookingReceipt.client_rate;
            },
            getBookingReceipt() {
                return this.receipt.detail?.booking_receipt ?? "";
            },
            isClient() {
                if (this.$store.state.userData.access_level === 2) return true;
                return false;
            },
            isSales() {
                if (this.$store.state.userData.access_level === 3) return true;
                return false;
            },
            isAdmin() {
                if (this.$store.state.userData.access_level === 4) return true;
                return false;
            },
            isManager() {
                if (this.$store.state.userData.access_level === 5) return true;
                return false;
            },
        },
        methods: {
            getTime(date, type) {
                if (type == "date") {
                    return moment(date).format("ddd, DD/MM/YYYY");
                }
                return moment(date).format("HH:mm");
            },
            handleImageError() {
                return "/placeholders/unavailable-image.jpg";
            },
            openPhotoViewer(category, photoFile, documentFile) {
                let arrayImage;
                let path, photoLabel, documentLabel;

                if (category) {
                    if (category === 1) {
                        path = "pickup";
                        photoLabel = "Pickup Photo";
                        documentLabel = "Pickup Document";
                    }
                    if (category === 2) {
                        path = "delivery-status";
                        photoLabel = "Update Photo";
                        documentLabel = "Update Document";
                    }
                    if (category === 3) {
                        path = "arrived";
                        photoLabel = "Delivered Photo";
                        documentLabel = "Delivered Document";
                    }

                    arrayImage = [
                        {
                            src: photoFile ? `https://s3-usfc-${path}-image.s3.ap-southeast-1.amazonaws.com/${photoFile}` : this.handleImageError(),
                            label: photoLabel,
                        },
                        {
                            src: documentFile ? `https://s3-usfc-${path}-image.s3.ap-southeast-1.amazonaws.com/${documentFile}` : this.handleImageError(),
                            label: documentLabel,
                        },
                    ];

                    this.imageArray = arrayImage;
                    console.log("AAA", arrayImage);
                    this.showPhoto = true;
                }
            },

            openGasReceipt(photoFile, label) {
                let arrayImage;
                let path, photoLabel;

                path = "gas-receipt";
                photoLabel = "Gas Receipt at " + label;

                arrayImage = [
                    {
                        src: photoFile ? `https://s3-usfc-${path}-image.s3.ap-southeast-1.amazonaws.com/${photoFile}` : this.handleImageError(),
                        label: photoLabel,
                    },
                ];

                this.imageArray = arrayImage;
                this.showPhoto = true;
            },
            closePhotoViewer() {
                this.showPhoto = false;
                this.imageArray = null;
            },
            formatDate(dateString) {
                const inputDate = moment.utc(dateString).utcOffset(7);
                const today = moment().utcOffset(7).startOf("day");

                if (inputDate.isSame(today, "day")) {
                    return "Today, " + inputDate.format("HH:mm") + " WIB";
                } else {
                    return inputDate.format("DD/MM/YYYY, HH:mm") + " WIB";
                }
            },
            clearInterval() {
                if (this.intervalId) {
                    clearInterval(this.intervalId);
                    this.intervalId = null;
                }
            },
            createInterval() {
                console.log("aaa");
                this.getLocation(this.receipt?.transporter?.id);
                this.intervalId = setInterval(() => {
                    this.getLocation(this.receipt?.transporter?.id);
                }, 10000);
            },

            async getLocation(transporterId) {
                console.log("aaa");
                let token = JSON.parse(localStorage.getItem("token"));

                const { data } = await axios.get(this.$store.state.app.apiBasepath + "/receipt/location?transporterId=" + transporterId, { headers: { Authorization: `Bearer ${token}` } });

                console.log(data, "data");
                if (data) {
                    const loc = {
                        location: {
                            lat: data.lat,
                            lng: data.lng,
                        },
                    };

                    this.selectLocation(loc);
                }
            },
            async getDetail(id) {
                let token = JSON.parse(localStorage.getItem("token"));

                const data = await axios.get(this.$store.state.app.apiBasepath + "/receipt/details/" + id, { headers: { Authorization: `Bearer ${token}` } }).then((res) => {
                    return res.data;
                });

                if (data) {
                    this.receipt.detail = data.receipt;
                    this.receipt.transporter = data.transporterDetails;

                    this.gmaps.originMarker = { lat: +data?.receipt?.booking_receipt?.pickup_latitude, lng: +data?.receipt?.booking_receipt?.pickup_longitude };

                    this.gmaps.destinationMarker = { lat: +data?.receipt?.booking_receipt?.recipient_company?.latitude, lng: +data?.receipt?.booking_receipt?.recipient_company?.longitude };

                    if (!this.getBookingReceipt.client_rate.shipping_type.is_real_time) {
                        this.getLocation(this.receipt?.transporter?.id);
                    }

                    this.$store.state.app.breadcrumb.currentSubSubMenu = this.receipt.detail.booking_receipt.booking_number;

                    const baggings = await axios.get(this.$store.state.app.apiBasepath + "/booking-receipt/details/" + data.receipt.booking_receipt.id, { headers: { Authorization: `Bearer ${token}` } }).then((res) => {
                        return res.data.bagging;
                    });

                    if (baggings) this.receipt.baggings = baggings;

                    const gas = await axios.get(this.$store.state.app.apiBasepath + "/booking-receipt/gas-station-locations/" + data.receipt.booking_receipt.id, { headers: { Authorization: `Bearer ${token}` } }).then((res) => {
                        return res.data.rows;
                    });

                    if (gas) {
                        const temp = gas;

                        await Promise.all(
                            temp.map(async (gas) => {
                                gas.lat = gas.latitude;
                                gas.lng = gas.longitude;
                                gas.formatted_address = await this.getLocDetails({ lat: gas.lat, lng: gas.lng });
                                console.log(gas, "rob");
                                return gas;
                            })
                        );

                        if (gas) this.gasLocations = temp;
                    }
                }
            },

            async getLocDetails(coords) {
                try {
                    const response = await axios.get("https://api.macra.co.id/maps/place?searchQuery=" + `${coords.lat},${coords.lng}` + "&lat=" + coords.lat + "&lng=" + coords.lng);
                    console.log(response.data, "555");
                    return response.data.results[0].formatted_address;
                } catch (error) {
                    console.log(error);
                }
            },

            markerCallback(e) {
                let coords = {
                    location: {
                        lat: e.lat(),
                        lng: e.lng(),
                    },
                };
                this.selectLocation(coords);
            },
            selectLocation(itemIndex) {
                console.log("focus");
                let loc = itemIndex.location;
                this.gmaps.marker = { lat: loc.lat, lng: loc.lng };
                this.gmaps.center = { lat: loc.lat, lng: loc.lng };
                setTimeout(() => {
                    this.gmaps.suggestions = [];
                    this.gmaps.zoom = 10;
                }, 250);
                // this.getLocDetails(loc);
            },
            async viewLocationHistory() {
                const loc = {
                    location: {
                        ...this.gmaps.marker,
                    },
                };

                this.selectLocation(loc);
                // const address = this.getLatestAddress;

                // if (![this.getReceipt.delivered_address ?? "Address", this.getReceipt.update_address ?? "Address"].includes(address)) return;

                // if (!this.getLatestAddress) return;

                // try {
                //     const { data } = await axios.get("https://api.macra.co.id/maps/place?searchQuery=" + address + "&lat=" + this.gmaps.center.lat + "&lng=" + this.gmaps.center.lng);

                //     if (data) {
                //         const loc = data.results[0].geometry.location;
                //         this.gmaps.historyMarker = { lat: loc.lat, lng: loc.lng };
                //         this.gmaps.center = { lat: loc.lat, lng: loc.lng };

                //         setTimeout(() => {
                //             this.gmaps.suggestions = [];
                //             this.gmaps.zoom = 15;
                //         }, 250);
                //     }
                // } catch (error) {
                //     console.log(error);
                // }
            },
        },
        mounted() {
            this.getDetail(this.$route.params.id);
        },
        beforeUnmount() {
            this.clearInterval();
        },
    };
</script>
<style lang="scss" scoped>
    .gas-locations {
        margin-left: 220px;
        width: calc(100% - 200px);

        .location {
            border: 1px solid rgba($color: #000000, $alpha: 0.25);
            border-radius: 6px;
            padding: 12px;

            transition: all 0.3s ease;

            display: flex;
            align-items: center;

            &:hover {
                box-shadow: 0 0 10px 1px rgba($color: #000000, $alpha: 0.05);
            }

            .header {
                flex-grow: 1;
                display: flex;
                flex-direction: column;
                gap: 12px;

                .title {
                    font-weight: 600;
                }
                .address {
                    font-size: 12px;
                    opacity: 0.75;

                    max-width: 50%;
                }
            }

            .content {
                font-size: 12px;

                &.unavailable {
                    opacity: 0.5;
                    user-select: none;
                    pointer-events: none;
                }
                .view {
                    background-color: #01a4e9;
                    padding: 12px 22px;
                    min-width: 150px;
                    border-radius: 6px;
                    color: #fff;
                    transition: all 0.3s ease;
                    text-align: center;
                    cursor: pointer;

                    &:active {
                        opacity: 0.5;
                        transform: scale(0.98);
                    }
                }
            }
        }
    }
    .map {
        margin-left: 220px;
        width: calc(100% - 200px);
        height: 100%;
        filter: saturate(2);

        .marker {
            filter: saturate(200%);
        }
    }
    .btn.btn-usfc-outline {
        border: 1px solid #3699ff;
        color: #3699ff;
        border-radius: 6px;
        padding: 10px 25px;
    }
    .frame {
        display: flex;
        height: 88vh;
        background: white;
        justify-content: space-between;
        padding: 20px;
        gap: 20px;
        overflow-y: auto;
        &::-webkit-scrollbar-track {
            -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
            background-color: #f5f5f5;
        }

        &::-webkit-scrollbar {
            width: 6px;
            background-color: #f5f5f5;
        }

        &::-webkit-scrollbar-thumb {
            background-color: #3699ff;
            border-radius: 6px;
        }
        // max-width: max-content;
        .nav {
            display: flex;
            flex-direction: column;
            height: 88vh;
            position: fixed;
            width: 200px;
            // padding: 20px;
            gap: 6px;
            border-right: 1px solid rgba($color: #000000, $alpha: 0.05);

            .nav-item {
                margin-right: 12px;
                border-radius: 6px;
                padding: 12px 24px;
                cursor: pointer;

                &.active {
                    background-color: rgba($color: #000000, $alpha: 0.05);
                    filter: invert(45%) sepia(69%) saturate(2325%) hue-rotate(196deg) brightness(108%) contrast(101%);
                }

                &:hover {
                    background-color: rgba($color: #000000, $alpha: 0.05);
                    filter: invert(45%) sepia(69%) saturate(2325%) hue-rotate(196deg) brightness(108%) contrast(101%);
                }
            }
        }

        .detail {
            flex-grow: 1;

            display: flex;
            flex-direction: column;
            gap: 20px;
            height: fit-content;
            margin-left: 220px;

            .receipt {
                display: flex;
                flex-direction: column;

                .title {
                    display: flex;
                    gap: 16px;
                    margin-top: 6px;
                    font-weight: 600;
                    .dot {
                        display: block;
                        box-sizing: border-box;
                        align-self: center;
                        justify-self: center;

                        width: 12px;
                        height: 12px;

                        border: 3px solid #3699ff;

                        border-radius: 50%;
                    }
                }

                .checkpoint {
                    display: flex;
                    flex-direction: column;
                    gap: 6px;
                    .content {
                        display: flex;
                        gap: 2px;
                        flex-direction: column;
                        margin-left: 10px;
                        padding-left: 24px;

                        &.lined {
                            margin-left: 9px;
                            border-left: 2px solid #3699ff;
                        }

                        .item {
                            display: flex;
                            &.locbtn {
                                min-width: 100%;
                                min-height: 24px;
                                background-color: #3699ff;
                            }

                            .update-location {
                                line-height: 18px;
                                font-size: 12px;
                                font-weight: bold;
                                transition: all ease 0.3s;

                                min-width: 100%;
                                position: relative;
                                left: -6px;

                                cursor: pointer;
                            }

                            .update-location:hover {
                                background-color: rgba(0, 0, 0, 0.35);
                            }

                            .process {
                                background-color: rgba(0, 0, 0, 0.15);

                                filter: invert(45%) sepia(69%) saturate(2325%) hue-rotate(196deg) brightness(108%) contrast(101%);
                                border-radius: 20px;
                            }
                        }
                    }
                }
            }

            .container {
                min-height: 20%;
                background: #ffffff;
                /* Others/Grey */

                padding: 18px;

                border: 1px solid rgba(0, 0, 0, 0.09);
                border-radius: 6px;
                width: 100%;

                display: flex;
                flex-direction: column;
                justify-content: space-between;

                .order-summary {
                    display: flex;
                    flex-grow: 1;
                    width: 100%;

                    div {
                        flex-grow: 1;
                        &.right {
                            text-align: end;
                        }
                    }
                }

                .title {
                    font-size: 16px;
                    font-weight: 600;
                    margin-bottom: 16px;
                }

                .content {
                    display: flex;
                    flex-direction: column;

                    sup {
                        transform: translate(-6px, 12px);
                    }

                    .title {
                        font-size: 12px;
                        font-weight: 200;
                        margin-bottom: 0;
                        margin-top: 6px;

                        &__numbered {
                            font-size: 14px;
                            font-weight: 400px;
                            margin-bottom: 8px;
                        }
                    }
                }
            }

            .shipper {
                flex-grow: 1;
                overflow: auto;
                // max-height: 250px;
                min-height: 20%;
                background: #ffffff;
                /* Others/Grey */

                padding: 18px;

                border: 1px solid rgba(0, 0, 0, 0.09);
                border-radius: 6px;

                display: flex;
                flex-direction: row;
                justify-content: space-between;

                .title {
                    font-size: 16px;
                    font-weight: 600;
                    margin-bottom: 16px;
                }

                .content {
                    display: flex;
                    flex-direction: column;

                    .title {
                        font-size: 12px;
                        margin-bottom: 0;
                        font-weight: 200;
                    }
                }

                &::-webkit-scrollbar-track {
                    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
                    background-color: #f5f5f5;
                }

                &::-webkit-scrollbar {
                    width: 6px;
                    background-color: #f5f5f5;
                }

                &::-webkit-scrollbar-thumb {
                    background-color: #3699ff;
                    border-radius: 6px;
                }
            }

            .user-action {
                display: flex;
                justify-content: flex-end;

                .usfc-btn {
                    color: #3699ff;

                    padding: 12px 48px;
                    background: #ffffff;
                    /* Blue/Primary */

                    border: 1px solid #3699ff;
                    border-radius: 6px;

                    transition: all 0.3s ease;

                    &:hover {
                        border: 1px solid white;
                        background: #3699ff;
                        color: #fff;
                    }
                }
            }
        }

        .tracking {
            display: block;
            flex-grow: 1;
            min-width: 500px;
            height: 570px;
            border-radius: 12px;
        }

        .trip-history {
            display: flex;
            flex-direction: column;
            margin-right: 40px;
            margin-bottom: 25px;

            width: 910px;
            height: 500px;
            max-height: 150px;

            transition: all 0.3s ease-in-out;

            &.show {
                max-height: 675px;
            }

            &.floating {
                position: absolute;
                bottom: 0;
                right: 0;
            }
            &.static {
                position: absolute;
                right: 0;

                height: 80vh;
                max-height: 80vh;
            }

            .handle {
                cursor: pointer;
                font-weight: 600;
                display: flex;
                align-self: flex-end;
                justify-content: space-between;
                background: #ebf5ff;
                height: fit-content;
                width: 200px;
                padding: 4px 16px;
                color: #3699ff;
                border-radius: 6px 6px 0px 0px;

                .toggler {
                    transform: scale(0.8);
                    margin-left: 6px;

                    transition: all 0.3s ease;
                    &.show {
                        transform: scale(0.8) rotateX(180deg);
                    }
                }
            }

            .trip {
                height: 95%;
                min-height: 125px;
                background-color: white;

                display: flex;
                flex-direction: column;
                padding: 20px;

                border-radius: 6px 0 0 0;

                gap: 16px;

                .latest {
                    display: flex;
                    flex-direction: column;

                    .status {
                        display: flex;
                        justify-content: space-between;

                        .title {
                            font-size: 16px;
                            font-weight: 600;
                        }

                        .update-status {
                            color: black;
                            font-size: 12px;
                            width: 150px;
                            text-align: center;
                            padding: 3px;
                            font-weight: bold;
                            line-height: 16px;
                            border-radius: 20px;
                            opacity: 0.75;

                            &.receipt__1 {
                                background: rgba(0, 0, 0, 0.09);

                                &::before {
                                    content: "Awaiting Pickup";
                                }
                            }

                            &.receipt__2 {
                                background: #c5def9;
                                color: #083e76;

                                &::before {
                                    content: "On Process";
                                }
                            }

                            &.receipt__3 {
                                background: #bcf2bb;
                                color: #107e0d;

                                &::before {
                                    content: "Delivered";
                                }
                            }

                            &.receipt__4 {
                                background: #bcf2bb;
                                color: #107e0d;

                                &::before {
                                    content: "Confirmed";
                                }
                            }

                            &.receipt__5 {
                                background: rgba(255, 171, 171, 0.7);
                                color: #971919;
                                &::before {
                                    content: "Cancelled";
                                }
                            }

                            &.pending {
                                opacity: 0.75;
                                background-color: #ffd3ab;
                                border-radius: 20px;
                                color: #c46b1b;
                            }

                            &.rejected {
                                opacity: 0.75;
                                background-color: rgba(255, 171, 171, 0.7);
                                border-radius: 20px;
                                color: #971919;
                            }

                            &.approved {
                                opacity: 0.75;
                                background: #bcf2bb;
                                border-radius: 20px;
                                color: #107e0d;
                            }
                        }
                    }

                    .position {
                        display: flex;

                        .place {
                            display: flex;
                            flex-direction: column;
                        }
                    }
                }

                .trip-show {
                    display: flex;
                    flex-direction: column;
                    .status {
                        display: flex;
                        justify-content: space-between;

                        .title {
                            font-size: 16px;
                            font-weight: 600;
                        }
                    }
                    &::-webkit-scrollbar-track {
                        -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
                        background-color: #f5f5f5;
                    }

                    &::-webkit-scrollbar {
                        width: 0px;
                        background-color: #f5f5f5;
                    }

                    &::-webkit-scrollbar-thumb {
                        background-color: #1a222a;
                        border-radius: 6px;
                    }

                    overflow: auto;
                    // max-height: 45vh;
                    gap: 12px;
                    .history {
                        opacity: 0.5;
                        display: flex;
                        opacity: 0.5;
                        transition: all 0.3s ease;

                        &:hover {
                            opacity: 1;
                        }
                        &.show {
                            opacity: 1;
                        }
                        .detail {
                            display: flex;
                            flex-direction: column;
                            gap: 6px;
                            margin-left: 0;

                            .time {
                                display: flex;
                                .dot {
                                    width: 6px;
                                    height: 6px;
                                    border: 3px solid #01a4e9;
                                    border-radius: 50%;
                                    background: #01a4e9;
                                    align-self: center;
                                    margin-right: 18px;
                                }
                            }

                            .content {
                                border-left: 1px solid #01a4e9;
                                padding-left: 22px;
                                position: relative;
                                left: 2px;
                            }

                            .note {
                                .title {
                                    font-weight: 600;
                                }

                                .content {
                                    border: none;
                                    padding-left: 0;
                                }
                            }
                        }

                        .photo {
                            padding: 0 20px;
                            img {
                                width: 75px;
                                height: 75px;
                            }
                        }
                    }
                }
            }
        }
    }
    .blue-filter {
        filter: invert(45%) sepia(69%) saturate(2325%) hue-rotate(196deg) brightness(108%) contrast(101%);
    }

    #map_canvas {
        width: 980px;
        height: 500px;
    }
    #current {
        padding-top: 25px;
    }
    #pickup_maps > a {
        width: 0;
        height: 0;
        opacity: 0;
    }

    #details > .row {
        margin-top: 14px;
    }

    .pulsating-circle {
        position: relative;
        transform: translateX(-50%) translateY(-50%);
        top: 50%;
        width: 8px;
        height: 8px;
    }
    .pulsating-circle:before {
        content: "";
        position: relative;
        display: block;
        width: 300%;
        height: 300%;
        box-sizing: border-box;
        margin-left: -100%;
        margin-top: -100%;
        border-radius: 50%;
        background-color: #01a4e9;
        -webkit-animation: pulse-ring 1.25s cubic-bezier(0.215, 0.61, 0.355, 1) infinite;
        animation: pulse-ring 1.25s cubic-bezier(0.215, 0.61, 0.355, 1) infinite;
    }
    .pulsating-circle:after {
        content: "";
        position: absolute;
        left: 0;
        top: 0;
        display: block;
        width: 100%;
        height: 100%;
        background-color: white;
        border-radius: 15px;
        box-shadow: 0 0 8px rgba(0, 0, 0, 0.3);
        -webkit-animation: pulse-dot 1.25s cubic-bezier(0.455, 0.03, 0.515, 0.955) -0.4s infinite;
        animation: pulse-dot 1.25s cubic-bezier(0.455, 0.03, 0.515, 0.955) -0.4s infinite;
    }

    @-webkit-keyframes pulse-ring {
        0% {
            transform: scale(0.33);
        }
        80%,
        100% {
            opacity: 0;
        }
    }

    @keyframes pulse-ring {
        0% {
            transform: scale(0.33);
        }
        80%,
        100% {
            opacity: 0;
        }
    }
    @-webkit-keyframes pulse-dot {
        0% {
            transform: scale(0.8);
        }
        50% {
            transform: scale(1);
        }
        100% {
            transform: scale(0.8);
        }
    }
    @keyframes pulse-dot {
        0% {
            transform: scale(0.8);
        }
        50% {
            transform: scale(1);
        }
        100% {
            transform: scale(0.8);
        }
    }
</style>
